.create-reservation-container {
  display: block;
  width: 100%;
  max-width: 900px;
  margin: auto;
  padding: 20px;
  @media(max-width: 991px) {
    max-width: 100%;
  }
  .col-left {
    float: left;
    width: 100%;
    max-width: 300px;
    padding-right: 20px;
    @media(max-width: 991px) {
      position: relative;
    }
    @media(max-width: 767px) {
      padding: 0;
      max-width: 100%;
    }
    .garage-grid-item {
      @media(max-width: 991px) {
        transform: scale(0.7);
        margin-top: -62px;
        margin-left: -50px;
        position: absolute;
      }
      @media(max-width: 767px) {
        position: initial;
        transform: none;
        margin: 0;
        width: 100%;
        height: auto;
        padding-bottom: 0;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 10px;
        .garage-thumb-container {
          width: 25%;
        }
        .garage-thumb-image {
          height: 115px;
        }
        .garage-thumb-title {
          width: 75%;
          padding: 0 0 0 40px;
          height: auto;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .type-container {
          width: 75%;
          padding-left: 40px;
        }
        .location-of-garage {
          width: 75%;
          padding-left: 40px;
          clear: initial;
          padding-bottom: 15px;
        }
        .user-name-with-rating {
          display: none;
        }
        .name {
          display: none;
        }
        .user-rating {
          display: none;
        }
        .user-nav-avatar {
          top: -9px;
          left: -5px;
        }
      }
    }
  }
  .col-right {
    float: left;
    width: calc(100% - 300px);
    padding-bottom: 100px;
    @media(max-width: 991px) {
      width: 100%;
      float: left;
      padding-left: 230px;
    }
    @media(max-width: 767px) {
      padding-left: 0;
    }
  }
}
.dashboard-notifications.billing-info {
  float: left;
  max-width: 600px;
  width: 100%;
  @media(max-width: 991px) {
    max-width: 100%;
  }
}
.protected-by-row {
  color: #b3b3b3;
  float: right;
  width: 133px;
  clear: both;
  margin-top: 10px;
  span {
    font-weight: bolder;
    padding-bottom: 5px;
    float: left;
  }
  svg.stripe-secure {
    width: 55px;
    float: left;
    height: 27px;
    margin-top: -4px;
    margin-left: -2px;
    path {
      fill: #8c8c8c;
    }
    .st0 {
      fill : #8c8c8c;
    }
  }
}
.save-payment-information {
  float: left;
  width: 100%;
  max-width: 600px;
  @media(max-width: 991px) {
    max-width: 100%;
  }
  button.about-info-save-btn {
    margin: 10px 0 0 0;
    float: right;
    clear: both;
  }
}
svg.lock-icon-billing {
  width: 15px;
  height: 15px;
  float: left;
}
.loading-stripe-card-from {
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  max-width: 600px;
  span {
    color: #b7b7b7;
    display: inline-block;
    position: relative;
    top: -15px;
    left: 15px;
  }
  .stripe-form-loader svg {
    color: #4bbfff;
  }
}
.stripe-add-card-form {
  float: left;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.user-cards-data {
  float: left;
  width: 100%;
  max-width: 600px;
  @media(max-width: 991px) {
    max-width: 100%;
  }
}
.default-card-source {
  float: left;
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
  background-color: #e6fff8;
  border-radius: 10px;
  padding: 15px;
  position: relative;
}
.add-payment-info-form .payment-form-wrap {
  @media(max-width: 991px) {
    max-width: 100%;
    .stripe-add-card-form {
      max-width: 100%;
    }
  }
}
h3.payment-info-title {
  font-size: 13px;
  font-weight: bolder;
  float: left;
  width: 100%;
  border-bottom: 2px solid #92e2dc;
  padding-bottom: 5px;
  color: #7ecec8;
}
.saved-card-info {
  float: left;
  width: 100%;
  margin-top: 10px;
  position: relative;
  .card-four {
    font-family: 'Inconsolata', monospace;
    font-size: 24px;
    margin: 9px 15px 0 15px;
    color: #1b6d68;
    float: left;
    user-select: none;
    span {
      font-size: 12px;
      position: absolute;
      top: -2px;
    }
  }
}
.card-brand-logo {
  height: 35px;
  width: 54px;
  background-color: #fff;
  border-radius: 5px;
  float: left;
  box-shadow: aquamarine 1px 2px 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../images/credit-card.svg');
  background-size: 58px;
  &.Visa {
    background-image: url('../images/visa.svg');
    background-size: 44px;
  }
  &.MasterCard {
    background-image: url('../images/mastercard.svg');
    background-size: 30px;
  }
  &.American.Express {
    background-image: url('../images/amex.svg');
    background-size: 25px;
  }
  &.Discover {
    background-image: url('../images/discover.svg');
    background-size: 54px;
  }
  &.Diners.Club {
    background-image: url('../images/dinersclub.svg');
    background-size: 54px;
  }
  &.JCB {
    background-image: url('../images/jcb.svg');
    background-size: 26px;
  }
  &.UnionPay {
    background-image: url('../images/unionpay.svg');
    background-size: 26px;
  }
}
.saved-card-actions {
  float: right;
}
.payment-form-wrap {
  float: left;
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
  .new-payment-title {
    font-weight: bold;
    font-size: 14px;
    padding-left: 13px;
    margin-bottom: 3px;
    color: #556379;
  }
}
button.update-card-btn {
  color: #2d63ff;
  &.with-cancel {
    color: #525252;
  }
}
button.delete-card-btn {
  color: #ff00a3;
}
.deleting-card-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  border-radius: 10px;
  user-select: none;
  .loading-stripe-card-from {
    background-color: transparent;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0;
    .loader-container-delete {
      width: 200px;
      height: 43px;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      span {
        color: #fff;
      }
    }
  }
}
.reservation-info-container {
  float: left;
  width: 100%;
  max-width: 600px;
  @media(max-width: 991px) {
    max-width: 100%;
  }
}
.reservation-top-info-row {
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  p {
    @media(max-width: 800px) {
      text-align: center;
      float: left;
      width: 100%;
    }
  }
  .where-col ,
  .start-date-col,
  .end-date-col {
    float: left;
    width: 33.33333333%;
    @media(max-width: 800px) {
      width: 100%;
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: 0;
      }
    }
    span.title-res {
      float: left;
      width: 100%;
      color: #68adb9;
      @media(max-width: 800px) {
        text-align: center;
      }
      p {
        font-size: 22px;
         @media(max-width: 800px) {
          text-align: center;
          float: left;
          width: 100%;
        }
      }
    }
    span.weekday {
      font-size: 12px;
      color: #828282;
      float: left;
      width: 100%;
      @media(max-width: 800px) {
        text-align: center;
      }
    }
  }
}
.reservation-info-content {
  float: left;
  width: 100%;
  position: relative;
  padding: 10px 11px;
  margin: 10px 0;
  svg {
    position: absolute;
    width: 45px;
    height: 45px;
    color: #1aa9ff;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 2px solid #bdfff3;
    border-radius: 50%;
    padding: 8px;
  }
  p {
    float: left;
    width: 100%;
    padding-left: 60px;
    font-size: 14px;
  }
}
.final-reservation-requirements {
  float: left;
  width: 100%;
  max-width: 600px;
  @media(max-width: 991px) {
    max-width: 100%;
    margin-bottom: 100px;
  }
  p {
    float: right;
    position: relative;
    top: 9px;
    @media(max-width: 667px) {
      font-size: 11px;
      top: 13px;
    }
  }
  span {
    @media(max-width: 667px) {
      font-size: 11px;
    }
  }
  label {
    float: right;
  }
}
button.create-reservation-btn {
  float: right;
  margin-top: 20px;
  background-color: #1ea6f1;
  color: #fff;
  padding: 12px 22px;
  text-transform: none;
  @media(max-width: 991px) {
    clear: both;
  }
  &:hover {
    background-color: #1e5ff1;
  }
}
.reservation-for {
  position: absolute;
  top: 50px;
  height: 341px;
  max-width: 282px;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 22px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  animation: float 6s ease-in-out infinite;
  box-shadow: rgba(135, 206, 235, 0.9) 20px -7px 70px 0px, rgba(135, 235, 138, 0.9) -20px 18px 70px 0px;
}
@keyframes float {
  0% {
    transform: translatey(0px) scale(0.6);
    box-shadow: rgba(135, 206, 235, 0.3) 0px -7px 10px 0px, rgba(135, 235, 138, 0.3) 0px 18px 10px 0px;
  }
  50% {
    transform: translatey(-20px) scale(0.6);
    box-shadow: rgba(135, 206, 235, 0.9) 20px -7px 70px 0px, rgba(135, 235, 138, 0.9) -20px 18px 70px 0px;
  }
  100% {
    transform: translatey(0px) scale(0.6);
    box-shadow: rgba(135, 206, 235, 0.3) 0px -7px 10px 0px, rgba(135, 235, 138, 0.3) 0px 18px 10px 0px;
  }
}
.app-loader-wrap .loading-app.making-reservation {
  width: 40px;
  height: 40px;
  bottom: initial;
  top: 18px;
}
span.creating-loader-text {
  position: absolute;
  width: 155px;
  left: -58px;
  bottom: -28px;
  right: 0;
  margin: auto;
}
.final-reservation-requirements button.disable-sub-btn {
  background-color: #d4d4d4;
  color: #8a8a8a;
  cursor: initial;
}
.payment-info-breakdown {
  float: right;
}
.creating-reservation-wrap {
  background-image: url('../images/motorroof-banner-bg.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  &.loading-charge {
    z-index: 9999;
  }
}
.motorroof-create-logo {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 50px;
  width: 100%;
  .st0{fill:#10FFE2;}
  .st1{fill:#231F20;}
  .st2{fill:#2D2D2D;}
}
.reservation-create-buffer {
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
  height: calc(100% - 100px);
}





