.garage-owner-banner {
  float: left;
  width: 100%;
  text-align: center;
  background-color: #bdffea;
  transition: all 0.1s ease-in-out;
  p {
    color: #1d9c81;
    user-select: none;
    padding: 10px;
  }
  &:hover {
    background-color: #86f3ff;
    p {
      color: #1e85d4;
    }
  }
}
.info-about-rate-container {
  float: left;
  width: 100%;
  position: relative;
  .rate-info {
    position: absolute;
    left: 120px;
    top: 11px;
    bottom: 0;
    margin: auto;
    padding: 20px 30px 15px 50px;
    width: calc(100% - 130px);
    max-width: 400px;
    @media(max-width: 991px) {
      padding-top: 10px;
    }
    @media(max-width: 667px) {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      padding: 0px;
    }
    &:before {
      content: '';
      height: 20px;
      width: 30px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      background-image: url('../images/arrow-left.svg');
      left: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      @media(max-width: 667px) {
        content: none;
      }
    }
    p {
      font-size: 12px;
      @media(max-width: 600px) {
        font-size: 11px;
      }
    }
  }
}
div.listing-price {
  max-width: 120px;
  @media(max-width: 667px) {
    max-width: initial;
    width: 100%;
  }  
  input {
    font-size: 25px;
  }
}
.garage-header {
  float: left;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 999;
  top: 66px;
  left: 0;
  right: 0;
  .garage-header-container {
    margin: auto;
    max-width: 1000px;
    width: 100%;
    position: relative;
    left: -20px;
    @media(max-width: 1100px) {
      max-width: 100%;
      left: 0;
    }
  }
  .garage-header-container-wrap {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 20px;
    @media(max-width: 1100px) {
      margin-top: 0;
    }
  }
  .garage-info-col {
    float: left;
    width: calc(100% - 250px);
    @media(max-width: 1100px) {
      width: calc(100% - 300px);
    }
    @media(max-width: 800px) {
      width: 100%;
    }
  }
  .garage-make-reservation-col {
    float: left;
    width: 250px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-image: url('../images/motorroof-pattern.jpg');
    border-top-right-radius: 50px;
    @media(max-width: 1100px) {
      border-top-right-radius: 0;
      width: 300px;
    }
    @media(max-width: 991px) {
      position: initial;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    @media(max-width: 800px) {
      width: 100%;
    }
  }
}
.garage-gallery-wrap {
  float: left;
  width: 100%;
}
.garage-gallery-container {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 50px;
  background-color: #474c4e;
  @media(max-width: 1100px) {
    border-top-left-radius: 0;
  }
}
div.gallery-stepper-index-container-wrap {
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 50px;
  font-size: 12px;
  overflow: hidden;
  div {
    padding: 13px 35px 5px 20px;
    background-color: #fff;
    @media(max-width: 1100px) {
      padding-right: 20px;
    }
  }
}
.gallery-actions-wrap {
  float: left;
  width: 100%;
  position: relative;
  &:hover {
    .left-arrow-container {
      opacity: 1;
    }
    .right-arrow-container {
      opacity: 1;
    }
  }
}
.left-arrow-container {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  margin: auto;
  height: 35px;
  width: 35px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 9;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0 0 4px 0 rgba(46,62,72,.06), 0 4px 12px 0 rgba(46,62,72,.06);
  box-shadow: 0 0 4px 0 rgba(46,62,72,.06), 0 4px 12px 0 rgba(46,62,72,.06);
  &:hover {
    -webkit-box-shadow: 0 0 4px 0 rgba(46,62,72,.12), 0 4px 12px 0 rgba(46,62,72,.12);
    box-shadow: 0 0 4px 0 rgba(46,62,72,.12), 0 4px 12px 0 rgba(46,62,72,.12);
  }
  button {
    min-width: 35px;
    min-height: 33px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
  svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 35px;
    height: 35px;
  }
}
.right-arrow-container {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  margin: auto;
  height: 35px;
  width: 35px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 9;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow: 0 0 4px 0 rgba(46,62,72,.06), 0 4px 12px 0 rgba(46,62,72,.06);
  box-shadow: 0 0 4px 0 rgba(46,62,72,.06), 0 4px 12px 0 rgba(46,62,72,.06);
  &:hover {
    -webkit-box-shadow: 0 0 4px 0 rgba(46,62,72,.12), 0 4px 12px 0 rgba(46,62,72,.12);
    box-shadow: 0 0 4px 0 rgba(46,62,72,.12), 0 4px 12px 0 rgba(46,62,72,.12);
  }
  @media(max-width: 1100px) {
    right: 8px;
  }
  button {
    min-width: 35px;
    min-height: 33px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
  svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 35px;
    height: 35px;
  }
}
.garage-price-info {
  float: left;
  width: 100%;
  .price-container {
    background-color: #fff;
    margin: 20px auto;
    display: block;
    text-align: center;
    max-width: 150px;
    border-radius: 10px;
    padding: 8px 5px;
    box-shadow: 0 6px 28px 0 rgba(34, 66, 142, 0.19);
    h3 {
      font-size: 38px;
      font-weight: normal;
      margin: 0;
    }
  }
}
.checkin-checkout-reservation {
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  float: left;
  margin-left: -25px;
  box-shadow: 0 6px 28px 0 rgba(24, 52, 117, 0.2);
  position: relative;
  z-index: 99;
  @media(max-width: 1100px) {
    margin-left: 0;
    border-radius: 0;
  }
  @media(max-width: 991px) {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  @media(max-width: 800px) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .make-a-reservation-btn svg {
    margin-right: 10px;
  }
}
.reservation-total {
  float: left;
  width: 100%;
  padding: 10px 20px 20px 20px;
  border-top: 1px solid #ddd;
  position: relative;
  &:after {
    content: '';
    background-image: url('../images/down-arrow.svg');
    height: 30px;
    width: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: -14px;
    margin: auto;
  }
}
.total-table {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: auto;
}
.total-row {
  float: left;
  width: 100%;
  &.final-amount {
    border-top: 1px solid #ddd;
    padding-top: 3px;
    margin-top: 3px;
    .tooltip-help-icon {
      svg {
        top: 3px;
      }
    }
    .info-col {
      span {
        font-size: 16px;
      }
    }
    .amount-col {
      padding-top: 1px;
      span {
        font-size: 16px;
      }
    }
  }
  .info-col {
    float: left;
    text-align: left;
    width: 125px;
    span {
      font-size: 13px;
    }
  }
  .amount-col {
    float: left;
    text-align: right;
    width: calc(100% - 125px);
    padding-top: 4px;
    span {
      font-size: 13px;
    }
  }
}
.tooltip-help-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 3px;
  svg {
    width: 15px;
    position: absolute;
    top: 4px;
    color: #9e9e9e;
  }
  &:hover {
    svg {
      color: #03a9f4;
    }
  }
}
button.make-a-reservation-btn {
  margin-top: 10px;
  float: left;
  width: 100%;
  background-color: #1e5ff1;
  &:hover {
    background-color: #1ea6f1;
  }
  &.disabled-btn {
    background-color: #8c8c8c;
  }
}
.garage-title-container {
  float: left;
  width: 100%;
  padding: 0 25px 20px;
  h1 {
    margin-bottom: 0;
    @media(max-width: 991px) {
      font-size: 25px;
    }
  }
  h4 {
    margin: 0;
    font-weight: normal;
    padding-left: 20px;
    background-image: url('../images/location-garage.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: 15px;
  }
}
.garage-avatar {
  border: 1px solid #ddd;
  float: left;
  transition: all 0.2s ease-in-out;
  &.no-image-user {
    img {
      width: 20px;
      height: 20px;
    }
  }
}
.garage-owner {
  float: left;
  clear: both;
  margin: 10px 0;
  user-select: none;
  &:hover {
    cursor: pointer;
    .name {
      color: #138be6;
    }
    .garage-avatar {
      border-color: #138be6;
    }
  }
}
.garage-owner-with-rating {
  float: left;
  margin-left: 10px;
  .name {
    float: left;
    width: 100%;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
  .user-rating {
    float: left;
    width: 100%;
  }
  .user-star {
    color: #ffb224;
    width: 18px;
    height: 18px;
    margin-top: 2px;
    float: left;
  }
  .rating {
    float: left;
    margin: 0;
    font-weight: bold;
  }
}
.garage-body-wrap {
  float: left;
  width: 100%;
  padding-bottom: 100px;
  @media(max-width: 991px) {
    padding-bottom: 30px;
  }
}
.garage-body-container {
  display: block;
  margin: auto;
  max-width: 987px;
}
.garage-content-col {
  float: left;
  width: calc(100% - 315px);
  padding: 0 0 20px 0;
  @media(max-width: 991px) {
    width: 100%;
  }
}
.garage-info-bar-col {
  float: left;
  width: 300px;
  right: -26px;
  position: relative;
  @media(max-width: 1100px) {
    width: 280px;
  }
  @media(max-width: 991px) {
    width: 100%;
    right: 0;
    padding: 0 20px 0 20px;
  }
  .garage-map {
    border-radius: 20px;
    margin-top: 20px;
    overflow: hidden;
    float: left;
    width: 100%;
    z-index: 0;
    position: relative;
    @media(max-width: 991px) {
      margin-top: 0;
      width: 60%;
    }
    @media(max-width: 667px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
.garage-map-area-general-location {
  background-color: rgba(33, 243, 177, 0.51);
  border: 2px solid rgba(33, 173, 243, 0.31);
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.mobile-text-header {
  display: none;
  float: left;
  width: 100%;
  border-bottom: 3px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 5px;
  svg {
    margin-bottom: -6px;
    margin-right: 5px;
  }
  @media(max-width: 991px) {
    display: block;
  }
}
.garage-description {
  float: left;
  width: 100%;
  margin: 20px 0 20px;
  white-space: pre-line;
  @media(max-width: 991px) {
    padding: 20px;
    margin: 0;
  }
}
.garage-amenities {
  float: left;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  .amenitie-label {
    float: left;
    width: 16.6666666667%;
    text-align: center;
  }
  .amenitie-icon {
    height: 50px;
    width: 50px;
    margin: auto auto 5px auto;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .check-amenitie {
    svg {
      fill: #a6f946;
      border-radius: 50%;
      border: 2px solid #ccffc4;
    }
  }
  .has-amenitie-true {
    .icon-ac svg {
      fill: #00d3ff;
    }
    .icon-heating svg {
      fill: #ff0061;
    }
    .icon-lift svg {
      fill: #a500ff;
    }
    .icon-paint svg {
      fill: #61e069;
    }
    .icon-welding svg {
      fill: #ffa100;
    }
    .icon-wifi svg {
      fill: #1132ff;
    }
  }
  .has-amenitie-false {
    svg {
      fill: #ddd;
    }
    p {
      color: #ddd;
    }
  }
  .icon-ac {
    svg {
      max-width: 30px;
    }
  }
  .icon-heating {
    svg {
      max-width: 20px;
    }
  }
  .icon-lift {
    svg {
      max-width: 30px;
    }
  }
  .icon-paint {
    svg {
      max-width: 35px;
    }
  }
  .icon-welding {
    svg {
      max-width: 30px;
    }
  }
  .icon-wifi {
    svg {
      max-width: 30px;
    }
  }
}
.slider-container {
  display: flex;
  height: 100%;
  max-height: 550px;
  img {
    object-fit: cover;
    user-select: none;
  }
}
.reservation-cal-container {
  padding: 0 10px 10px 10px;
  transition: all 0.4s ease-in-out;
  &.big-month {
    padding-bottom: 39px;
  }
  .dialog-info {
    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center; 
  }
  .block-out-date-col {
    width: 350px;
    overflow: hidden;
    display: block;
    margin: auto;
  }
  .opening-hours-col {
    float: left;
    width: calc(100% - 350px);
    padding: 0 0 0 20px;
  }
  .schedule-day-block {
    height: 40px;
    width: 40px;
    &.hidden-day {
      opacity: 0;
    }
    &.inpast-day {
      button {
        color: #ddd;
      }
    }
    &.not-current-month-day {
      opacity: 0.2;
    }
    button {
      padding: 1px;
    }
    &.one-day-checkin {
      button {
        background-color: #20a6f1;
        opacity: 1;
        color: #fff;
        border: 1px solid #fff;
        position: relative;
        -webkit-box-shadow: inset 13px 0px 9px -9px rgba(185,79,255,1),inset -13px 0px 9px -9px rgba(79,255,232,1);
        -moz-box-shadow: inset 13px 0px 9px -9px rgba(185,79,255,1),inset -13px 0px 9px -9px rgba(79,255,232,1);
        box-shadow: inset 13px 0px 9px -9px rgba(185,79,255,1),inset -13px 0px 9px -9px rgba(79,255,232,1);
        &:before {
          content: '';
          position: absolute;
          height: 42px;
          width: 42px;
          border: 2px solid #49d3ff;
          border-radius: 50%;
        }
      }
    }
    &.start-date {
      button {
        background-color: #20a6f1;
        -webkit-box-shadow: inset 13px 0px 9px -9px rgba(185,79,255,1);
        -moz-box-shadow: inset 13px 0px 9px -9px rgba(185,79,255,1);
        box-shadow: inset 13px 0px 9px -9px rgba(185,79,255,1);
        opacity: 1;
        color: #fff;
        border: 1px solid #fff;
      }
      &.selected-both button{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }
    }
    &.end-date {
      button {
        background-color: #20a6f1;
        opacity: 1;
        color: #fff;
        border: 1px solid #fff;
        -webkit-box-shadow: inset -13px 0px 9px -9px rgba(79,255,232,1);
        -moz-box-shadow: inset -13px 0px 9px -9px rgba(79,255,232,1);
        box-shadow: inset -13px 0px 9px -9px rgba(79,255,232,1);
      }
       &.selected-both button{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; 
        border-left: 0;
      }
    }
    &.between-start-and-end-date {
      button {
        background-color: #20a6f1;
        opacity: 1;
        color: #fff;
        border-radius: 0;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
      }
    }
    button {
      width: 40px;
      height: 40px;
      position: relative;
      font-weight: normal;
    }

  }
  .not-available {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/block-garage.svg');
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
  }
  .day-object {
    font-size: 14px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.confetti-wrapper-garage {
  position: absolute;
  z-index: 999;
  top: 136px;
  left: 10px;
  margin: auto;
}
.reservation-state-title {
  h4 {
    font-size: 16px;
    padding: 15px 10px;
    text-align: center;
    margin: 0;
    border-bottom: 2px dotted #f5f5f5;
  }
  h4.needs-checkin-title {
    background-image: url('../images/checkin.svg');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: 23px center;
    @media(max-width: 800px) {
      background-position: calc(50% - 109px) center;
    }
  }
  h4.dates-available {
    background-image: url('../images/yey.jpg');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: 23px center;
    @media(max-width: 800px) {
      background-position: calc(50% - 109px) center;
    }
  }
  h4.needs-checkout-title {
    background-image: url('../images/checkout.svg');
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: 23px center;
    @media(max-width: 800px) {
      background-position: calc(50% - 109px) center;
    }
  }
}
.garage-gallery-has-no-photos {
  background-image: url('../images/missing-image.jpg');
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 450px;
  background-size: cover;
  border-top-left-radius: 45px;
  @media(max-width:1100px) {
    border-top-left-radius: 0;
  }
}
.garage-loader-wrap {
  position: relative;
  min-height: 600px;
  width: 100%;
}
.loading-garage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 20px;
  margin: auto;
}
.garage-access-hours {
  margin: 20px auto;
  width: 100%;
  display: block;
  margin: auto;
  padding: 20px;
  max-width: 250px;
  @media(max-width: 991px) {
    float: left;
    padding-left: 35px;
    padding-right: 0;
    padding-top: 0;
    max-width: 40%;
  }
  @media(max-width: 667px) {
    max-width: 100%;
    padding: 20px 0;
  }
}
.hours-wrap {
  float: left;
  width: 100%;
  margin-top: 40px;
  @media(max-width: 991px) {
    margin-top: 0;
  }
}
.hours-information {
  float: left;
  width: 100%;
  max-width: 220px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  position: relative;
  @media(max-width: 991px) {
    max-width: 100%;
  }
  p {
    color: #138be6;
  }
  h4 {
    font-size: 17px;
  }
  .dash-hours {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 4px;
    top: 0;
    bottom: 0;
    width: 15px;
    color: #ddd;
  }
  &.closed {
    border-bottom: 0;
    h4 {
      float: left;
      width: 100%;
      background-color: #ddd;
      border-radius: 7px;
      font-size: 14px;
      padding: 10px 10px;
      text-align: center;
    }
  }
  .hour-info-col {
    float: left;
    width: 50%;
  }
  .closed-at-col {
    float: left;
    width: 50%;
    text-align: right;
  }
}
.day-of-week-title {
  float: left;
  width: 100%;
}
.access-hours-title {
  margin-bottom: 15px;
  float: left;
  width: 100%;
  @media(max-width: 667px) {
    position: relative;
    margin-bottom: 25px;
    &:before {
      content: '';
      top: 30px;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #ddd;
    }
  }
  &.no-hours {
    margin-top: 40px;
  }
  &.is-open-24hours {
    margin-bottom: -10px;
    margin-left: 15px;
  }
  svg.info-hover {
    width: 13px;
    height: 13px;
    float: left;
    margin-left: 5px;
    margin-top: 6px;
    transition: all 0.2s ease-in-out;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  svg.time-icon {
    float: left;
    margin-top: -1px;
  }
  h5 {
    font-size: 17px;
    float: left;
    margin-left: 5px;
  }
}
.garage-access-extra-info {
  float: left;
  width: 100%;
  margin-top: 20px;
  &.no-hours-box {
    margin-top: 0;
  }
  svg.info-hover {
    width: 13px;
    height: 13px;
    margin-left: 5px;
    transition: all 0.2s ease-in-out;
    opacity: 0.5;
    position: relative;
    top: 1px;
    &:hover {
      opacity: 1;
    }
  }
}
.garage-is-24hours {
  display: block;
  background-image: url('../images/24hours.svg');
  background-size: 40px;
  background-repeat: no-repeat;
  padding: 20px 10px 20px 54px;
  background-position: 4px center;
  max-width: 185px;
  margin: auto;
  p {
    font-weight: bolder;
  }
}
.garage-is-appointment-only {
  display: block;
  background-image: url('../images/appointment-needed.svg');
  background-size: 40px;
  background-repeat: no-repeat;
  padding: 20px 10px 20px 54px;
  background-position: 4px center;
  max-width: 185px;
  margin: auto;
  p {
    font-weight: bolder;
  }
}
.garage-is-not-appointment-only {
  display: block;
  background-image: url('../images/no-appointment-needed.svg');
  background-size: 40px;
  background-repeat: no-repeat;
  padding: 20px 10px 20px 54px;
  background-position: 4px center;
  max-width: 185px;
  margin: auto;
  p {
    font-weight: bolder;
  }
}
.no-access-hours-provided {
  float: left;
  width: 100%;
  text-align: center;
  background-color: #e4e4e4;
  border-radius: 20px;
  padding: 10px;
  p {
    float: left;
    margin: auto;
    padding: 5px 5px 5px 10px;
  }
  .info-hover {
    width: 13px;
    float: right;
    margin: 3px 10px 0 5px;
  }
}
.garage-security-wrap {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  float: left;
  width: 100%;
  padding: 10px;
  .security-item {
    float: left;
    width: calc(33.333333333% - 6px);
    margin: 3px;
    border-radius: 5px;
    position: relative;
    &.has-security-true {
      opacity: 1;
    }
    &.has-security-false {
      opacity: 0.2;
      &:before {
        content: '';
        background-image: url('../images/no.svg');
        width: 39px;
        height: 39px;
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        @media(max-width: 667px) {
          left: 0;
          right: 0;
          top: 4px;
          margin: auto;
          bottom: initial;
        }
      }
    }
  }
  .cctv-security-tag {
    background-image: url('../images/camera.svg');
    background-repeat: no-repeat;
    background-position: 15px center;
    text-align: center;
    background-size: 28px;
    padding: 18px;
    padding-left: 40px;
    @media(max-width: 667px) {
      padding: 18px;
      padding-top: 48px;
      background-position: center 10px;
    }
  }
  .security-security-tag {
    background-image: url('../images/alarm.svg');
    background-repeat: no-repeat;
    background-position: 19px center;
    text-align: center;
    background-size: 21px;
    padding: 18px;
    @media(max-width: 667px) {
      padding-top: 48px;
      background-position: center 10px;
    }
  }
  .locked-security-tag {
    background-image: url('../images/locked.svg');
    background-repeat: no-repeat;
    background-position: 20px 15px;
    text-align: center;
    background-size: 19px;
    padding: 18px;
    @media(max-width: 667px) {
      padding-top: 48px;
      background-position: center 10px;
    }
  }
}
.no-hours-but-open {
  position: absolute;
  right: 0;
}
.garage-standards-bar {
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
  .standards-container {
    width: 100%;
    max-width: 750px;
    margin: auto;
    display: block;
  }
  .header-info {
    font-weight: 500;
  }
  .col-item {
    float: left;
    width: 33.33333333%;
    text-align: center;
    padding: 20px;
    @media(max-width: 667px) {
      width: 100%;
    }
    &:nth-child(1) {
      svg {
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
    &:nth-child(2) {
      svg {
        width: 68px;
        margin-bottom: 14px;
        margin-top: 5px;
      }
    }
    &:nth-child(3) {
      svg {
        margin-bottom: 14px;
        margin-top: 5px;
      }
    }
    svg {
      width: 50px;
      display: block;
      margin: auto;
      fill: #ddd;
    }
  }
}
.host-review-title {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-top: 20px;
}
.no-reviews-for-owner {
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #e8fbff;
  border-radius: 20px;
  text-align: center;
  p {
    color: #6298a2;
  }
}
.host-info-card {
  padding: 20px 0;
  max-width: 300px;
  margin: auto;
  margin-top: 40px;
  h4 {
    font-size: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .owner-rating {
    float: left;
    width: 100%;
    p {
      float: left;
      margin: 0;
      font-weight: bold;
    }
    svg {
      float: left;
      width: 20px;
      fill: #ffb224;
    }
  }
  .owner-info {
    float: left;
    width: calc(100% - 100px);
    padding-left: 20px;
    margin-top: 5px;
  }
  .owner-photo {
    float: left;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ddd;
    background-position: center;
    background-size: cover;
    border: 3px solid #ddd;
    &.missing-photo {
      background-image: url('../images/missing-avatar.jpg');
    }
  }
}
.user-verify-row {
  background-color: #f7f7f7;
  float: left;
  padding: 5px;
  border-radius: 25px;
  user-select: none;
  margin-top: 3px;
  margin-left: -15px;
}
.user-verify-title {
  float: left;
  width: 34px;
  height: 34px;
  position: relative;
  background-color: #dcefff;
  border-radius: 50%;
  &:hover {
    svg {
      fill: #00fffc;
    }
  }
  svg {
    transition: all 0.2s ease-in-out;
    width: 16px;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    fill: #6075f9;
  }
}
.user-verify-item  {
  float: left;
  width: 50px;
  span {
    float: left;
    width: 100%;
    text-align: center;
  }
  svg {
    width: 15px;
    height: 15px;
    margin: auto;
    display: block;
  }
  &.is-verified {
    svg {
      fill: #a6f946;
      border-radius: 50%;
      border: 2px solid #ccffc4;
    }
  }
  &.pending {
    svg {
      fill: #ddd;
    }
  }
}
.payment-notes {
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  span {
    color: #848484;
  }
}
.space-coming-soon {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-image: url('../images/coming-soon-bg.png');
  background-size: 100%;
  z-index: 9;
  background-position: center;
}
.space-coming-soon-content {
  height: 70px;
  margin: auto;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  h3 {
    color: #fff;
    font-size: 26px;
    font-weight: bolder;
    text-align: center;
  }
  p {
    color: #fff;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding: 10px 33px;
  }
}
.coming-soon-icon {
  position: absolute;
  top: -65px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  max-width: 50px;
}

button.report-btn {
  color: #394148;
  transition: all 0.2s ease-in-out;
  width: 100%;
  padding: 10px 40px 10px 20px;
  float: left;
  white-space: nowrap;
  margin-top: 40px;
  &:hover {
    background-color: #ffdae7;
    color: #ff005a;
  }
}