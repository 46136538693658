* {
    box-sizing: border-box;
}

a,p,strong,h1,h2,h3,h4,h5 {
  text-decoration: none;
  font-family: 'Roboto';
}

h1,h2,h3,h4,h5 {
  color: #2d2d2d;
}

body {
  margin: 0;
}
.loader-login-spinner {
  margin: 5px 0;
}
svg.verified-user-check {
  color: #0570ff;
  width: 16px;
  margin-right: 7px;
}
.phone-number-verify-wrap {
  float: left;
  width: 100%;
  border-radius: 5px;
  padding: 15px;
  background-color: #eff7ff;
  border-radius: 20px;
  position: relative;
  .verify-phone-placeholder-icon {
    position: absolute;
    left: 12px;
    top: 0;
    bottom: -21px;
    margin: auto;
  }
  label {
    float: left;
    width: 100%;
    display: block;
    position: initial;
  }
}
button.resend-phone-verification {
  float: left;
  width: 100%;
}
.enter-verification-code-phone {
  h4 {
    font-size: 19px;
  }
}
.success-phone-verify {
  h4 {
    font-size: 20px;
    margin-top: 20px;
    float: left;
    width: 100%;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 20px;
  }
}
button.send-verification-phone-btn {
  float: left;
  width: 100%;
  padding: 12px 22px;
  text-transform: none;
  margin: 10px auto 10px auto;
  border: 2px solid #1ea6f1;
  background-color: #1ea6f1;
  color: #fff;
  &.with-icon svg {
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &.loading-verify-btn {
    background-color: #ddd;
    border-color: #ddd;
    padding: 9px 22px;
    &:hover {
      background-color: #ddd;
      border-color: #ddd;
    }
  }
  &:hover {
    background-color: #1e5ff1;
    border-color: #1e5ff1;
  } 
}
div.code-verify-input {
  font-size: 35px;
  font-weight: 100;
}
div.phone-number-verify-input {
  font-size: 30px;
  font-weight: 100;
  text-align: center;
  margin-top: 0;
  padding-left: 25px;
}
.verify-phone-number-hero {
  float: left;
  width: calc(100% + 40px);
  height: auto;
  margin-top: -20px;
  margin-left: -20px;
}
button.verify-success-btn {
  padding: 12px 22px;
  text-transform: none;
  margin: auto;
  border: 2px solid #1ea6f1;
  background-color: #1ea6f1;
  width: 100%;
  color: #fff;
  svg {
    margin-right: 5px;
  }
  &:hover {
    background-color: #1e5ff1;
    border-color: #1e5ff1;
  } 
}
.fullname-missing-form > div:nth-child(3) > div:first-child {
  max-width: 300px;
  h2 {
    margin-bottom: -10px;
  }
  form {
    margin-top: -20px;
  }
  .whoops-title {
    float: left;
    width: 100%;
    color: #3891ff;
  }
  button.update-btn {
    background-color: #1ea6f1;
    color: #fff;
    text-transform: initial;
    margin-left: -10px;
    margin-right: -10px;
    position: relative;
    width: calc(100% + 30px);
    &.is-loading {
      background-color: #ddd;
      &:hover {
        background-color: #ddd;
      }
    }
    &:hover {
      background-color: #1e5ff1;
    }
  }
}
a.link-garage {
  .user-name-with-rating,
  .garage-thumb-price {
    color :#3a3a3a;
  }
}
.app-loader-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  &.dashboard-loader {
    z-index: 99;
    &.loading-stripe {
      z-index: 999;
      .motor-roof-connect-stripe {
        width: 200px;
        height: 50px;
        margin-left: -83px;
        margin-top: -50px;
        background-image: url('../images/stripe-verify.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
      span {
        position: absolute;
        left: -50px;
        right: 0;
        width: 145px;
        text-align: center;
      }
      .main-loader {
        svg {
          color: #4bffd7;
        }
      }
    }
  }
  .loading-app {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 30px;
    height: 30px;
    margin: auto;
    &.stripe-loader {
      width: 40px;
      height: 129px;
      margin: auto;
    }
  }
}
.owner-loader-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  .loading-app {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 30px;
    height: 30px;
    margin: auto;
  }
}
.main-loader {
  svg {
    color: #4bbfff;
  }
}
.no-seller-account-found {
  float: left;
  width: 100%;
  border-radius: 5px;
  text-align: left;
  margin: 10px 0;
  position: relative;
  box-shadow: 0 6px 28px 0 rgba(24, 52, 117, 0.2);
  h2 {
    font-size: 18px;
    font-weight: bolder;
  }
  .info-col {
    float: left;
    width: calc(100% - 83px);
    padding: 30px 10px 30px 40px;
    position: relative;
    z-index: 9;
    &.sidebar-size {
      width: 100%;
      padding: 20px;
      .connect-payout-btn {
        width: 100%;
      }
    }
    @media(max-width: 667px) {
      width: 100%;
      padding: 30px 50px 30px 30px;
    }
  }
  .stripe-col {
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    background-image: url("../images/stripe-bg.svg");
    background-repeat: no-repeat;
    padding: 10px;
    background-position: -2px -5px;
    background-size: 180px;
    height: 100%;
    &.sidebar-size {
      width: 100%;
      position: initial;
      float: left;
      background-image: none;
      width: 100%;
      .about-stripe {
        float: left;
        width: 170px;
        position: absolute;
        bottom: 4px;
        right: 0;
        left: 0;
        margin: auto;
        transform: scale(0.7);
      }
    }
    .about-stripe {
      float: left;
      width: 170px;
      position: absolute;
      bottom: 0;
      right: 0;
      span {
        float: right;
        margin-top: 6px;
      }
      svg {
        width: 69px;
        float: right;
      }
      .stripe-about-btn {
        width: 16px;
        height: 16px;
        float: right;
        margin: 8px 8px 8px -4px;
        color: #909090;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: #6f64f3;
          cursor: pointer;
        }
      }
    }
  }
}
.about-stripe-dialog {
  padding: 30px;
  position: relative;
  h4 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  button.connect-payout-btn {
    margin-top: 0;
    margin-left: 10px;
  }
}
button.connect-payout-btn {
  padding: 12px 22px;
  text-transform: none;
  margin: auto;
  border: 2px solid #6f65f3;
  background-color: #6f65f3;
  color: #fff;
  margin-top: 15px;
  &:hover {
    background-color: #823fff;
    border-color: #823fff;
  }
}
button.close-connect-btn {
  background-color: #fff;
  color: #2f2f2f;
  border: 2px solid #2f2f2f;
  padding: 12px 22px;
  text-transform: none;
  &:hover {
    color: #1e5ff1;
    border-color: #1e5ff1;
    background-color: #fff;
  } 
}
.hide-element {
  display: none !important;
}
.stripe-info-actions-wrap {
  float: right;
  width: 100%;
  margin: 20px auto auto auto;
  button {
    float: right;
  }
}
.forgot-password-dialog {
  z-index: 9999!important;
  h3 {
    font-size: 23px;
  }
}
.forgot-password-email-sent {
  h3 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 20px;
  }
  button {
    margin-bottom: 10px;
  }
}
.checkbox-terms.with-link {
  a {
    font-size: 15px;
  }
}
.privacy-policy-dialog,
.terms-and-conditions-dialog {
  z-index: 99999!important;
}
.rate-your-stay {
  float: left;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  h5 {
    font-size: 16px;
  }
}
.animated-rater {
  transform: translateZ(0);
  float: left;
  width: 100%;
  padding: 20px 0;
  .react-rater > * {
    &:first-child {
      margin-left: 0;
    }
    margin-left: 1rem;
    float: left;
  }
  svg {
    cursor: pointer;
  }
}
.write-review-of-user {
  float: left;
  width: 100%;
  h5 {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.review-loader svg {
  color: #1ea6f1;
}
.review-user-block h5.review-list-title {
  margin-bottom: 10px;
}
button.write-review-btn {
  background-color: #1ea6f1;
  color: #fff;
  text-transform: initial;
  padding: 11px 25px;
  margin-top: 15px;
  float: right;
  &:hover {
    background-color: #1e5ff1;
  }
}
.reservation-reviews {
  float: left;
  width: 100%;
  .review-block {
    padding: 20px;
    background-color: #eaf9fd;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    &.viewers-review {
      background-color: #ecfff4;
    }
  }
}
div.review-block {
  .react-rater {
    float: left;
    width: 100%;
    margin-top: 5px;
    padding-top: 5px;
  }
  .reviewer p {
    font-size: 14px;
    font-weight: bold;
    float: left;
    width: 100%;
  }
  .react-rater div{
    float: left;
    padding-left: 5px;
    &:first-child {
      padding-left: 0;
    }
  }
}
.reviwers-title {
  float: left;
  width: 100%;
  border-bottom: 2px solid #b3efcb;
  margin-bottom: 10px;
  color: #81d8a3;
}
.has-no-review-of-self {
  background-color: #e2faff;
  margin-bottom: 10px;
  padding: 20px;
}
.reviewedby-title {
  float: left;
  width: 100%;
  border-bottom: 2px solid #78cfe6;
  margin-bottom: 10px;
  color: #78cfe6;
}
.user-nav-avatar.no-avatar img,
.user-nav-avatar.no-user-avatar img,
.garage-avatar.no-image-user img {
  background-color: transparent;
}
.user-reviews {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.review-block.for-user {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  background-color: #f4f9fb;
  padding: 11px;
  border-radius: 10px;
  &:nth-child(odd) {
    background-color: #e7f8ff;
  }
}
div.edit-garage-from-fixed-wrap {
  z-index: 9999!important;
}
.dashboard-container.pages {
  padding: 20px;
}
.social-links-row {
  width: 100%;
  margin: auto;
  max-width: 112px;
  display: block;
  &.mobile-nav {
    float: left;
    max-width: 100%;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    span {
      padding-bottom: 10px;
      margin-top: 0;
    }
    a {
      width: 25%;
      float: left;
      margin: auto;
      &.facebook-btn {
        svg {
          margin: auto;
          display: block;
        }
      }
      &.youtube-btn {
        svg {
          margin: auto;
          display: block;
        }
      }
      &.instagram-btn {
        svg {
          margin: auto;
          display: block;
        }
      }
      &.twitter-btn {
        svg {
          margin: auto;
          display: block;
        }
      }
    }
  }
  span {
    float: left;
    width: 100%;
    text-align: center;
    color: #cecece;
    font-size: 10px;
    user-select: none;
    margin-bottom: 3px;
    margin-top: 20px;
  }
  a {
    height: 30px;
    width: 30px;
    position: relative;
    &.facebook-btn svg {
      height: 18px;
      width: 20px;
      margin: -2px 5px auto auto;
      position: relative;
      top: -1px;
      path {
        transition: all 0.3s ease-in-out;
        fill: #adadad;
      }
      &:hover path {
        fill: #3b5998;
      }
    }
    &.youtube-btn svg {
      height: 20px;
      width: 20px;
      margin: auto;
      path {
        transition: all 0.3s ease-in-out;
        fill: #adadad;
      }
      &:hover path {
        fill: #c4302b;
      }
    }
    &.instagram-btn svg {
      height: 20px;
      width: 20px;
      margin: auto 10px auto auto;
      path {
        transition: all 0.3s ease-in-out;
        fill: #adadad;
      }
      &:hover path {
        fill: #dd2a7b;
      }
    }
    &.twitter-btn svg {
      height: 20px;
      width: 20px;
      margin: auto 10px auto auto;
      path {
        fill: #adadad;
        transition: all 0.3s ease-in-out;
      }
      &:hover path {
        fill: #1DA1F2;
      }
    }
  }
}
.hosting-page-dialog {
  @media(max-width: 767px) {
    min-width: 550px;
  }
  @media(max-width: 620px) {
    min-width: 500px;
  }
  @media(max-width: 565px) {
    min-width: auto;
  }
  h4 {
    text-align: center;
    @media(max-width: 667px) {
      min-width: 375px;
    }
    @media(max-width: 488px) {
      min-width: 100%;
    }
  }
  .stripe-info-actions-wrap {
    float: none;
    margin: auto;
    max-width: 86px;
    margin-top: 20px;
  }
}
.embeded-iframe-youtube {
  width: 100%;
  min-width: 539px;
  padding-bottom: 55.9%;
  height: auto;
  position: relative;
  border: 2px solid #ddd;
  @media(max-width: 767px) {
    min-width: 100%;
  }
  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
span.checkbox-payout {
  &:hover {
    background-color: rgba(0, 155, 245, 0.04);
  }
}
span.Mui-checked.checked-payout {
  color: #1b94ff;
}