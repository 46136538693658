.startdate-search-home {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 50px;
  background-image: url('../images/checkin.svg');
  background-size: 23px;
  background-repeat: no-repeat;
  background-position: 5px 5px;
}
svg.search-icon-home {
  position: absolute;
  left: -10px;
  top: 13px;
  bottom: 0;
  color: #ff2f69;
  width: 45px;
  height: 45px;
  margin: auto;
  @media(max-width: 1100px) {
    height: 35px;
    width: 35px;
    left: -5px;
  }
}
button.homepage-search-btn {
  float: right;
  width: 200px;
  background-color: #1ea6f1;
  color: #fff;
  font-size: 24px;
  padding: 11px 7px;
  text-transform: none;
  @media(max-width: 1100px) {
    font-size: 20px;
  }
  span span > *:first-child {
    font-size: 25px;
    @media(max-width: 1100px) {
      font-size: 20px;
    }
  }
  &:hover {
    background-color: #1e5ff1;
  } 
}
.search-form-home {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 20px;
  min-width: 670px;
  clear: both;
  @media(max-width: 1100px) {
    min-width: 560px;
  }
  @media(max-width: 991px) {
    min-width: 100%;
  }
  @media(max-width: 667px) {
    display: none;
  }
}
.search-location-home {
  float: left;
  width: calc(100% - 210px);
  position: absolute;
  .nav-search-input-root {
    width: 100%;
  }
  .nav-search-container-form {
    margin: 0;
    margin-top: -1px;
    max-width: 100%;
    border: 1px solid #ddd;
  }
  .nav-search-input-text {
    font-size: 25px;
    padding: 13px 14px 13px 45px;
    @media(max-width: 1100px) {
      font-size: 20px;
    }
  }
}

.home-slider {
  float: left;
  width: 100%;
  min-height: 600px;
  background-size: 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  background-image: url('../images/homepage-bg.svg');
  background-repeat: no-repeat;
  background-size: 500px;
  background-position: 98% 161px;
  @media(max-width: 991px) {
    background-image: none;
  }
  .slide-1 {
    position: relative;
    min-height: 600px;
    max-width: calc(100% - 250px);
    margin: auto;
    @media(max-width: 800px) {
      max-width: 100%;
    }
    @media(max-width: 667px) {
      height: 500px;
      min-height: 100%;
    }
    .search-homepage-container {
      position: absolute;
      left: 0;
      top: 0;
      bottom: -40px;
      height: 344px;
      width: 310px;
      margin: auto;
    }
    .trust {
      position: absolute;
      opacity: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 500px;
      width: 300px;
      background-image: url('../images/review-first.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      &.active {
        opacity: 1;
      }
    }
    .service {
      position: absolute;
      right: 0;
      opacity: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 500px;
      width: 400px;
      background-image: url('../images/service.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      &.active {
        opacity: 1;
      }
    }
  }
}
.rent-garages-title {
  position: absolute;
  left: 0;
  height: 300px;
  margin: auto;
  top: 130px;
  bottom: 0;
  z-index: 9;
  @media(max-width: 1200px) {
    left: -83px;
    height: 300px;
    top: 19px;
  }
  @media(max-width: 991px) {
    max-width: calc(100% - 40px);
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    top: 160px;
  }
  h1 {
    font-size: 55px;
    margin: 0;
    font-weight: bolder;
    @media(max-width: 1100px) {
      font-size: 45px;
      float: left;
      width: 100%;
    }
    @media(max-width: 991px) {
      padding-bottom: 55px;
    }
    b {
      @media(max-width: 991px) {
        float: left;
        width: 100%;
        position: relative;
        text-align: center;
      }
    }
    span {
      color: #0fdcff;
      position: absolute;
      opacity: 0;
      white-space: nowrap;
      transition: all 1s ease-in-out;
      @media(max-width: 991px) {
        left: 0;
        right: 0;
        margin: auto;
      }
      &.word-slider-1 {
        color: #2ed6a1;
      }
      &.word-slider-2 {
        color: #9037bd;
      }
      &.word-slider-3 {
        color: #8bdc0d;
      }
      &.word-slider-0.currentWordIndex-0 {
        opacity: 1;
      }
      &.word-slider-1.currentWordIndex-1 {
        opacity: 1;
      }
      &.word-slider-2.currentWordIndex-2 {
        opacity: 1;
      }
      &.word-slider-3.currentWordIndex-3 {
        opacity: 1;
      }
    }
  }
  h3 {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
    max-width: 430px;
  }
}
.become-a-host-btn {
  position: absolute;
  bottom: -50px;
  right: 0;
  transition: all 0.01s ease-in-out;
  @media(max-width: 991px) {
    float: left;
    width: 100%;
    bottom: 0;
  }
  a {
    color: #1ea6f1;
    &:hover {
      color: #1e5ff1;
    }
  }
}
.what-can-i-rent-block {
  float: left;
  width: 100%;
  position: relative;
  padding-bottom: 100px;
  overflow: hidden;
  position: relative;
  @media(max-width: 525px) {
    padding-bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 300px;
    background-color: #ccf9fe;
  }
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: 1800px;
    z-index: 1;
    background-position: top center;
    background-image: url('../images/what-bg.svg');
    @media(max-width: 991px) {
      background-size: 1200px;
    }
  }
  .what-can-i-container {
    display: block;
    width: 100%;
    margin: auto;
    max-width: 880px;
    @media(max-width: 991px) {
      max-width: 500px;
    }
    @media(max-width: 525px) {
      max-width: 100%;
    }
  }
  .col-left {
    float: left;
    width: 70%;
    margin-top: 100px;
    @media(max-width: 991px) {
      width: 100%;
    }
    @media(max-width: 590px) {
      margin-top: 50px;
    }
  }
  .what-garage-mobile {
    height: 200px;
    width: 100%;
    float: left;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    display: none;
    background-image: url('../images/host-your-place-shadow.png');
    @media(max-width: 991px) {
      display: block;
    }
  }
  .what-garage {
    height: 100%;
    width: 50%;
    float: left;
    background-size: 450px;
    background-repeat: no-repeat;
    background-position: center left;
    position: absolute;
    left: 51%;
    z-index: 1;
    background-image: url('../images/host-your-place-shadow.png');
    @media(max-width: 991px) {
      display: none;
    }
  }
  .extra-info {
    margin: 20px 0 20px;
    float: left;
    width: 100%;
  }
  .info-block-float {
    float: left;
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 6px 28px 0 rgba(24, 52, 117, 0.2);
    padding: 40px;
    position: relative;
    z-index: 2;
    @media(max-width: 991px) {
      text-align: center;
    }
    @media(max-width: 525px) {
      max-width: 100%;
      border-radius: 0;
      padding-bottom: 80px;
    }
    h2 {
      margin: 0;
      font-size: 38px;
      margin-bottom: 10px;
    }
  }
}
button.home-std-btn {
  float: left;
  width: 100%;
  background-color: #1e5ff1;
  color: #fff;
  text-transform: none;
  padding: 10px;
  max-width: 200px;
  @media(max-width: 991px) {
    max-width: 100%;
  }
  &:hover {
    background-color: #1ea6f1;
  }
}
.become-a-host-block {
  float: left;
  width: 100%;
  padding: 150px 50px;
  box-shadow: -5px -13px 28px 0 rgba(24, 52, 117, 0.15);
  z-index: 99;
  position: relative;
  @media(max-width: 767px) {
    padding: 40px;
    overflow: hidden;
  }
  @media(max-width: 525px) {
    box-shadow: none;
  }
  .become-a-host-container {
    display: block;
    max-width: 500px;
    margin: auto;
    float: left;
    margin-left: 42%;
    @media(max-width: 767px) {
      width: 100%;
      margin: 0;
      max-width: 100%;
      text-align: center;
    }
  }
  .col-right {
    float: right;
    width: 100%;
    margin-right: -50px;
    @media(max-width: 991px) {
      padding: 50px;
    }
    @media(max-width: 850px) {
      margin-right: 0;
    }
    @media(max-width: 767px) {
      padding-top: 250px;
    }
    @media(max-width: 667px) {
      padding: 250px 0 0 0;
    }
    h2 {
      margin: 0;
      font-size: 38px;
      margin-bottom: 10px;
    }
    button {
      margin-top: 20px;
    }
  }
  .col-left {
    float: left;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 573px;
    background-position: -240px 13%;
    background-image: url('../images/motorroof-garage.svg');
    @media(max-width: 850px) {
      left: -119px;
    }
    @media(max-width: 767px) {
      left: 0;
      right: 0;
      margin: auto;
      background-size: 251px;
      background-position: 24px 36px;
      height: 200px;
      bottom: initial;
      top: 67px;
      width: 100%;
      max-width: 344px;
    }
    &:before {
      content: '';
      height: 367px;
      width: 303px;
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      bottom: -18px;
      left: 165px;
      margin: auto;
      background-image: url('../images/approved-guest.png');
      @media(max-width: 767px) {
        height: 227px;
        width: 170px;
      }
    }
  }
}
.saftey-highlights-block {
  float: left;
  width: 100%;
  padding: 50px;
  background-color: #1eeaa3;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    height: 900px;
    width: 1800px;
    background-color: #4fefb6;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transform: skew(70deg, 15deg);
    @media(max-width: 767px) {
      top: -239px;
    }
  }
  &:after {
    content: '';
    height: 900px;
    width: 1800px;
    background-color: #5de8b7;
    position: absolute;
    right: -1200px;
    top: 0;
    bottom: 0;
    transform: skew(-70deg, 15deg);
    @media(max-width: 767px) {
      right: 216px;
      top: 80%;
    }
  }
  .safe-home-icon,
  .twenty-four-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    fill: #ad00fd;
  }
  .safe-home-icon {
    top: 3px;
    fill: #fd0092;
  }
  .hands-home-icon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    fill: #fdb500;
  }
  .safety-container {
    display: block;
    margin: auto;
    max-width: 875px;
  }
  .col-three {
    float: left;
    width: 33.333333333%;
    float: left;
    position: relative;
    z-index: 1;
    @media(max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
    span {
      padding: 10px 15px;
      float: left;
      width: 100%;
      p {
        font-weight: normal;
        margin-top: 0;
        font-size: 16px;
      }
    }
    p {
      float: left;
      width: 100%;
      text-align: center;
      margin-top: 20px;
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      text-shadow: rgba(14, 148, 101, 0.43) 1px 1px 0px;
    }
  }
  .saftey-icon {
    background-color: #fff;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: block;
    margin: auto;
    position: relative;
  }
}
.let-us-build-block {
  float: left;
  width: 100%;
  padding: 150px 50px 200px;
  background-image: url('../images/motorroof-banner-bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  @media(max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 100px;
    background-size: 1200px;
    background-position: center top;
  }
  &:after {
    content: '';
    height: 124px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -42px;
    background-size: 1000px;
    background-image: url('../images/banner-bottom.svg');
  }
  .we-the-people {
    height: 210px;
    width: 220px;
    margin: auto;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../images/wTrust.png');
  }
  .let-us-build-container {
    display: block;
    margin: auto;
    max-width: 550px;
    text-align: center;
    h2 {
      margin: 0;
      font-size: 38px;
      margin-bottom: 10px;
    }
    button {
      float: none;
      margin: 20px auto auto auto;
    }
    .extra-info {
      margin: 20px 0 20px;
      float: left;
      width: 100%;
    }
  }
}
.mini-slider {
  position: absolute;
  right: 0;
  bottom: 60px;
  width: 100%;
  max-width: 700px;
  height: 400px;
  background-size: cover;
  transition: all 3s ease-out;
  opacity: 0;
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: bottom right;
  @media(max-width: 1350px) {
    background-size: 400px;
  }
  @media(max-width: 991px) {
    max-width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center bottom;
  }
}
.mini-slider.mini-slide-0 {
  right: 20px;
  @media(max-width: 1350px) {
    right: -200px;
  }
  @media(max-width: 1100px) {
    right: -300px;
  }
  @media(max-width: 991px) {
    right: 0;
    background-size: 270px;
  }
}
.mini-slider.mini-slide-2 {
  background-size: 350px;
  right: 40px;
  @media(max-width: 1350px) {
    right: -150px;
  }
  @media(max-width: 1100px) {
    background-size: 300px;
  }
  @media(max-width: 991px) {
    right: 0;
    background-size: 230px;
  }
}
.mini-slider.mini-slide-1 {
  background-size: 600px;
  @media(max-width: 1350px) {
    right: -100px;
  }
  @media(max-width: 1100px) {
    right: -200px;
    background-size: 500px;
  }
  @media(max-width: 991px) {
    right: 0;
    background-size: 400px;
  }
}
.mini-slide-0.currentSlide-0,
.mini-slide-1.currentSlide-1,
.mini-slide-2.currentSlide-2,
.mini-slide-3.currentSlide-3 {
  z-index: 3;
  opacity: 1;
}
.what-is-motorroof-wrap {
  float: left;
  width: 100%;
  padding: 150px 0 0 0;
  background-image: url('../images/motorroof-banner-bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  @media(max-width: 950px) {
    background-size: 1200px;
    background-position: center top;
    padding-top: 50px;
  }
  .renter-or-host {
    padding: 50px;
    text-align: center;
    display: block;
    margin: auto;
    max-width: 550px;
    h2 {
      margin: 0;
      font-size: 38px;
      margin-bottom: 10px;
      @media(max-width: 950px) {
        float: none;
        display: block;
      }
    }
  }
  .what-is-container {
    display: block;
    margin: auto;
    max-width: 900px;
    width: 100%;
  }
  .renter-col {
    float: left;
    width: calc(50% - 10px);
    margin-right: 10px;
    border-radius: 20px;
    padding: 150px 20px 20px 20px;
    background-image: url("../images/renter-bg.jpg");
    background-repeat: no-repeat;
    background-size: 168%;
    background-position: -125px -257px;
    box-shadow: 0 6px 20px 0 rgba(24, 52, 117, 0.2);
    position: relative;
    background-color: #fff;
    z-index: 2;
    @media(max-width: 950px) {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-bottom: 20px;
      background-size: 100%;
      background-position: 50% -72px;
      background-image: url('../images/mobile-banner-renter.jpg');
    }
    h2 {
      text-align: center;
      float: left;
      width: 100%;
      @media(max-width: 950px) {
        float: none;
        display: block;
      }
    }
    &:before {
      content: '';
      background-image: url("../images/carpeep.png");
      height: 88px;
      width: 199px;
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 58px;
      margin: auto;
    }
  }
  .host-col {
    float: left;
    width: calc(50% - 10px);
    margin-left: 10px;
    border-radius: 20px;
    padding: 20px;
    background-repeat: no-repeat;
    background-image: url("../images/host-bg.jpg");
    box-shadow: 0 6px 20px 0 rgba(24, 52, 117, 0.2);
    padding: 150px 20px 20px 20px;
    background-size: 105%;
    background-position: -8px -86px;
    position: relative;
    background-color: #fff;
    z-index: 2;
    @media(max-width: 950px) {
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-bottom: 20px;
      background-image: url('../images/mobile-banner-host.jpg');
    }
    &:before {
      content: '';
      background-image: url("../images/motorroof-garage.svg");
      height: 122px;
      width: 199px;
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 32px;
      margin: auto;
    }
    h2 {
      text-align: center;
      float: left;
      width: 100%;
      @media(max-width: 950px) { 
        float: none;
        display: block;
      }
    }
  }
}
.join-the-community {
  float: left;
  width: 100%;
  margin-top: 100px;
  background-color: #eff6f7;
  padding: 70px 20px 100px 20px;
  background-image: url("../images/banner-bottom.svg");
  background-position: bottom center;
  background-size: 1300px;
  background-repeat: no-repeat;
  text-align: center;
  a {
    margin: auto;
    display: block;
    float: none;
    max-width: 200px;
  }
}
.step-row-home {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 50px;
  margin-bottom: 35px;
  @media(max-width: 950px) {
    float: none;
    display: block;
    max-width: 400px;
    margin: auto auto 35px auto;
  }
  span {
    position: absolute;
    left: 0;
    top: 0;
    border: 2px solid #84f8ff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    p {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 18px;
      width: 100%;
      height: 100%;
      text-align: center;
      line-height: 37px;
      font-weight: bolder;
      color: #1b7e84;
    }
  }
}
.host-col .step-row-home {
  span {
    border-color: #b9ffb2;
    p {
      color: #43b22d;
    }
  }
}
.step-2-renter {
  margin-top: -70px;
  @media(max-width: 667px) {
    margin-top: -20px;
  }
  p {
    margin-top: 6px;
  }
}
.step-3-renter {
  margin-top: 11px;
}
.how-to-search {
  margin-left: -20px;
  @media(max-width: 667px) {
    video {
      width: 100%;
      height: auto;
    }
  }
}
.how-to-monitor {
  float: left;
  width: 100%;
  max-width: 297px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #f3f3f3;
  margin-top: 15px;
  padding-top: 17px;
  video {
    float: left;
    width: 100%;
    height: auto;
  }
  @media(max-width: 950px) { 
    float: none;
    display: block;
  }
  .monitor-navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 17px;
    background-color: #f4f4f4;
  }
  .close-monitor {
    height: 10px;
    width: 10px;
    background-color: #ffb7b7;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 3px;
  }
  .min-monitor {
    height: 10px;
    width: 10px;
    background-color: #f7ec91;
    border-radius: 50%;
    position: absolute;
    left: 22px;
    top: 3px;
  }
  .expand-monitor {
    height: 10px;
    width: 10px;
    background-color: #a9f179;
    border-radius: 50%;
    position: absolute;
    left: 36px;
    top: 3px;
  }
}
.left-box-home {
  position: absolute;
  left: -399px;
  bottom: 118px;
  background-color: #84f8ff;
  height: 700px;
  width: 700px;
  transform: rotateZ(45deg);
  @media(max-width: 1200px) {
    left: -600px;
  }
  @media(max-width: 950px) {
    display: none;
  }
}
.right-box-home {
  position: absolute;
  right: -399px;
  bottom: 118px;
  background-color: #8eff99;
  height: 700px;
  width: 700px;
  transform: rotateZ(-45deg);
  @media(max-width: 1200px) {
    right: -600px;
  }
  @media(max-width: 950px) {
    display: none;
  }
}
.no-padding-footer {
  .footer-wrap {
    margin-top: 0;
    border-top: 0;
  }
}
.review-each-other {
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 50px;
  position: relative;
  @media(max-width: 667px) {
    padding: 50px 20px 20px 20px;
  }
  &:before {
    content: '';
    background-image: url('../images/renter-tire-change.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 200px;
    width: 200px;
    position: absolute;
    left: -204px;
    bottom: -121px;
    @media(max-width: 1200px) {
      left: -100px;
    }
    @media(max-width: 950px) {
      display: none;
    }
  }
  &:after {
    content: '';
    background-image: url('../images/host-check-car.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 166px;
    width: 420px;
    position: absolute;
    right: -284px;
    bottom: -121px;
    @media(max-width: 1200px) {
      right: -200px;
    }
    @media(max-width: 950px) {
      display: none;
    }
  }
  .review-each-content {
    max-width: 500px;
    margin: auto;
    display: block;
  }
  .review-preview {
    width: 100%;
    max-width: 300px;
    margin: auto;
    display: block;
    height: 139px;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 50px;
    background-image: url('../images/motorroof-reviews.jpg');
  }
}
.featured-garage-single-wrap {
  float: left;
  width: 100%;
  padding: 20px;
  margin-bottom: 50px;
  @media(max-width: 991px) {
    margin-top: 50px;
  }
  @media(max-width: 590px) {
    background-image: url('../images/homepage-bg.svg');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 0;
    margin-bottom: 0;
    padding: 50px 0;
  }
  .featured-garage-container {
    display: block;
    margin: auto;
    max-width: 1000px;
  }
  .link-garage {
    float: left;
    width: 100%;
    max-width: 350px;
    @media(max-width: 924px) {
      max-width: 275px;
    }
    @media(max-width: 830px) {
      max-width: 350px;
    }
    @media(max-width: 667px) {
      max-width: 275px;
    }
    @media(max-width: 590px) {
      max-width: 350px;
      float: none;
      display: block;
      margin: auto;
    }
    .garage-thumb-image {
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
    }
    .garage-grid-item {
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: translate(-3px, -8px);
      }
    }
  }
}
.featured-garage-single-content {
  float: left;
  width: calc(100% - 350px);
  padding-left: 50px;
  @media(max-width: 991px) {
    padding-left: 25px;
  }
  @media(max-width: 924px) {
    width: calc(100% - 275px);
  }
  @media(max-width: 830px) {
    width: calc(100% - 350px);
  }
  @media(max-width: 667px) {
    width: calc(100% - 275px);
  }
  @media(max-width: 590px) {
    display: none;
  }
  .garage-amenities {
    border-bottom: 0;
    padding-left: 0;
    margin-left: -30px;
    @media(max-width: 991px) {
      width: calc(100% + 50px);
      padding-right: 0;
    }
    @media(max-width: 830px) {
      width: 100%;
      margin-left: -12px;
      padding: 0;
      .ac-label,
      .wifi-label,
      .heating-label {
        display: none;
      }
      .check-amenitie {
        display: none;
      }
      .has-amenitie-false {
        display: none;
      }
      .amenitie-label {
        width: 25%;
        p {
          font-size: 11px;
        }
      }
    }
  }
  .garage-security-wrap {
    padding-left: 0;
    padding-right: 0;
    margin-left: -15px;
    @media(max-width: 1100px) {
      .cctv-security-tag {
        padding: 20px 0 20px 50px;
        text-align: left;
      }
      .locked-security-tag {
        padding: 20px 0 20px 50px;
        text-align: left;
      }
      .security-security-tag {
        padding: 20px 0 20px 50px;
        text-align: left;
      }
    }
    @media(max-width: 991px) {
      width: calc(100% + 35px);
    }
    @media(max-width: 830px) {
      width: 100%;
      border-top: 1px solid #ddd;
      margin-top: 20px;
      padding-bottom: 0;
      .has-security-false {
        display: none;
      }
      .has-security-true {
        width: 100%;
        .cctv-security-tag {
          background-size: 21px;
          padding: 5px 0 0 40px;
        }
        .locked-security-tag {
          padding: 5px 0 0 40px;
          background-size: 13px;
          background-position: 20px;
        }
        .security-security-tag {
          padding: 5px 0 0 40px;
          background-size: 15px;
        }
      }
    }
    @media (max-width: 667px) {
      .has-security-true {
        .locked-security-tag {
          background-position: 17px center;
        }
        .security-security-tag {
          background-position: 15px center;
        }
        .cctv-security-tag {
          background-position: 11px center;
        }
      }
    }
  }
  p.featured-info {
    float: left;
    width: 100%;
    margin: 0;
    @media(max-width: 830px) {
      font-size: 13px;
    }
  }
}

.featured-single-title {
  background-image: url('../images/featured-icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 24px;
  background-size: 18px;
  float: left;
  width: 100%;
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  color: #9e9c4a;
  &.mobile {
    display: none;
    @media(max-width: 590px) {
      display: block;
      max-width: 170px;
      margin: auto;
      float: none;
      position: relative;
      top: -42px;
      font-size: 20px;
    }
  }
}
h1.featured-garage-owner-name {
  float: left;
  width: 100%;
  margin: 0;
}
h4.location-featured {
  float: left;
  width: 100%;
  margin: 0;
  font-size: 13px;
  padding-left: 17px;
  background-image: url('../images/location-garage.svg');
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: left center;
  margin-bottom: 10px;
}






