.host-info-hero {
  float: left;
  width: 100%;
  overflow: hidden;
  display: block;
  padding: 50px 0;
  position: relative;
  @media(max-width: 767px) {
    padding-top: 0;
  }
  svg {
    position: absolute;
    width: 1885px;
    top: -412px;
    left: -474px;
    z-index: 0;
    @media(max-width: 1140px) {
      left: -600px;
    }
    @media(max-width: 991px) {
      left: -800px;
    }
    @media(max-width: 767px) {
      display: none;
    }
  }
}
.intro-host-hero {
  float: left;
  width: calc(100% - 650px);
  height: 400px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  z-index: 3;
  background-image: url('../images/hosting-garage.jpg');
  -webkit-box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025);
  @media(max-width: 991px) {
    width: calc(100% - 550px);
  }
  @media(max-width: 767px) {
    display: none;
  }
}
.mobile-host-hero-wrap {
  @media(max-width: 767px) {
    background-image: url("../images/how-to-host-bg.jpg");
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    top: -20px;
    padding: 50px;
    background-position: center;
  }
}
.how-to-container {
  display: block;
  margin: auto;
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
  @media(max-width: 991px) {
    margin-top: 0;
  }
  @media(max-width: 767px) {
    padding: 0;
  }
}
.intro-host-content {
  margin: 20px 0;
  float: left;
  width: 650px;
  position: relative;
  z-index: 3;
  @media(max-width: 991px) {
    width: 550px;
  }
  @media(max-width: 767px) {
    width: 100%;
    padding: 20px;
    margin: 0;
  }
  h1 {
    font-size: 50px;
    position: relative;
    white-space: nowrap;
    margin-left: -210px;
    z-index: 3;
    @media(max-width: 790px) {
      font-size: 40px;
      margin-left: -157px;
    }
    @media(max-width: 767px) {
      margin: auto;
      text-align: center;
      float: left;
      width: 100%;
      margin-top: 20px;
    }
    @media(max-width: 500px) {
      font-size: 35px;
      white-space: normal;
    }
    span {
      margin-right: 10px;
      background-color: #69e666;
      color: #fff;
      padding: 0 10px;
      position: relative;
      @media(max-width: 767px) {
        display: block;
        margin: auto;
        float: none;
        max-width: 250px;
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 15px 0 15px;
        border-color: #68e666 transparent transparent transparent;
        position: absolute;
        left: 0;
        bottom: -20px;
      }
    }
  }
  .avg-income {
    float: left;
    width: 100%;
    margin-top: 50px;
    padding: 0 20px 0 89px;
    p:nth-child(1) span {
      margin-left: 31px;
      @media(max-width: 500px) {
        float: left;
        width: 100%;
        text-align: center;
        margin-left: 0;
        font-weight: bolder;
        margin-bottom: 10px;
      }
    }
    p:nth-child(2) span {
      margin-left: 15px;
      @media(max-width: 500px) {
        float: left;
        width: 100%;
        text-align: center;
        margin-left: 0;
        font-weight: bolder;
      }
    }
    @media(max-width: 767px) {
      padding: 0;
      margin: 50px auto;
      text-align: center;
    }
    @media(max-width: 500px) {
      margin: 20px auto;
    }
  }
  .hero-action {
    float: left;
    width: 100%;
    margin-top: 50px;
    padding: 0 20px 0 89px;
    @media(max-width: 767px) {
      padding: 20px 0;
      max-width: 350px;
      margin: auto;
      float: none;
      display: block;
    }
    p.list-caption {
      font-size: 12px;
      color: #b1b1b1;
      float: left;
      margin: 12px;
      @media(max-width: 991px) {
        text-align: center;
        width: calc(100% - 24px);
        float: left;
      }
    }
  }
}
.what-is-motorroof-video-wrap {
  float: left;
  width: 100%;
  margin-top: 50px;
  position: relative;
  z-index: 3;
  strong {
    margin-bottom: 10px;
    float: left;
    width: 100%;
  }
  .video-thumb {
    float: left;
    width: 200px;
    height: 125px;
    background-color: #f7f7f7;
    border-radius: 20px;
    border: 3px solid #ddd;
    position: relative;
    transition: all 0.4s ease-in-out;
    background-image: url('../images/hosting-101-thumb-sm.jpg');
    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      background-color: rgba(80, 11, 191, 0.49);
    }
    &:hover {
      cursor: pointer;
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      -webkit-box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
      box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
      svg path {
        fill: #10FFE2;
      }
    }
    svg {
      width: 50px;
      height: 50px;
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      path {
        fill: #fff;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .what-is-container {
    display: block;
    margin: auto;
    max-width: 700px;
    @media(max-width: 767px) {
      max-width: 100%;
      border-top: 1px solid #f7f7f7;
      padding-top: 40px;
      float: none;
      strong {
        margin-top: 20px;
      }
    }
  }
  .video-col {
    float: left;
    width: 235px;
    @media(max-width: 767px) {
      float: none;
      display: block;
      margin: auto;
      .video-thumb {
        float: none;
        display: block;
        margin: auto;
        svg {
          display: block;
        }
      }
    }
  }
  .about-col {
    float: left;
    width: calc(100% - 300px);
    padding-top: 10px;
    @media(max-width: 767px) {
      float: none;
      display: block;
      margin: auto;
      text-align: center;
      width: 100%;
      max-width: 450px;
      padding: 0 20px;
    }
  }
}
.your-rules-wrap {
  float: left;
  width: 100%;
  background-color: #fff;
  padding: 50px;
  z-index: 3;
  position: relative;
  box-shadow: 0 6px 28px 0 rgba(24, 52, 117, 0.2), -5px -13px 28px 0 rgba(24, 52, 117, 0.15);
  .text-content-container {
    max-width: 500px;
    margin: auto;
    display: block;
    text-align: center;
    h2 {
      font-size: 32px;
    }
  }
  .customize-action-wrap {
    float: left;
    width: 100%;
    margin-top: 50px;
  }
  .customize-action {
    display: block;
    margin: auto;
    max-width: 200px;
  }
  .rule-list {
    display: block;
    width: 100%;
    max-width: 900px;
    margin: 50px auto;
  }
  .rule-col {
    float: left;
    width: 20%;
    text-align: center;
    @media(max-width: 767px) {
      width: 33.33333333%;
      &:nth-child(4),
      &:nth-child(5) {
        width: 50%;
        margin-top: 75px;
      }
      &:nth-child(4) {
        padding-left: 10%;
      }
      &:nth-child(5) {
        padding-right: 10%;
      }
    }
    @media(max-width: 500px) {
      width: 100%;
      margin-bottom: 50px;
      &:nth-child(4),
      &:nth-child(5) {
        width: 100%;
        margin: auto;
        float: left;
        padding-left: 0;
        padding-right: 0;
      }
      &:nth-child(4) {
        margin-bottom: 50px;
      }
      &:nth-child(5) {
        margin-bottom: 0;
      }
    }
    svg {
      margin: auto;
      display: block;
    }
    &.open svg {
      width: 100%;
      max-width: 80px;
      height: auto;
      position: relative;
      top: -5px;
    }
    &.schedule svg {
      width: 100%;
      max-width: 80px;
      height: auto;
      position: relative;
      top: -7px;
    }
    &.wrench {
      svg {
        width: 100%;
        max-width: 57px;
        height: auto;
      }
      p {
        margin-top: 23px;
      }
    }
    &.shield {
      svg {
        width: 100%;
        max-width: 53px;
        height: auto;
        position: relative;
        top: -3px;
      }
      p {
        margin-top: 17px;
      }
    }
    &.camera {
      svg {
        width: 100%;
        max-width: 54px;
        height: auto;
      }
      p {
        margin-top: 23px;
      }
    }
  }
}
.what-can-you-list-wrap {
  float: left;
  width: 100%;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
  @media(max-width: 500px) {
    padding: 100px 20px;
  }
  .what-content {
    float: left;
    width: 100%;
    max-width: 400px;
    margin-left: 150px;
    position: relative;
    button {
      max-width: 200px;
      @media(max-width: 500px) {
        max-width: 260px;
      }
    }
    @media(max-width: 991px) {
      margin: auto auto 50px auto;
      float: none;
      h2 {
        text-align: center;
        margin-top: 0;
        position: relative;
        z-index: 4;
      }
      p {
        text-align: center;
        position: relative;
        z-index: 4;
      }
      button {
        float: none;
        display: block;
        margin: auto;
        position: relative;
        z-index: 4;
      }
    }
    h2 {
      font-size: 32px;
      @media(max-width: 767px) {
        padding-top: 108px;
      }
    }
    &:before {
      content: '';
      left: -340px;
      background-image: url('../images/what-to-share-left.jpg');
      height: 333px;
      width: 300px;
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      top: 26px;
      bottom: 0;
      margin: auto;
      @media(max-width: 991px) {
        left: -300px;
      }
      @media(max-width: 767px) {
        top: -150px;
        right: 0;
        left: -500px;
        width: 230px;
      }
    }
    &:after {
      content: '';
      right: -540px;
      background-image: url('../images/what-to-share-right.jpg');
      height: 391px;
      width: 500px;
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      top: 100px;
      bottom: 0;
      margin: auto;
      @media(max-width: 991px) {
        right: -480px;
      }
      @media(max-width: 767px) {
        top: -150px;
        left: 0;
        right: -350px;
        width: 400px;
      }
    }
  }
  .what-can-you-list-container {
    display: block;
    margin: auto;
    max-width: 900px;
  }
  .list-spaces-action {
    float: left;
    width: 100%;
  }
  .list-of-spaces {
    float: left;
    width: 100%;
    @media(max-width: 500px) {
      margin-top: 20px;
      max-width: 212px;
      float: none;
      display: block;
      margin: 20px auto;
    }
    ul {
      padding-left: 0;
      list-style: none;
      float: left;
      width: 100%;
      @media(max-width: 500px) {
        margin-bottom: 0;
        margin-top: 0;
      }
      li {
        padding-left: 15px;
        margin-bottom: 5px;
        background-image: url("../images/plus.svg");
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: left center;
      }
    }
    .left-col {
      float: left;
      width: 60%;
      @media(max-width: 500px) {
        width: 100%;
      }
    }
    .right-col {
      float: left;
      width: 40%;
      @media(max-width: 500px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
.chart-bg {
  float: left;
  width: 100%;
  background-image: url("../images/banner-bottom.jpg");
  background-repeat: no-repeat;
  background-size: 101%;
  padding-bottom: 20%;
  top: -10px;
  position: relative;
}
.host-tool-tri-overflow {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden;
  margin-top: -90px;
}
.host-tools-list {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 10%;
  .tool-list-content-container {
    max-width: 380px;
    margin: auto;
    display: block;
    text-align: center;
    position: relative;
    z-index: 2;
    margin-top: 100px;
    @media(max-width: 500px) {
      padding: 0 20px;
    }
    p {
      margin-top: 15px;
      color: #fff;
    }
    h2 {
      margin: 0;
      font-size: 32px;
      color: #fff;
    }
  }
  .tool-bg-left {
    background-color: #32b8fd;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 150%;
    width: 100%;
    transform: skewY(-5deg);
  }
  .tool-bg-right {
    background-color: #3f87ff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 150%;
    width: 100%;
    transform: skewY(5deg);
  }
  .tool-item-list {
    display: block;
    max-width: 750px;
    margin: 30px auto;
    position: relative;
    z-index: 2;
    .tool-grid-col {
      float: left;
      width: 33.3333333333%;;
      padding: 10px;
      @media(max-width: 550px) {
        width: 50%;
      }
      @media(max-width: 360px) {
        width: 100%;
      }
      .grid-item {
        background-color: #fff;
        width: 100%;
        border-radius: 15px;
        text-align: center;
        padding: 20px 10px;
        box-shadow: 0px 6px 16px 5px rgba(5, 64, 152, 0.05);
        height: 135px;
        position: relative;
        p {
          position: absolute;
          bottom: 15px;
          left: 0;
          right: 0;
          text-align: center;
        }
        &.private-chat {
          svg {
            max-width: 60px;
          }
        }
        &.accept-payments {
          svg {
            max-width: 33px;
          }
        }
        &.approve-booking {
          svg {
            max-width: 60px;
          }
        }
        &.review-guests {
          svg {
            max-width: 60px;
            margin: 3px auto 5px;
          }
        }
        &.calendar-tracking {
          svg {
            max-width: 55px;
            margin: 5px auto;
          }
        }
        &.reminder-system {
          svg {
            max-width: 65px;
          }
        }
      }
    }
  }
}
p.and-more-tools {
  float: left;
  width: 100%;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
  margin: 20px 0 50px 0;
}
.host-tools-action {
  position: relative;
  z-index: 2;
  display: block;
  margin: auto;
  max-width: 200px;
  button.home-std-btn {
    background-color: #093d93;
    &:hover {
      background-color: #1ecaf1;
    }
  }
}
.payment-systems-wrap {
  float: left;
  width: 100%;
  margin-top: 0;
  .stripe-connected-img {
    background-image: url('../images/stripe-connected-slide-1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 250px;
    width: 100%;
    max-width: 400px;
    display: block;
    margin: auto;
  }
  .payment-system-info-container {
    display: block;
    max-width: 900px;
    margin: auto;
    padding: 0 20px;
    .col-left {
      float: left;
      width: 45%;
      padding-right: 40px;
      @media(max-width: 767px) {
        width: 100%;
        text-align: center;
        padding-right: 0;
        button {
          max-width: 300px;
          float: none;
        }
      }
      h2 {
        font-size: 32px;
      }
      button {
        margin-top: 20px;
      }
    }
    .col-right {
      float: left;
      width: 55%;
      @media(max-width: 767px) {
        width: 100%;
        max-width: 465px;
        margin: auto;
        float: none;
      }
      strong {
        float: left;
        width: 100%;
        margin-top: 35px;
        padding-left: 22px;
        margin-bottom: 10px;
        @media(max-width: 767px) {
          padding-left: 0;
          text-align: center;
        }
      }
      ul {
        list-style: none;
        float: left;
        width: 100%;
        margin-top: 0;
        padding-left: 0;
        li {
          margin-bottom: 7px;
          background-image: url('../images/checked.svg');
          background-repeat: no-repeat;
          background-size: 15px;
          background-position: left center;
          padding-left: 21px;
        }
      }
    }
  }
}
.host-get-started-wrap {
  float: left;
  width: 100%;
  border-top: 5px solid #0be8dc;
  padding: 50px 0 100px 0;
  margin-top: 100px;
  .host-get-started-content {
    max-width: 520px;
    display: block;
    margin: auto;
    text-align: center;
    padding: 0 20px;
    h2 {
      font-size: 32px;
    }
  }
  .get-started-grid {
    display: block;
    width: 100%;
    margin: auto;
    max-width: 800px;
    margin-top: 50px;
    .left-col {
      float: left;
      width: 33.333333333%;
      margin-top: 80px;
      @media(max-width: 767px) {
        width: 100%;
        margin-top: 0;
      }
      span {
        float: left;
        width: 100%;
        text-align: center;
        margin-top: 8px;
      }
      &.no-video-left-col {
        svg {
          max-width: 80px;
          margin: auto;
          display: block;
        }
        p {
          font-size: 18px;
          float: left;
          width: 100%;
          text-align: center;
          margin-top: 10px;
          color: #2d2d2d;
          font-weight: bolder;
          margin-bottom: 7px;
        }
         button.home-std-btn {
          background-color: #fff;
          border: 3px solid #1e5ff1;
          color: #1e5ff1;
          padding: 7px;
          float: none;
          display: block;
          margin: auto;
          max-width: 200px;
          span {
            margin-top: 0;
          }
          &:hover {
            background-color: #1e5ff1;
            color: #fff;
          }
        }
      }
      .video-thumb {
        width: 200px;
        height: 125px;
        background-color: #f7f7f7;
        border-radius: 20px;
        border: 3px solid #ddd;
        position: relative;
        transition: all 0.4s ease-in-out;
        display: block;
        margin: auto;
        &:hover {
          cursor: pointer;
          -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
          -webkit-box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
          box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
          svg path {
            fill: #10FFE2;
          }
        }
        svg {
          width: 50px;
          height: 50px;
          margin: auto;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          path {
            fill: #fff;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
    .center-col {
      float: left;
      width: 33.333333333%;
      @media(max-width: 767px) {
        width: 100%;
      }
      .hosting-start {
        background-image: url('../images/w-reserve.jpg');
        height: 130px;
        width: 100%;
        max-width: 180px;
        margin: auto;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-top: 10px;
      }
    }
    .right-col {
      float: left;
      width: 33.333333333%;
      margin-top: 30px;
      @media(max-width: 767px) {
        width: 100%;
      }
      button.home-std-btn {
        background-color: #fff;
        border: 3px solid #1e5ff1;
        color: #1e5ff1;
        padding: 7px;
        float: none;
        display: block;
        margin: auto;
        max-width: 200px;
        &:hover {
          background-color: #1e5ff1;
          color: #fff;
        }
      }
      .live-chat-team {
        background-image: url('../images/motorroof-live-chat.jpg');
        height: 172px;
        width: 100%;
        max-width: 160px;
        margin: auto;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
  .become-a-host-card {
    float: left;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #f1f1f1;
    box-shadow: 0.941px 8.951px 13px 0px rgba(40, 109, 129, 0.16);
    text-align: center;
    @media(max-width: 767px) {
      margin-top: 45px;
      border-radius: 0;
    }
    button {
      max-width: 100%;
      margin-top: 20px;
      @media(max-width: 767px) {
        max-width: 200px;
        float: none;
      }
    }
    .list-caption {
      font-size: 12px;
      color: #b1b1b1;
      float: left;
      width: 100%;
      text-align: center;
      margin-top: 5px;
    }
  }
}
.business-support-wrap {
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 50px 0 65px 0;
  border-bottom: 1px solid #ddd;
  .business-support-content {
    max-width: 500px;
    display: block;
    margin: auto;
    text-align: center;
    h2 {
      font-size: 32px;
      margin-top: 0;
      padding: 0 20px;
    }
    p {
      margin-bottom: 30px;
      padding: 0 20px;
    }
    button {
      max-width: 200px;
      display: block;
      margin: auto;
      float: none;
    }
  }
  .business-hero {
    height: 200px;
    max-width: 350px;
    width: 100%;
    background-image: url('../images/business-support.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    margin: auto;
    display: block;
  }
}
.hosting-tools-top-image {
  max-width: 165px;
  margin-top: -50px;
  width: 100%;
}
.mobile-text-info-support {
  display: none;
  @media(max-width: 767px) {
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 20px 20px 20px;
  }
}










