.verified-email-view {
  > div:first-child {
    background-color: rgba(255, 255, 255, 0.68);
  }
}
.verified-email-container {
  background-image: url('../images/motorroof-banner-bg-welcome.jpg');
  background-repeat: no-repeat;
  background-position: 53% 0%;
  background-size: 200%;
  width: 500px;
}
.confetti-wrapper {
  z-index: 999;
  position: fixed;
  width: 50px;
  left: 0;
  right: 0;
  top: 35%;
  margin: auto;
}
.verified-email-title {
  display: block;
  width: 100%;
  text-align: center;
  padding: 70px 10px 0px 10px;
  p {
    margin: 0;
    font-size: 20px;
    span {
      color: #00b4ff;
      font-weight: bold;
    }
  }
}
.verified-email-info {
  text-align: center;
  margin: 0px auto 50px auto;
  display: block;
  height: 72px;
}
.button-holder-verified {
  display: block;
  left: 0;
  right: 0;
  margin: -20px auto 40px auto;
}
button.verified-next-btn {
  background-color: #1ea6f1;
  color: #fff;
  padding: 12px 22px;
  text-transform: none;
  margin: auto;
  display: block;
  &.thanks {
    color: #1ac332;
    background-color: #d3ffdd;
    &:hover {
      color: #1ac332;
      background-color: #d3ffdd;
      -webkit-box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
      -moz-box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
      box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
    }
  }
  span {
    padding-right: 15px;
  }
  svg {
    position: absolute;
  }
  &:hover {
    background-color: #1e5ff1;
  } 
}