.garage-form-wrap {
  float: left;
  width: 100%;
  position: fixed;
  left: 0;
  max-width: 800px;
  right: 0;
  margin: auto;
  @media(max-width: 991px) {
    position: initial;
    max-width: 100%;
  }
  .garage-form-container {
    display: block;
    margin: auto;
    max-width: 1000px;
    width: 100%;
  }
  .left-form-col {
    float: left;
    width: 600px;
    margin-top: 50px;
    position: fixed;
    @media(max-width: 991px) {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 550px;
      margin: auto;
      float: none;
      padding: 0 20px;
    }
  }
  .listing-title {
    float: left;
    width: 100%;
  }
  .listing-description {
    float: left;
    width: 100%;
  }
}
.preview-garage-col {
  position: absolute;
  right: -400px;
  top: -100px;
  width: 280px;
  @media(max-width: 1300px) {
    right: -300px;
  }
  @media(max-width: 1000px) {
    right: -280px;
  }
  @media(max-width: 991px) {
    display: none;
  }
}
.preview-label {
  font-size: 12px;
  color: #4ac3d2;
}
.stepper-slide-up-container {
  position: relative;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  div.active-step .step-0,
  div.active-step .step-1,
  div.active-step .step-2,
  div.active-step .step-3,
  div.active-step .step-4,
  div.active-step .step-5 {
    svg {
      border-radius: 50%;
      border: 2px solid #00ccff;
      circle {
        color: #fff;
      }
      text {
        fill: #00ccff;
      }
    }
  }
  div.completed-step {
    svg {
      color: #7ee29f;
    }
  }
  &.stepper-0 {
    top: 0;
  }
  &.stepper-1 {
    top: -63px;
  }
  &.stepper-2 {
    top: -126px;
  }
  &.stepper-3 {
    top: -189px;
  }
  &.stepper-4 {
    top: -252px;
  }
  &.stepper-5 {
    top: -315px;
  }
}
.create-new-garage-title {
  z-index: 91;
  position: relative;
}
.list-space-form-container {
  position: relative;
  margin-top: 30px;
  margin-left: -25px;
  @media(max-width: 991px) {
    margin-bottom: 100px;
  }
}
.completed-steps-hideaway {
  position: absolute;
  width: 100%;
  height: 290px;
  top: -270px;
  z-index: 9;
  background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  @media(max-width: 991px) {
    top: -262px;
  }
}
.create-garage-accent-left {
  position: fixed;
  left: 0;
  height: 100%;
  width: 150px;
  background-image: url('../images/sidebar-build-garage.jpg');
  background-color: #43ab92;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;
  @media(max-width: 1100px) {
    width: 7%;
  }
  @media(max-width: 991px) {
    width: 100%;
    position: relative;
    float: left;
    height: 100px;
    background-image: url('../images/garage-add-mobile.jpg');
    background-position: center center;
    background-size: cover;
    z-index: 99;
  }
  .accent-crop {
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 40px;
    border-top-left-radius: 80px;
    @media(max-width: 1100px) {
      display: none;
    }
  }
}
.step-form-actions-container {
  float: left;
  width: 100%;
  margin-top: 20px;
  .back-btn {
    margin-right: 20px;
  }
  .forward-btn {
    background-color: #1ea6f1;
    color: #fff;
    &:hover {
      background-color: #1e5ff1;
    }
  }
}
.build-garage-amenities {
  .check-amenities {
    padding: 10px 20px 10px 45px;
    border: 3px solid #ddd;
    float: left;
    margin: 3px;
    border-radius: 40px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in-out;
    user-select: none;
    &.heating {
      svg {
        fill: #ff0061;
      }
    }
    &.lift {
      svg {
        fill: #a500ff;
      }
    }
    &.paint {
      svg {
        fill: #61e069;
      }
    }
    &.welding {
      svg {
        fill: #ffa100;
      }
    }
    &.wifi {
      svg {
        fill: #1132ff;
      }
    }
    svg {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      fill: #0091FF;
      path {
        transition: all 0.2s ease-in-out;
      }
    }
    &:hover {
      border-color: #38f3c5;
    }
    &.selected-amenitie {
      color: #1ac332;
      background-color: #d3ffdd;
      border-color: #38f3c5;
      svg {
        fill: #38f3c5;
      }
    }
  }
}
.search-address-newgarage-icon {
  color: #ff3c3c;
}
div.search-address-newgarage {
  float: left;
  width: 100%;
}
.mapbox-container {
  position: relative;
  float: left;
  width: 100%;
  height: 200px;
  margin-top: 10px;
  @media(max-width: 991px) {
    height: 150px;
  }
}
.check-address-notification {
  h2.location-found-title {
    font-size: 20px;
    text-align: center;
    margin: 30px 0 20px 0;
    font-weight: normal;
    @media(max-width: 991px) {
      margin-top: 10px;
    }
  }
  .location-found-content {
    font-size: 14px;
    text-align: center;
    max-width: 500px;
    padding: 0 20px;
    margin: auto;
  }
}
.final-publish-requirements {
  margin-bottom: 10px;
  @media(max-width: 667px) {
    span {
      font-size: 13px;
    }
    p.garage-host-guidelines {
      font-size: 13px;
      margin-left: -14px;
      top: 2px;
    }
  }
}
div.checking-address-dialog {
  z-index: 9999!important;
}
.checking-address-dialog > div:nth-child(3) > div:first-child{
  height: 430px;
}
.checking-photos-dialog > div:nth-child(3) > div:first-child {
  padding: 20px;
  max-width: 330px;
  .missing-garage-hero {
    background-image: url("../images/motorroof-garage-missing-image.jpg");
    background-size: 200px;
    height: 125px;
    width: 100%;
    background-position: center bottom;
    margin: auto;
    background-repeat: no-repeat;
  }
  h4 {
    font-size: 20px;
    margin-top: 20px;
    float: left;
    width: 100%;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 20px;
  }
}
.confirm-available-actions-wrap,
.confirm-hours-actions-wrap {
  float: left;
  width: 100%;
}
.confirm-available-actions,
.confirm-hours-actions,
.confirm-photos-actions,
.confirm-location-actions {
  display: block;
  height: 50px;
  max-width: 370px;
  margin: 17px auto 0 auto;
}
.confirm-location-actions {
  @media(max-width: 991px) {
    margin-bottom: 20px;
  }
}
div.confirm-hours-actions {
  max-width: 326px;
}
div.confirm-available-actions {
  max-width: 348px;
}
button.photo-back-btn,
button.address-back-btn {
  background-color: #fff;
  color: #2f2f2f;
  border: 2px solid #2f2f2f;
  padding: 12px 22px;
  text-transform: none;
  @media(max-width: 667px) {
    width: 100%;
    margin-bottom: 10px;
  }  
  &:hover {
    color: #1e5ff1;
    border-color: #1e5ff1;
    background-color: #fff;
  } 
}
button.photo-next-btn,
button.address-next-btn {
  padding: 12px 22px;
  text-transform: none;
  margin: auto;
  margin-left: 15px;
  border: 2px solid #1ea6f1;
  background-color: #1ea6f1;
  color: #fff;
  @media(max-width: 667px) {
    width: 100%;
    margin-left: 0;
  }  
  &:hover {
    background-color: #1e5ff1;
    border-color: #1e5ff1;
  } 
}
.marker-pin-red {
  color: #F23766;
}
.form-container-select {
  float: left;
  width: 100%;
  display: block;
  position: relative;
  &.no-title {
    margin-top: 15px;
  }
  .disable-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
}
.full-width-input {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  &.city-garage-build {
    .col-center,
    .col-right,
    .col-left {
      @media(max-width: 667px) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }
  .col-left {
    float: left;
    width: calc(100% - 290px);
  }
  .col-center {
    float: left;
    width: 125px;
    margin-left: 20px;
    margin-top: -4px;
    label {
      top: 4px;
    }
  }
  .col-right {
    float: left;
    width: 125px;
    margin-left: 20px;
  }
}
.scheduler-form-dialog-title {
  text-align: center;
}
.check-box-center {
  display: block;
  margin: auto;
  max-width: 204px;
}
.scheduler-screen-container {
  padding: 0 30px 30px 30px;
  float: left;
  width: 100%;
  .dialog-info {
    margin-top: -10px;
    margin-bottom: 20px;
    text-align: center; 
  }
  .block-out-date-col {
    width: 350px;
    overflow: hidden;
    display: block;
    margin: auto;
  }
  .opening-hours-col {
    float: left;
    width: calc(100% - 350px);
    padding: 0 0 0 20px;
  }
  .schedule-day-block {
    height: 40px;
    width: 40px;
    &.hidden-day {
      opacity: 0;
    }
    &.inpast-day {
      button {
        color: #ddd;
      }
    }
    button {
      width: 40px;
      height: 40px;
      position: relative;
      font-weight: normal;
    }

  }
  .not-available {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../images/block.svg');
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center;
  }
  .day-object {
    font-size: 14px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.is-24hours-wrap {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}
button.clear-month-btn {
  margin: auto;
  display: block;
  font-size: 11px;
  border: 1px solid #dddd;
  padding: 5px 20px;
  color: #636363;
}
.weekday-row {
  float: left;
  width: 100%;
  position: relative;
}
.hour-row-container {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  @media(max-width: 667px) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 25px;
  }
  &.no-title {
    margin-top: -15px;
  }
  .open-switch-label {
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 13px;
    color: #757575;
  }
  .hour-title {
    width: 100px;
    float: left;
    font-weight: bold;
    margin-top: 20px;
  }
  .switch-col {
    width: 50px;
    float: left;
    padding-top: 15px;
    position: relative;
  }
  .hour-col-label {
    width: 200px;
    float: left;
    @media(max-width: 667px) {
      width: 100%;
    }
  }
  .hour-col-select {
    float: left;
    width: calc(100% - 200px);
    position: relative;
    @media(max-width: 667px) {
      width: 100%;
    }
    .hours-disabled {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 9; 
    }
    .start-hour-col {
      float: left;
      padding-right: 10px;
      width: 145px;
      @media(max-width: 667px) {
        width: calc(50% - 10px);
      }
    }
    .end-hour-col {
      float: left;
      padding-left: 10px;
      width: 145px;
      @media(max-width: 667px) {
        width: calc(50% - 10px);
      }
    }
  }
}
.hours-and-access-container {
  float: left;
  width: 100%;
}
button.verify-phone-btn,
button.change-scheduler-btn,
button.change-access-hours-btn {
  float: left;
  padding: 20px 20px 20px 50px;
  background-color: #fff;
  color: #1ea6f1;
  border: 2px solid #1ea6f1;
  text-transform: none;
  margin-right: 10px;
  @media(max-width: 667px) {
    width: calc(100% - 10px);
    margin-top: 10px;
    margin-right: 0px;
    padding: 10px 10px 10px 25px;
  }
  &:hover {
    border-color: #1e5ff1;
    background-color: #1e5ff1;
    span {
      color: #fff;
    }
    svg {
      color: #fff;
    }
  } 
}
button.verify-phone-btn.verified-phone {
  background-color: #d3ffdd;
  border-color: #38f3c5;
  span {
    color: #1ac332;
  }
  svg {
    color: #38f3c5;
  }
}

button.verify-phone-btn,
button.change-scheduler-btn,
button.change-access-hours-btn {
  span {
    color: #2d2d2d;
  }
  svg {
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 28px;
    height: 28px;
    color: #1ea6f1;
  }
}
.dash-hours {
  height: 50px;
  width: 50px;
  float: left;
  position: relative;
  @media(max-width: 667px) {
    width: 20px;
  }
  .dash {
    position: absolute;
    height: 1px;
    width: 20px;
    top: 19px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: #a5a5a5;
  } 
}
.verify-and-post-step {
  float: left;
  width: 100%;
}
.required-to-post {
  float: left;
  width: 100%;
  .verify-dash-block {
    width: initial;
  }
}
.verify-phone-block > div:nth-child(3) > div:first-child {
  padding: 20px;
  float: left;
  width: 100%;
  max-width: 300px;
}
.creating-public-listing {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -5;
  opacity: 0;
  transition: all 1s ease-in-out;
  background-image: url('../images/motorroof-banner-bg-welcome.jpg');
  background-repeat: no-repeat;
  background-position: center 50px;
  background-size: 1200px;
  &.show-creating {
    opacity: 1;
    z-index: 9999;
    overflow: scroll;
    @media(max-width: 991px) {
      opacity: 1;
      z-index: 99;
      overflow: scroll;
      position: fixed;
    }
  }
  .motorroof-garage-creating {
    background-image: url('../images/motorroof-garage.svg');
    float: left;
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    z-index: 9;
  }
  .motorroof-garage-finish-create {
    background-image: url('../images/garage-post-success.png');
    float: left;
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    z-index: 9;
    background-size: 100%;
    @media(max-width: 767px) {
      height: 200px;
      background-size: 200px;
    }
  }
  .loader-garage-creation {
    position: absolute;
    top: -80px;
    right: 0;
    margin: auto;
    left: 0;
    z-index: 5;
    svg {
      width: 200px;
      height: 300px;
    }
  }
  .waiting-on-creation-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: 300px;
    height: 300px;
    text-align: center;
    &.generated {
      max-width: 850px;
      margin-top: 113px;
      display: block;
      text-align: center;
      position: initial;
      left: initial;
      right: initial;
      bottom: initial;
      height: initial;
    }
    p {
      margin-top: 20px;
      float: left;
      width: 100%;
    }
  }
}
p.garage-host-guidelines {
  font-weight: bolder;
  color: #1ea6f1;
  font-size: 16px;
  transition: 0.2s ease-in-out;
  display: inline-block;
  margin-left: -10px;
  top: 3px;
  position: relative;
  &:hover {
    color: #1e5ff1;
    cursor: pointer;
  }
}
.motorroof-terms-link {
  font-weight: bolder;
  color: #1ea6f1;
  font-size: 16px;
}
.garage-create-success {
  margin: auto;
  display: block;
  width: 100%;
  max-width: 850px;
}
.garage-created-info-col {
  float:left;
  width: 560px;
  padding: 0 20px 20px 75px;
  @media(max-width: 991px) {
    width: 100%;
    padding: 20px;
    clear: both;
    border-top: 3px solid #ddd;
    margin-top: 20px;
    background-color: #fff;
  }
}
.garage-creation-success-col {
  float: left;
  width: 290px;
  @media(max-width: 991px) {
    clear: both;
    display: block;
    margin: auto;
    float: none;
  }
  h2 {
    font-size: 39px;
    font-weight: bold;
    span {
      color: #0194fe;
    }
  }
}
.garage-created-basic-info {
  text-align: left;
  float: left;
  width: 100%;
  h2 {
    font-size: 39px;
    font-weight: bold;
  }
}
.market-listing-info {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 10px;
  span {
    float: left;
    width: 100%;
  }
  input {
    float: left;
    width: 100%;
  }
  div {
    float: left;
    width: 100%;
  }
}
.garage-created-options {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
  button {
    margin-left: 10px;
  }
}
button.hosting-dashboard-btn,
button.share-listing-btn {
  background-color: #fff;
  color: #2f2f2f;
  border: 2px solid #2f2f2f;
  padding: 12px 22px;
  text-transform: none;
  &:hover {
    color: #1e5ff1;
    border-color: #1e5ff1;
    background-color: #fff;
  } 
}
button.view-listing-btn {
  padding: 12px 22px;
  text-transform: none;
  margin: auto;
  border: 2px solid #1ea6f1;
  background-color: #1ea6f1;
  color: #fff;
  &:hover {
    background-color: #1e5ff1;
    border-color: #1e5ff1;
  } 
}
.creating-public-listing .waiting-on-creation-content .copy-wrap {
  position: relative;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  svg {
    float: left;
    color: #fff;
    position: absolute;
    left: -106px;
    right: 0;
    margin: auto;
    top: 16px;
  }
  p {
    color: #fff;
    font-weight: bolder;
    font-size: 21px;
    margin-top: 14px;
  }
  .coping {
    position: absolute;
    user-select: none;
    width: 100%;
    height: 100%;
    background-color: #5dff97;
    z-index: 3;
    opacity: 1;
    display: block;
    transition: all 0.2s ease-in-out;
  }
  .not-copy {
    display: none;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }
}


