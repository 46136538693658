.welcome-screen-view {
  > div:first-child {
    background-color: rgba(255, 255, 255, 0.68);
  }
}
.welcome-slide-container {
  background-image: url('../images/motorroof-banner-bg-welcome.jpg');
  background-repeat: no-repeat;
  background-position: 53% 0%;
  background-size: 200%;
  position: relative;
}
.confetti-wrapper {
  z-index: 999;
  position: fixed;
  width: 50px;
  left: 0;
  right: 0;
  top: 35%;
  margin: auto;
}
.welcome-slide-title {
  display: block;
  width: 100%;
  text-align: center;
  padding: 70px 10px 0px 10px;
  p {
    margin: 0;
    font-size: 20px;
    span {
      color: #00b4ff;
      font-weight: bold;
    }
  }
}
.welcome-hero {
  width: 100%;
  display: block;
  margin: 0px auto 40px auto;
  max-width: 300px;
  height: auto;
  &.step-1 {
   margin: 20px auto 20px auto;
  }
  &.step-2 {
    max-width: 190px;
  }
  &.step-3 {
    max-width: 264px;
  }
}
.welcome-slide-info {
  max-width: 400px;
  text-align: center;
  margin: 0px auto 50px auto;
  display: block;
  height: 72px;
}
div.stepper-dots {
  background-color: #fff;
  max-width: 64px;
  margin: auto;
  position: absolute;
  bottom: 195px;
  left: 0;
  right: 0;
}
button.back-btn-welcome {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  background-color: #ddd;
  width: 40px;
  min-width: initial;
  height: 40px;
  padding: 0;
}
button.welcome-next-btn {
  background-color: #1ea6f1;
  color: #fff;
  padding: 12px 22px;
  text-transform: none;
  margin: auto;
  display: block;
  &.thanks {
    color: #1ac332;
    background-color: #d3ffdd;
    &:hover {
      color: #1ac332;
      background-color: #d3ffdd;
      -webkit-box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
      -moz-box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
      box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
    }
  }
  span {
    padding-right: 15px;
  }
  svg {
    position: absolute;
  }
  &:hover {
    background-color: #1e5ff1;
  } 
}
.button-holder-welcome {
  display: block;
  left: 0;
  right: 0;
  margin: -20px auto 40px auto;
}
.search-address-newgarage {
  float: left;
  width: 100%;
}