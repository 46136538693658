.dashboard-container.reservation-show {
  @media(max-width: 1230px) {
    max-width: 100%;
    margin-top: 0;
  }
  .reservation-card {
    float: left;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 6px 28px 0 rgba(24, 52, 117, 0.2);
    @media(max-width: 1230px) {
      border-radius: 0;
      box-shadow: none;
    }
  }
  .reservation-card-info {
    float: left;
    width: 100%;
    position: relative;
    svg.ticket-info {
      position: absolute;
      top: 12px;
      left: 12px;
      color: #0ff3ff;
    }
    h3 {
      font-size: 16px;
      float: left;
      width: 100%;
      padding: 13px 10px 13px 50px;
    }
    .garage-photo {
      float: left;
      width: calc(100% + 10px);
      height: 145px;
      left: -10px;
      position: relative;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('../images/missing-image.jpg');
    }
  }
  .col-left {
    padding: 0;
    @media(max-width: 1230px) {
      position: fixed;
      height: calc(100% - 100px);
      overflow: scroll;
      background-color: #fff;
      border-right: 1px solid #ddd;
    }
    @media(max-width: 991px) {
      width: 250px;
    }
    @media(max-width: 767px) {
      display: none;
    }
    &.mobile-inline-reservation-basic-info-header {
      @media(max-width: 767px) {
        display: block;
        margin-top: -24px;
        left: -44px;
        width: calc(100% + 88px);
        border-right: 0;
      }
    }
    .float-row {
      float: left;
      width: 100%;
    }
    .type-of-rental-row {
      float: left;
      width: 100%;
      padding: 10px;
      @media(max-width: 767px) {
        float: right;
        width: 50%;
      }
      p span {
        font-size: 12px;
        float: left;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
      }
    }
    .type-label {
      border-radius: 15px;
      color: #fff;
      font-family: 'Roboto';
      font-size: 12px;
      padding: 2px 8px;
      margin-right: 5px;
      user-select: none;
      text-align: center;
      max-width: 115px;
      display: block;
      margin: auto;
      &:first-child {
        margin-bottom: 5px;
      }
      p {
        font-size: 13px;
      }
    }
    .Storage {
      border: 2px solid #7b49ef;
      color: #7b49ef;
    }
    .Workshop {
      border: 2px solid #20d0c9;
      color: #20d0c9;
    }
  }
  .col-right {
    float: left;
    width: calc(100% - 250px);
    padding: 20px;
    border-left: 1px solid #eaeaea;
    position: absolute;
    left: 250px;
    height: 100%;
    @media(max-width: 1230px) {
      position: fixed;
      width: calc(100% - 250px);
      top: 67px;
      left: 250px;
      border-left: 0;
    }
    @media(max-width: 767px) {
      top: 48px;
      left: 0;
      width: 100%;
    }
    &.description-tab {
      position: initial;
      float: left;
      @media(max-width: 1230px) {
        position: fixed;
        width: calc(100% - 250px);
        top: 67px;
        left: 250px;
        border-left: 0;
        background-color: #fff;
        height: calc(100% - 68px);
        overflow: scroll;
        padding-bottom: 150px;
      }
      @media(max-width: 767px) {
        top: 75px;
        left: 0;
        width: 100%;
        height: calc(100% - 167px);
        overflow: scroll;
      }
      header {
        left: 250px;
        @media(max-width: 1230px) {
          left: 0;
        }
        @media(max-width: 767px) {
          position: fixed;
          top: 48px;
          left: 0;
          right: 0;
          width: 100%;
        }
      }
    }
    header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 1;
    }
  }
}
.with-navbar-wrap.reservation-show {
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  &.host-mode {
    background-color: #daf8ff;
  }
}
.chat-container {
  background-color: #e9f9ff;
  background-image: url('../images/motorroof-pattern.jpg');
  background-size: contain;
  position: relative;
  right: 0;
  padding-top: 10px;
  left: -44px;
  overflow: scroll;
  padding-right: 40px;
  height: 100%;
  position: absolute;
  left: 0;
  width: calc(100% + 41px);
  padding-bottom: 145px;
  @media(max-width: 1230px) {
    height: calc(100% - 111px);
  }
}
.chat-form-box {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: rgba(190, 225, 255, 0.31) 0px -11px 12px 0px;
  @media(max-width: 1230px) {
    position: fixed;
    width: calc(100% - 250px);
    left: 250px;
    bottom: 0;
    padding-bottom: 20px;
  }
  @media(max-width: 767px) {
    width: 100%;
    left: 0;
  }
  &.disabled {
    background-color: #dcf5ff;
    height: 55px;
  }
  div.texting-input {
    float: left;
    background-color: #fff;
    width: calc(100% - 150px);
    margin: 0;
    border-radius: 5px;
    &.host-double-buttons {
      width: calc(100% - 300px);
      @media(max-width: 767px){
        width: calc(100% - 175px);
      } 
    }
  }
  button {
    float: left;
    min-width: iniital;
    width: 140px;
    color: #fff;
    text-transform: none;
    margin-left: 10px;
    height: 57px;
    background-color: #1e5ff1;
    &.single-action-guest {
      @media(max-width: 767px) {
        width: 145px;
      }
    }
    @media(max-width:767px) {
      width: 100px;
      margin-left: 5px;
    }
    &:hover {
      background-color: #1ea6f1;
    }
  }
}
.chat-no-messages-yet {
  float: left;
  width: 100%;
  padding: 10px;
  text-align: center;
}
.chat-message-row {
  float: left;
  width: calc(100% - 100px);
  max-width: 700px;
  clear: both;
  margin: 15px 0 15px 70px;
  padding: 15px 15px;
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  word-break: break-word;
  .chat-caption {
    position: absolute;
    left: 15px;
    font-size: 11px;
    bottom: -16px;
    color: #27a4d6;
  }
  &.viewers-message {
    float: right;
    margin: 15px 70px 15px 0;
    background-color: #00a1ff;
    color: #fff;
    word-break: break-word;
    .chat-caption {
      position: absolute;
      right: 15px;
      left: initial;
      font-size: 11px;
      bottom: -16px;
      color: #27a4d6;
    }
    &:after {
      content: '';
      position: absolute;
      right: -7px;
      top: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      left: initial;
      border-width: 5px 0 5px 10px;
      border-color: transparent transparent transparent #00a1ff;
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: -7px;
    top: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 10px 5px 0;
    border-color: transparent #ffffff transparent transparent;
  }
}
.chat-user-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ddd;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-image: url('../images/missing-avatar.jpg');
  top: 0px;
  left: -60px;
  &.denied-message {
    position: relative;
    left: initial;
    float: left;
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  &.viewer-icon {
    right: -60px;
    left: initial;
  }
}
.reservation-actions-and-info {
  .status {
    float: left;
    width: 100%;
    padding: 10px;
  }
  .checkin-checkout-row {
    float: left;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    @media(max-width: 767px) {
      width: 50%;
      border: 0;
    }
    .end-date,
    .start-date {
      text-align: center;
      span p{
        font-size: 12px;
      }
    }
    .start-date {
      margin-bottom: 10px;
    }
  }
  .reservation-status {
    font-size: 14px;
    background-color: #ff097b;
    color: #fff;
    padding: 1px 15px;
    border-radius: 15px;
    width: 198px;
    margin: auto;
    display: block;
    &.pending.guest {
      width: 199px;
    }
    &.approved {
      background-color: #12d67b;
      text-align: center;
    }
    &.denied {
      background-color: #000;
      text-align: center;
    }
    &.canceled {
      background-color: #797979;
      text-align: center;
    }
    &.completed {
      background-color: #fff;
      color: #98f3e8;
      border: 2px solid #98f3e8;
      text-align: center;
    }
  }
  .reservation-actions {
    float: left;
    width: 100%;
    padding: 10px;
    @media(max-width: 767px) {
      justify-content: center;
      display: flex;
    }
    button {
      white-space: nowrap;
      @media(max-width: 767px) {
        text-align: center;
      }
    }
    button.approve-btn {
      background-color: #fff;
      border: 2px solid #0fec9a;
      padding: 10px 20px;
      svg {
        color: #0fec9a;
      }
      &:hover {
        color: #0feccd;
      }
    }
    button.cancel-btn {
      padding: 10px 40px 10px 20px;
      border: 1px solid #ddd;
      &.report-btn {
        text-align: center;
        padding: 10px;
        margin-top: 0;
        border: 0;
        font-size: 13px;
        color: #7b7b7b;
        font-weight: normal;
        border: 0;
      }
      @media(max-width: 767px) {
        max-width: 225px;
      }
      &:hover {
        color: #ff0082;
      }
    }
    button.deny-btn {
      background-color: #fff;
      border: 2px solid #ff0082;
      padding: 10px 45px 10px 20px;
      svg {
        color: #ff0082;
      }
       &:hover {
        color: #ff0082;
      }
    }
  }
}
.reservation-show .reservation-info-panel {
  min-height: 500px;
  padding: 30px 0;
  @media(max-width: 767px) {
    padding: 0;
  }
}
.chat-system-message-wrap {
  float: left;
  width: 100%;
  padding: 20px;
  &.payment-request-system {
    margin-top: 20px;
    &.paid-invoice-msg {
      .payment-requested-title {
        background: #fff;
      }
      .payment-request-details .type-of-request-row p {
        border-top: 5px solid #ffffff;
        color: #585858;
      }
    }
  }
  .system-message {
    display: block;
    width: 100%;
    max-width: 450px;
    margin: auto;
  }
  .system-message-content {
    background-color: #fff;
    border-radius: 10px;
    float: left;
    width: 100%;
    box-shadow: rgba(3, 169, 244, 0.18) 1px 1px 2px;
  }
}
.chat-address-container {
  margin: auto;
  padding: 20px;
  padding-left: 60px;
  position: relative;
  float: left;
  width: 100%;
  border-top: 6px dotted #0fffe2;
  svg {
    position: absolute;
    left: 18px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: #ff4242;
  }
  p {
    font-size: 22px;
  }
}
.system-message-title {
  float: left;
  width: 100%;
  padding: 10px 20px;
}
.system-message-actions {
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px;
  button {
    text-transform: none;
    padding: 5px 20px;
  }
}
p.deny-label {
  margin-bottom: -14px;
  font-weight: 500;
}
.deny-texting-input {
  float: left;
  width: 100%;
}
.reason-for-deny {
  float: left;
  width: 100%;
  padding: 20px;
  border-top: 5px dotted #f1f1f1;
}
.denied-author {
  float: left;
  width: 100%;
  margin-top: 5px;
}
.report-user-input {
  float: left;
  width: 100%;
}
.reservation-infobox {
  float: left;
  width: 100%;
  .about-the-host {
    float: left;
    width: 100%;
  }
  .host-info-title {
    float: left;
    width: 100%;
    padding: 10px 0;
  }
  .host-content {
    float: left;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    p {
      float: left;
      width: 100%;
      clear: both;
      text-align: center;
      padding: 10px 0 0 0;
    }
  }
  .about-host-user-icon {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    display: block;
    margin: auto;
    background-image: url('../images/missing-avatar.jpg');
  }
}
button.reservation-action-info-btn.billing {
  margin-left: 10px;
}
button.res-show-location-btn,
button.reservation-action-info-btn {
  float: left;
  color: #fff;
  text-transform: none;
  background-color: #1e5ff1;
  padding: 15px;
  margin-top: 10px;
  &:hover {
    background-color: #1ea6f1;
  }
}
.pending-reservation-show-banner {
  background-image: url("../images/reservation-garage-banner.jpg");
  background-repeat: no-repeat;
  height: 144px;
  background-position: bottom center;
  background-size: cover;
  margin-top: -26px;
  margin-left: -45px;
  float: left;
  width: calc(100% + 90px);
  margin-bottom: 30px;
  @media(max-width: 1230px) {
    background-position: center 89%;
  }
  @media(max-width: 767px) {
    display: none;
  }
  &.approved {
    background-image: url("../images/garage-approved.jpg");
    background-position: center center;
  }
  &.canceled {
    background-image: url("../images/reservation-garage-banner-canceled.jpg");
  }
}
.reservation-info-approved {
  float: left;
  width: 100%;
  margin-top: 20px;
  border-top: 5px dotted #0fffe2;
  padding-top: 20px;
}
.reservation-show-where-col {
  float: left;
  width: 50%;
  border-right: 1px solid #ddd;
  padding-right: 10px;
  @media(max-width: 1100px) {
    width: 100%;
    margin-bottom: 20px;
    border-right: 0;
  }
  .reservation-location-info {
    position: relative;
    float: left;
    width: 100%;
    padding: 20px 20px 20px 40px;
    border: 1px solid #ddd;
    border-radius: 20px;
    svg {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      color: #ff4242;
    }
    p {
      font-size: 17px;
    }
  }
}
h5.res-title {
  font-size: 17px;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  padding-left: 27px;
  position: relative;
  svg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.garage-access-hours-reservation {
  float: left;
  width: calc(50% - 130px);
  @media(max-width: 1100px) {
    width: 50%;
  }
  @media(max-width: 667px) {
    width: 100%;
  }
  .garage-access-hours {
    float: left;
    width: 100%;
    padding-top: 0;
    @media(max-width: 1100px) {
      padding-left: 0;
      max-width: 100%;
    }
    .access-hours-title {
      margin-top: 0;
      @media(max-width: 667px) {
        margin-bottom: 8px;
      }
      &:before {
        @media(max-width: 667px) {
          display: none;
        }
      }
    }
  }
}
.res-host-info-col {
  float: left;
  width: 130px;
  @media(max-width: 1100px) {
    width: 50%;
    padding-left: 20px;
  }
  @media(max-width: 667px) {
    width: 100%;
    padding-left: 0;
  }
}
.res-approved-content {
  float: left;
  width: 100%;
}
.reservation-price-row {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  p {
    text-align: center;
    float: left;
    width: 100%;
    font-size: 25px;
    span {
     font-size: 12px;
     float: left;
     width: 100%;
     padding-top: 5px;
    }
  }
}
.hosting-mode-title {
  float: left;
  width: 100%;
  position: relative;
  background-color: #70ffea;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 7px 12px 22px 28px;
  margin-bottom: -17px;
  svg {
    position: absolute;
    left: 13px;
    top: 4px;
  }
  p {
    font-size: 15px;
    font-weight: bold;
    float: left;
    width: 100%;
    padding-left: 23px;
  }
}
.mobile-reservation-info {
  display: none;
  float: left;
  width: 100%;
  @media(max-width: 767px) {
    display: block;
  }
  .col-left.mobile-inline-reservation-basic-info-header {
    width: 100%;
    position: relative;
    float: left;
  }
}
.reservation-mobile-footer {
  display: none;
  button.cancel-btn {
    padding: 10px 40px 10px 20px;
    border: 1px solid #ddd;
    width: 100%;
    float: left;
    white-space: nowrap;
    margin-top: 100px;
    &:hover {
      color: #ff0082;
    }
  }
  button.report-btn-mobile {
    text-align: center;
    padding: 10px;
    margin-top: 0;
    border: 0;
    font-size: 13px;
    color: #7b7b7b;
    font-weight: normal;
    border: 0;
  }
  @media(max-width: 767px) {
    display: block;
    margin: auto;
    max-width: 225px;
  }
}
.mobile-price {
  display: none;
  @media(max-width: 767px) {
    display: block;
  }
}
.fixed-actions-container-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-shadow: rgba(63,150,243,0.17) 1px -2px 10px;
  button.deny-btn {
    background-color: #ff0082;
    border: 2px solid #ff0082;
    color: #fff;
    padding: 10px 45px 10px 20px;
    float: right;
    width: calc(50% - 5px);
    height: 62px;
    margin-right: 5px;
    white-space: nowrap;
    @media(max-width: 600px) {
      font-size: 13px;
      padding: 18px 5px;
    }
    span {
      max-width: 179px;
    }
    svg {
      color: #fff;
    }
     &:hover {
      color: #fff;
      background-color: #ff0082;
    }
  }
  button.approve-btn {
    background-color: #0fec9a;
    color: #fff;
    border: 2px solid #0fec9a;
    padding: 10px 20px;
    float: right;
    width: calc(50% - 5px);
    height: 62px;
    white-space: nowrap;
    margin-left: 5px;
    @media(max-width: 600px) {
      font-size: 13px;
      padding: 18px 5px;
    }
    span {
      max-width: 179px;
    }
    svg {
      color: #fff;
    }
    &:hover {
      background-color: #0feccd;
      color: #fff;
    }
  }
}
.review-user-block {
  float: left;
  padding: 15px 90px 100px 15px;
  position: absolute;
  width: calc(100% + 75px);
  height: 100%;
  overflow: scroll;
  background-color: #fff;
  left: 0;
  top: 49px;
  right: 0;
}
.host-message-buttons {
  float: right;
  width: 300px;
  @media(max-width: 767px) {
    width: 174px;
  }
}
.request-texting-input {
  width: 100%;
}
.request-amount-text-input {
  width: 120px;
}
label.amount-label {
  font-size: 12px;
  color: #03c55a;
  margin-bottom: -10px;
}
button.send-payment-request-btn {
  color: #fff;
  background-color: #06c55b;
  padding: 10px 20px;
  &:hover {
    background-color: #0ed4b8;
  }
  &.loading-request {
    background-color: #fff;
    color: #2cda61;
  }
}
.payment-type-top-row {
  float: left;
  width: 100%;
  .col-left {
    float: left;
    width: 120px;
  }
  .col-right {
    float: left;
    padding-left: 20px;
    width: calc(100% - 120px);
  }
}
label.request-select-label {
  font-size: 12px;
  margin-bottom: 7px;
}
.select-type-of-payment > div:first-child{
  padding: 17px 35px 17px 15px;
}
button.request-money-button {
  background-color: #06c55b;
  padding: 10px 20px;
  &.large-screen-btn {
    @media(max-width: 767px) {
      display: none;
    }
  }
  &.mobile-request-btn {
    display: none;
    width: 50px;
    line-height: 15px;
    @media(max-width: 767px) {
      display: block;
    }
  }
  &:hover {
    background-color: #0ed4b8;
  }
}
.payment-requested-title {
  text-align: center;
  width: 100%;
  float: left;
  padding-bottom: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #ffffff;
  background: -moz-linear-gradient(top,  #ffffff 0%, #d3f9ff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ffffff 0%,#d3f9ff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ffffff 0%,#d3f9ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d3f9ff',GradientType=0 ); /* IE6-9 */
  p {
    padding: 10px;
    font-size: 14px;
    float: left;
    width: 100%;
    margin-top: 20px;
  }
  h2 {
    margin-left: -15px;
    span {
      font-size: 21px;
      position: relative;
      margin-right: 6px;
      top: -10px;
    }
  }
}
.payment-request-details {
  float: left;
  width: 100%;
  .type-of-request-row {
    float: left;
    width: 100%;
    p {
      text-align: center;
      border-top: 5px solid #03c55a;
      color: #03c559;
    }
  }
  .request-info-row {
    float: left;
    width: 100%;
    text-align: center;
    border-top: 6px dotted #efefef;
    padding: 10px;
  }
}
.who-is-requesting-payment {
  position: relative;
  margin: auto;
  display: block;
  .chat-user-icon {
    top: -23px;
    left: 0;
    right: 0;
    margin: auto;
    border: 3px solid #fff;
    box-shadow: rgba(3, 169, 244, 0.18) 1px 1px 2px;
  }
}
.guest-actions-for-payment {
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px;
}
p.request-info-label {
  font-size: 12px;
  color: #777;
  &.time-label {
    margin-top: 20px;
    margin-bottom: 5px;
    &.with-form {
      margin-top: 0;
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
    }
  }
  &.invoice-info {
    float: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.invoice-label {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.user-has-paid-invoice {
  float: left;
  width: 100%;
  p {
    text-align: center;
    border-radius: 20px;
    border: 2px solid #03c55a;
    font-size: 13px;
    color: #03c55a;
    display: block;
    max-width: 224px;
    margin: 0 auto 10px auto;
  }
}
.outstanding-invoice-label {
  float: left;
  width: 100%;
  p {
    text-align: center;
    border-radius: 20px;
    border: 2px solid #fc46ff;
    font-size: 13px;
    color: #fc46ff;
    display: block;
    max-width: 224px;
    margin: 0 auto 10px auto;
  }
}
.button-center {
  margin: auto;
  display: block;
  width: 100%;
  max-width: 149px;
  &.has-paid {
    max-width: 119px;
  }
}
button.cancel-payment-btn {
  padding: 10px 20px;
  margin-right: 10px;
}
button.submit-payment-request-btn,
button.pay-request-btn {
  background-color: #06c55b;
  padding: 10px 20px;
  color: #fff;
  text-transform: none;
  &.has-paid {
    background-color: #1e5ff1;
    color: #fff;
    text-transform: none;
    &:hover {
      background-color: #1ea6f1;
    }
  }
  &:hover {
    background-color: #0ed4b8;
  }
}
.pay-request-actions {
  float: right;
  clear: both;
  margin-top: 30px;
  margin-bottom: 15px;
}
.payment-loader-wrap {
  float: left;
  width: 100%;
  max-width: 300px;
  height: 200px;
  position: relative;
}
.payment-loader-container {
  width: 100%;
  max-width: 30px;
  margin: auto;
  display: block;
  height: 30px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  &.processing {
    bottom: 50px;
  }
}
.payment-form-container {
  margin-top: -25px;
}
p.processing-payment-text {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  top: 0;
  bottom: -45px;
  margin: auto;
  height: 25px;
}
.paid-invoice-hero {
  float: left;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 20px;
  svg {
    display: block;
    margin: auto;
    max-width: 300px;
    width: 100%;
    height: auto;
  }
}
.paid-invoice-actions {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.paid-invoice-actions-container {
  display: block;
  margin: auto;
  max-width: 216px;
}
button.payment-success-back-btn {
  background-color: #fff;
  color: #2f2f2f;
  border: 2px solid #2f2f2f;
  padding: 12px 22px;
  text-transform: none;
  float: left;
  &.to-billing {
    border: 0px;
    padding: 14px 22px;
    margin-right: 3px;
    &:hover {
      background-color: #fff;
      border-color: transparent;
    }
  }
  &:hover {
    color: #1e5ff1;
    border-color: #1e5ff1;
    background-color: #fff;
  } 
}
.loading-payment-request-info {
  float: left;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  &.requesting-check {
    background-color: #00f490;
  }
  .reload-your-browser-error-payment {
    font-size: 12px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    height: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .loader-info-container {
    max-width: 30px;
    height: 30px;
    margin: auto;
    display: block;
    svg {
      color: #ddd;
    }
  }
}







