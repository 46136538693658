@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
.inline-upload-message {
  display: inline-block;
}
button.upload-start-button-icon {
  float: left;
  width: 100%;
  position: relative;
  border: 3px dashed #ddd;
  border-radius: 20px;
  height: 130px;
  margin-top: 5px;
  overflow: hidden;
  &:hover {
    border-color: #1e5ff1;
    .drop-upload-icon {
      color: #1e5ff1;
    }
  }
  &.uploading-drop-garage {
    border: 3px solid #33a0ff;
    color: #33a0ff;
    .drop-upload-icon {
      color: #1e5ff1;
      position: absolute;
      left: -145px;
      right: 0;
      margin: auto;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      -webkit-animation-name: hvr-bob-float, hvr-bob;
      animation-name: hvr-bob-float, hvr-bob;
      -webkit-animation-duration: .3s, 1.5s;
      animation-duration: .3s, 1.5s;
      -webkit-animation-delay: 0s, .3s;
      animation-delay: 0s, .3s;
      -webkit-animation-timing-function: ease-out, ease-in-out;
      animation-timing-function: ease-out, ease-in-out;
      -webkit-animation-iteration-count: 1, infinite;
      animation-iteration-count: 1, infinite;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-direction: normal, alternate;
      animation-direction: normal, alternate;
    }
  }
  .drop-upload-icon {
    z-index: 3;
  }
  &.uploading-started {
    border: 3px solid #ddf5e9;
    &:hover {
      border: 3px solid #ddf5e9;
    }
  }
}
.state-of-upload-title {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.stripe-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  text-align: center;
}
svg.rotate-arrow {
  transform: rotate(90deg);
  height: 15px;
  width: 15px;
  margin-right: 5px;
}
svg.camera-icon {
  height: 15px;
  width: 15px;
  display: block;
  margin: auto;
  margin-bottom: 5px;
}

.garage-uploading-button-icon {
  width: calc(33.33333333% - 4px);
  margin: 2px;
  padding-bottom: 20%;
  border-radius: 20px;
  float: left;
  position: relative;
  text-align: center;
  overflow: hidden;
  border: 3px solid #ddf5e9;
  &.order_garage_0 {
    order: 1;
  }
  &.order_garage_1 {
    order: 2;
  }
  &.order_garage_2 {
    order: 3;
  }
  &.order_garage_3 {
    order: 4;
  }
  &.order_garage_4 {
    order: 5;
  }
  &.order_garage_5 {
    order: 6;
  }
  &.order_garage_6 {
    order: 7;
  }
  .photo-upload-started svg{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.ordered-garage-thumb-container {
  display: flex;
  flex-wrap: wrap;
  float: left;
  width: 100%;
}
.garage-image-uploaded-list-item {
  float: left;
  background-size: cover;
  background-color: #ddd;
  background-position: center;
  background-repeat: no-repeat;
  width: calc(33.33333333% - 4px);
  margin: 2px;
  padding-bottom: 20%;
  border-radius: 20px;
  float: left;
  position: relative;
  text-align: center;
  overflow: hidden;
  border: 3px solid #f7f7f7;
  &.order_garage_0 {
    order: 1;
  }
  &.order_garage_1 {
    order: 2;
  }
  &.order_garage_2 {
    order: 3;
  }
  &.order_garage_3 {
    order: 4;
  }
  &.order_garage_4 {
    order: 5;
  }
  &.order_garage_5 {
    order: 6;
  }
  &.order_garage_6 {
    order: 7;
  }
  &:hover {
    .thumb-actions {
      opacity: 1;
      transform: translate(0,0);
    }
  }
}
.thumb-actions {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translate(0,80px);
}
button.set-lead-photo-btn {
  position: absolute;
  top: 0;
  left: -50px;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  float: right;
  padding: 0;
  min-width: initial;
  color: #fff;
  background-color: #146aea;
  border: 2px solid #146aea;
  &.lead {
    color: #4cf7ff;
    background-color: #146aea;
    border: 2px solid #14eaea;
  }
  &:hover {
    background-color: #2364c3;
  }
}
button.delete-photo-btn {
  position: absolute;
  top: 0;
  right: -50px;
  left: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  float: right;
  padding: 0;
  min-width: initial;
  color: #fff;
  background-color: #e81a5d;
  border: 2px solid #e81a5d;
  &:hover {
    background-color: #ff2e85;
    color: #fff;
  }
}
.lead-photo-label {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  svg {
    height: 12px;
    width: 12px;
    display: inline-block;
    margin: 2px 3px -2px 0;
  }
  p {
    font-size: 12px;
    display: inline-block;
  }
}