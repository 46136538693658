.primary-navigation-wrapper { 
  div.is-searching-input {
    z-index: 9999;
  }
  div.outside-search {
    z-index: 9991;
  }
  .app-bar-nav {
    position: fixed;
    top: -2px;
    left: 0;
    right: 0;
    width: 100%;
    height: 67px;
    background-color: #fff;
    z-index: 9999;
    -webkit-box-shadow: 0px 2px 33px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 2px 33px 0px rgba(0,0,0,0.1);
    @media(max-width: 767px) {
      height: 50px;
      > div:first-child {
        padding: 0 5px;
      }
    }
    > div:first-child {
      display: initial;
      float: left;
      width: 100%;
    }
  }
  form  {
    > div:first-child {
      width: 100%;
    }
  }
  .navigation-list {
    float: right;
    margin: 24px 0 0 0;
    @media(max-width: 991px) {
      a {
        display: none;
        &.log-in-nav-btn {
          display: block;
        }
        &.sign-up-nav-btn {
          display: block;
        }
      }
    }
    @media(max-width: 767px) {
      margin-top: 15px;
      a {
        &.sign-up-nav-btn {
          margin-right: 0;
        }
      }
    }
    @media(max-width: 550px) {
      a {
        &.log-in-nav-btn {
          font-size: 12px;
          margin-right: 13px;
          padding: 2px 0;
          font-weight: bolder;
        }
        &.sign-up-nav-btn {
          font-size: 12px;
          button {
            font-size: 12px;
            min-width: initial;
            padding: 8px;
          }
        }
      }
    }
    a {
      font-family: 'Roboto';
      color: #394148;
      text-decoration: none;
      margin-right: 20px;
      font-size: 15px;
      float: left;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #33a0ff;
      }
      button {
        text-transform: none;
        margin-top: -0.65em;
        font-size: 15px;
        padding: 7px 20px 8px;
        background-color: #1ea6f1;
        color: #fff;
        &:hover {
          background-color: #1e5ff1;
        }
      }
    }
  }
}
.motorroof-logo-navbar {
  width: 75px;
  float: left;
  margin-top: 10px;
  .st0{fill:#10FFE2;}
  .st1{fill:#231F20;}
  .st2{fill:#2D2D2D;}
  @media(max-width: 767px) {
    margin-top: 8px;
    width: 54px;
  }
}
.username-navbar {
  @media(max-width: 767px) {
    display: none;
  }
}
.user-menu-button-container {
  float: right;
  margin-top: -17px;
  margin-right: 20px;
  @media(max-width: 991px) {
    margin-right: 0;
    margin-top: -18px;
  }
  .user-nav-avatar {
    margin-right: 10px;
    border: 2px solid #f1f1f1;
    background-image: url('../images/user-placeholder.jpg');
    background-size: cover;
    @media(max-width: 767px) {
      margin-right: 0;
    }
    &.has-avatar {
      img {
        max-width: initial;
        object-fit: cover;
      }
    }
    img {
      max-width: 20px;
      object-fit: initial;
    }
  }
  .user-nav-avatar-loading {
    margin-right: 10px;
    .loading-avatar {
      color: #ddd;
    }
  }
}
button.user-menu-button {
  color: #000;
  text-transform: initial;
  @media(max-width: 767px) {
    min-width: initial;
    width: 36px;
    padding: 0;
    border-radius: 50%;
    top: 9px;
    right: 10px;
  }
  &:hover {
    background-color: #fff;
  }
}
.with-navbar-wrap {
  padding-top: 67px;
  @media(max-width: 767px) {
    padding-top: 49px;
  }
}
.menu-is-open > div:nth-child(3){
  margin-top: 40px;
}
.menu-is-closed > div:nth-child(2){
  margin-top: 40px;
}
.autocomplete-navbar-results {
  float: left;
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-bottom: -4px;
  box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
}
.searching-locations {
  float: left;
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-bottom: -4px;
  color: #bbb;
  box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fff;
  user-select: none;
  cursor: initial;
  overflow: hidden;
  padding: 10px 12px;
  span {
    margin-left: 14px;
    top: -3px;
    position: relative;
  }
  &:hover {
    background-color: #fff;
  }
}
.location-search-pin {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  svg {
    float: left;
    width: 100%;
    height: auto;
    fill: #d2d2d2;
    transition: all 0.2s ease-in-out;
  }
}
.search-result-row {
  float: left;
  width: 100%;
  color: #5a5a5a;
  padding: 10px 20px 10px 44px;
  user-select: none;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  position: relative;
  text-align: left;
  &:hover {
    background-color: #f1f1f1;
    cursor: pointer;
    svg {
      fill: #ff0f76;
    }
  }
  &.selected-by-key {
    background-color: #f1f1f1;
    svg {
      fill: #ff0f76;
    }
  }
}
div.is-searching-input {
  position: relative;
  z-index: 9;
}
div.is-searching-input > div:first-child{
  background-color: white;
  border: 3px solid #f1f1f1;
  box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.1);
}

div.nav-search-container-form {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  float: left;
  max-width: 350px;
  margin-top: 12px;
  margin-left: 20px;
  padding: 4px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 9999;
  &.full-width-mobile-search {
    @media(max-width: 767px) {
      width: calc(100% - 62px);
    }
    @media(max-width: 550px) {
      width: calc(100% - 62px);
    }
  }
  @media(max-width: 991px) {
    z-index: 9999;
  }
  @media(max-width: 767px) {
    margin: 3px;
    border-radius: 0;
    margin-left: 5px;
    max-width: 100%;
    width: calc(100% - 230px);
    &.homepage-search {
      border-radius: 10px;
      width: 100%;
    }
  }
  @media(max-width: 550px) {
    width: calc(100% - 174px);
  }
  &:hover {
    background-color: #f1f1f1;
  }
}
div.searching-info-bar {
  height: 4px;
  width: calc(100% - 10px);
  margin-left: 5px;
  float: left;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
  @media(max-width: 767px) {
    border-radius: 0;
  }
}
div.has-results-bar {
  background-color: #0fb5ff;
  height: 4px;
  width: calc(100% - 10px);
  margin-left: 5px;
  float: left;
  border-radius: 60px;
}
.searching-navbar-progress,
div.has-results-bar div:first-child {
  border-radius: 60px;
  overflow: hidden;
  position: relative;
  > div {
    background-color: #12d1ff;
  }
}
div.nav-search-icon {
  width: 15px;
  height: 23px;
  position: absolute;
  pointer-events: none;
  align-items: center;
  top: 12px;
  left: 13px;
  margin: auto;
  color: #394148;
}
div.nav-search-input-root {
  color: inherit;
}
input.nav-search-input-text {
  color: #394148;
  width: 100%;
  padding: 10px 10px 10px 42px;
}
div.outside-search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.full-dash-navlist {
  @media(max-width: 991px) {
    display: none;
  }
}
.mobile-nav-menu-btn {
  display: none;
  z-index: 9991;
  position: relative;
  @media(max-width: 991px) {
    display: block;
  }
}
.full-nav-logo {
  display: block;
  @media(max-width: 991px) {
    display: none;
  }
}
.mobile-menu-drop {
  position: fixed;
  top: 67px;
  bottom: 0;
  height: calc(100% - 67px);
  width: 100%;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 99;
  color: #545558;
  overflow: scroll;
  transition: top 0.5s ease-in-out;
  a {
    color: #535458;
  }
  &.closed {
    top: -100%;
  }
  &.is-open {
    top: 67px;
    @media(max-width: 767px) {
      top: 48px;
      border-top: 1px solid #ddd;
    }
  }
  @media(max-width: 767px) {
    top: 49px;
    bottom: 0;
    height: calc(100% - 49px);
  }
  nav {
    padding-top: 0;
    padding-bottom: 50px;
  }
}
.menu-loader {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  left: 0;
  right: 0;
  svg {
    color: #ddd;
  }
}
a.list-item-button-mobile,
div.list-item-button-mobile {
  padding-left: 132px;
  @media(max-width: 767px) {
    padding-left: 77px;
  }
}
.signup-login-top-mobile {
  display: none;
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 18px;
  a {
    font-family: 'Roboto';
    color: #394148;
    text-decoration: none;
    margin-right: 20px;
    font-size: 15px;
    float: left;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #33a0ff;
    }
    button {
      text-transform: none;
      margin-top: -0.65em;
      font-size: 15px;
      padding: 7px 20px 8px;
      background-color: #1ea6f1;
      color: #fff;
      &:hover {
        background-color: #1e5ff1;
      }
    }
  }
  @media(max-width: 991px) {
    display: block;
    z-index: 9999;
    a {
      display: none;
      &.log-in-nav-btn {
        display: block;
      }
      &.sign-up-nav-btn {
        display: block;
      }
    }
  }
  @media(max-width: 767px) {
    margin-top: 15px;
    a {
      &.sign-up-nav-btn {
        margin-right: 0;
      }
    }
  }
  @media(max-width: 550px) {
    a {
      &.log-in-nav-btn {
        font-size: 12px;
        margin-right: 13px;
        padding: 2px 0;
        font-weight: bolder;
      }
      &.sign-up-nav-btn {
        font-size: 12px;
        button {
          font-size: 12px;
          min-width: initial;
          padding: 8px;
        }
      }
    }
  }
}
.mobile-background-bar {
  display: none;
  height: 65px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  @media(max-width: 991px) {
    display: block;
  }
  @media(max-width: 767px) {
    height: 47px;
  }
}
.motorroof-nav-icon {
  height: 14px;
}
