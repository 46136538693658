.edit-profile-container {
  display: block;
  margin: auto;
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;
  .col-left {
    float: left;
    width: 250px;
    padding-top: 20px;
    padding-left: 10px;
  }
  .col-right {
    float: left;
    width: calc(100% - 250px);
    padding: 20px;
  }
}
.edit-profile-wrap {
  padding-top: 67px;
  @media(max-width: 767px) {
    padding-top: 49px;
  }
}
.edit-about-info-view {
  > div:first-child {
    background-color: rgba(255, 255, 255, 0.68);
  }
}
.ask-about-info-wrap {
  float: left;
  width: 100%;
}
.edit-about-info-container {
  padding: 20px;
  max-width: 500px;
  width: 100%;
  min-width: 450px;
  @media(max-width: 768px) {
    min-width: 100%;
  }
}
.edit-about-info-title {
  display: block;
  width: 100%;
  text-align: center;
  &.edit-profile-title {
    text-align: left;
  }
  p {
    margin: 0;
    font-size: 20px;
    span {
      color: #00b4ff;
      font-weight: bold;
    }
  }
}
.edit-about-info {
  float: left;
  width: 100%;
}
.button-holder-about-info {
  float: left;
  width: 100%;
  max-width: 600px;
  button {
    float: right;
    margin: 0;
  }
}
button.about-info-save-btn {
  background-color: #1ea6f1;
  color: #fff;
  padding: 12px 22px;
  text-transform: none;
  margin: 10px 0;
  display: block;
  &.close-box-btn {
    margin: 10px auto;
  }
  &.loading {
    background-color: #ddd;
  }
  &.thanks {
    color: #1ac332;
    background-color: #d3ffdd;
    span {
      padding-right: 15px;
    }
    &:hover {
      color: #1ac332;
      background-color: #d3ffdd;
      -webkit-box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
      -moz-box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
      box-shadow: inset 0px 0px 0px 2px rgba(102,255,79,1);
    }
  }
  svg {
    position: absolute;
  }
  &:hover {
    background-color: #1e5ff1;
  } 
}
.edit-about-info-form {
  float: left;
  max-width: 600px;
  width: 100%;
}
button.edit-about-info-btn {
  text-transform: none;
  margin: auto;
  border: 2px solid #1e5ff1;
  background-color: #1e5ff1;
  color: #fff;
  width: 100%;
  padding: 5px;
  &:hover {
    background-color: #1ea6f1;
    border-color: #1ea6f1;
  }
}
.verify-desc-success-image {
  width: 100%;
  float: left;
  img {
    max-width: 200px;
    display: block;
    margin: auto;
  }
  p {
    max-width: 300px;
    margin: auto;
    text-align: center;
  }
}
p.profile-form-break-title {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: -10px;
}
.progress-saving {
  margin-bottom: -2px;
}
.saving-btn-dash {
  span {
    margin-left: 5px;
  }
  svg {
    color: #4bbfff;
  }
}
.subscribe-mailing-list {
  float: left;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.subscribe-toggle {
  margin-left: 10px;
  display: flex;
  align-items: center;
}