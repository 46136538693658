.footer-wrap {
  float: left;
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 40px;
  margin-top: 40px;
}
.footer-content-container {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 800px;
}
.footer-link-col-container {
  float: left;
  width: calc(100% - 100px);
  @media(max-width: 767px) {
    width: 100%;
    margin-top: 20px;
  }
}
.footer-logo-col {
  float: left;
  width: 100px;
  @media(max-width: 767px) {
    float: none;
    display: block;
    margin: auto;
    width: 50px;
  }
  svg {
    width: 50px;
    .st0 {
      fill: #10FFE2;
    }
    .st2 {
      fill: #2D2D2D;
    }
  }
}
.footer-sub-nav-container {
  display: block;
  margin: auto;
  max-width: 800px;
  width: 100%;
  @media(max-width: 991px) {
    max-width: 614px;
  }
}
.footer-sub-left-col.social-links-row {
  float: left;
  @media(max-width: 767px) {
    float: none;
    display: block;
    margin: auto;
  }
  span {
    text-align: left;
    margin-left: 5px;
    @media(max-width: 767px) {
      text-align: center;
      margin-left: 0;
    }
  }
}
.footer-sub-right-col.support-number {
  float: right;
  @media(max-width: 767px) {
    float: none;
    display: block;
    margin: auto;
    margin-bottom: 20px;
  }
  span {
    float: left;
    width: 100%;
    text-align: right;
    color: #cecece;
    font-size: 10px;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 0;
    margin-top: 20px;
    @media(max-width: 767px) {
      text-align: center;
    }
  }
  p {
    color: #b5b5b5;
    float: right;
    @media(max-width: 767px) {
      float: none;
      display: block;
      max-width: 133px;
      margin: auto;
      left: -10px;
      position: relative;
    }
    svg {
      float: left;
    }
  }
}
.footer-link-col {
  float: left;
  width: 33.33333333333%;
  @media(max-width: 767px) {
    width: 100%;
    text-align: center;
    a {
      text-align: center;
      width: 100%;
    }
  }
  a {
    float: left;
    clear: both;
    color: #394148;
    transition: 0.1s ease-in-out;
    margin-bottom: 10px;
    &:hover {
      color: #33a0ff;
    }
  }
}
.copy-and-claim-row {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  padding-bottom: 10px;
  span {
    color: #7b7b7b;
  }
}
