.dashboard-navigation {
  float: left;
  width: 100%;
  padding: 20px;
  a {
    width: 100%;
    float: left;
  }
}
.dashboard-container {
  display: block;
  margin: auto;
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;
  @media(max-width: 991px) {
    margin-top: 0;
  }
  .col-left {
    float: left;
    width: 250px;
    padding-top: 20px;
    padding-left: 10px;
    @media(max-width: 991px) {
      width: 100%;
      .dashboard-navigation {
        width: calc(100% - 260px);
        .user-photo {
          width: 200px;
          float: left;
          margin: 0;
        }
        .user-image {
          overflow: hidden;
          border-radius: 10px;
        }
        button.click-to-upload-btn {
          border-radius: 10px;
          overflow: hidden;
        }
        a {
          float: left;
          width: calc(100% - 220px);
          margin-left: 20px;
        }
      }
      .list-your-space-dashblock {
        float: left;
        width: 230px;
        margin-top: -1px;
        margin-left: 10px;
        p {
          margin: 0;
        }
      }
      .host-your-space-image {
        max-width: 127px;
        margin-top: -4px;
      }
    }
    @media(max-width: 667px) {
      .dashboard-navigation {
        width: calc(100% - 15px);
        .user-photo {
          width: 165px;
          height: 165px;
        }
      }
    }
  }
  .col-right {
    float: left;
    width: calc(100% - 250px);
    padding: 20px;
    @media(max-width: 991px) {
      width: 100%;
    }
    .subtitle {
      color: #717171;
      margin: 1px;
      float: left;
    }
  }
}
button.dash-btn {
  color: #394148;
  transition: all 0.2s ease-in-out;
  width: 100%;
  padding: 3px;
  margin:5px 0;
  span {
    text-transform: none;
    width: 100%;
    text-align: left;
  }
  &.active {
    color: #33a0ff;
    border: 2px solid #33a0ff;
  }
  &:hover {
    color: #33a0ff;
    background-color: #f7f7f7;
  }
}
button.click-to-upload-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 4;
  color: #fff;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
    background-color: rgba(86, 216, 255, 0.3);
  }
}
.user-photo {
  height: 200px;
  margin: -20px 0 20px -20px;
  width: calc(100% + 40px);
  background-color: #f7f7f7;
  position: relative;
  .user-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.no-image-found {
      background-image: url('../images/missing-avatar.jpg');
    }
  }
  .trust-meter {
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 200px;
    height: 25px;
    border: 2px solid #fff;
    background-color: #fff;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    z-index: 9;
    &:hover {
      border-color: #33a0ff;
    }
  }
  .meter-bar {
    height: 8px;
    width: calc(100% - 24px);
    border-radius: 20px;
    margin: auto;
    position: absolute;
    background-image: url('../images/verify-bar.jpg');
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background-size: 185px;
    &.level-1 {
      left: 12px;
      right: initial;
      width: 10%;
    }
    &.level-2 {
      left: 12px;
      right: initial;
      width: 20%;
    }
    &.level-3 {
      left: 12px;
      right: initial;
      width: 30%;
    }
    &.level-4 {
      left: 12px;
      right: initial;
      width: 40%;
    }
    &.level-5 {
      left: 12px;
      right: initial;
      width: 50%;
    }
    &.level-6 {
      left: 12px;
      right: initial;
      width: 60%;
    }
    &.level-7 {
      left: 12px;
      right: initial;
      width: 70%;
    }
    &.level-8 {
      left: 12px;
      right: initial;
      width: 80%;
    }
    &.level-9 {
      left: 12px;
      right: initial;
      width: 90%;
    }
    &.level-10 {
      left: 12px;
      right: initial;
      width: 90%;
    }
  }
  .meter-background {
    height: 8px;
    width: calc(100% - 24px);
    border-radius: 20px;
    margin: auto;
    position: absolute;
    background-color: #798f98;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.dashboard-reviews {
  position: absolute;
  top: -23px;
  border-radius: 12px;
  border: 2px solid #ddd;
  left: 0;
  z-index: 9;
  right: 0;
  margin: auto;
  max-width: 100%;
  background-color: #fff;
  padding: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #ddd;
  p {
    margin: 0;
  }
  .star-rating {
    float: left;
    width: 50%;
    text-align: center;
    @media(max-width: 991px) {
      width: 30px;
    }
    p {
      float: right;
      font-size: 14px;
    }
    svg {
      width: 14px;
      height: 17px;
      float: right;
      margin-top: 0.062em;
      color: #ffb224;
    }
  }
  .ratings-holder {
    width: 33px;
    margin: auto;
    display: block;
    position: relative;
    .user-rating-avg {
      float: left;
      width: 100%;
      padding-right: 15px;
    }
    .star-rating {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .review-count {
    float: left;
    width: calc(50% - 5px);
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid #ddd;
    @media(max-width: 991px) {
      width: calc(100% - 35px);
    }
  }
}
.drop-photo-title {
  text-align: center;
  min-width: 396px;
}
.user-button-icon,
button.user-button-icon {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  display: block;
  margin: auto;
  position: relative;
  text-align: center;
  overflow: hidden;
  border: 3px dotted #ddd;
  box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.1);
  &:hover {
    border: 3px solid #33a0ff;
  }
  &.uploading-drop {
    border: 3px solid #33a0ff;
  }
}
.user-button-icon.uploading-started {
  border: 3px solid #ddf5e9;
}
.user-icon.missing-avatar.uploading-started,
.user-icon,
.user-icon.missing-avatar {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  img {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .drop-upload-icon {
    margin: auto;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0;
    right: 0;
    color: #33a0ff;
  }
}
.user-icon.has-avatar {
  background-size: cover;
  background-position: center;
}
.user-icon.missing-avatar.uploading-started {
  .photo-upload-started svg {
    margin: auto;
    margin: auto;
    height: 100px;
    width: 100px;
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
  }
}
.upload-photo-info {
  float: left;
  width: 100%;
  padding: 20px;
  text-align: center;
}
button.list-your-space-btn {
  background-color: #fff;
  color: #1ea6f1;
  border: 2px solid #1ea6f1;
  padding: 10px 20px;
  text-transform: none;
  display: block;
  margin: auto;
  &:hover {
    border-color: #1e5ff1;
    background-color: #1e5ff1;
    color: #fff;
  } 
}
.list-your-space-dashblock {
  float: left;
  width: 100%;
  padding: 20px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 15px;
  margin-top: 10px; 
  @media(max-width: 667px) {
    display: none;
  }
  p {
    margin: 20px 0;
  }
}
.list-your-space-title {
  span {
    font-size: 10px;
    color: #949494;
    position: relative;
    top: -5px;
    left: 2px;
  }
}
span.subcap {
  color: #949494;
}
.host-your-space-image {
  max-width: 150px;
  width: 100%;
  margin: auto;
}
.user-verification-blocks {
  float: left;
  width: 100%;
  margin-left: -5px;  
}
.verified-block-complete {
  display: none;
}
.verify-dash-block {
  float: left;
  width: calc(25% - 10px);
  margin: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  @media(max-width: 1100px) {
    width: calc(33.33333333% - 10px);
  }
  @media(max-width: 667px) {
    width: calc(50% - 10px);
  }
}
.verify-title-block {
  background-size: cover;
  width: 102px;
  height: 107px;
  margin: auto;
  display: block;
  &.email {
    background-image: url('../images/verify-mail.png');
  }
  &.passport {
    background-image: url('../images/verify-id-bg.png');
  }
  &.about {
    background-image: url('../images/verify-description-bg.png');
  }
  &.phone {
    background-image: url('../images/verify-phone-bg.png');
  }
}
button.verify-dash-btn {
  text-transform: none;
  margin: auto;
  border: 2px solid #1e5ff1;
  background-color: #1e5ff1;
  color: #fff;
  margin-top: 15px;
  width: 100%;
  padding: 5px;
  &:hover {
    background-color: #1ea6f1;
    border-color: #1ea6f1;
  }
}
button.about-info-btn {
  pointer-events: none;
  text-transform: none;
  margin: auto;
  border: 2px solid white;
  background-color: white;
  color: black;
  margin-top: 15px;
  width: 100%;
  padding: 5px;
}
.about-verification-roadmap {
  float: left;
  width: 100%;
  margin-left: 5px;
  margin-bottom: 10px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  h4 {
    font-size: 18px;
    font-weight: bolder;
  }
}
.user-has-garages-infobox {
  float: left;
  width: 100%;
  margin-left: 5px;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  h4 {
    font-size: 18px;
    font-weight: bolder;
  }
}
.host-quick-link  {
  float: left;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
  &.no-spaces {
    background-color: #ffffff;
    border: 2px solid #c5fcff;
    background-image: url('../images/motorroof-garage.svg');
    background-repeat: no-repeat;
    background-size: 125px;
    background-position: 27px 37%;
    padding-left: 175px;
    p {
      margin-bottom: 6px;
    }
  }
  &.has-draft {
    background-color: #ffffff;
    border: 2px solid #e3c5ff;
    background-image: url('../images/motorroof-garage-missing-image.jpg');
    background-repeat: no-repeat;
    background-size: 125px;
    background-position: 27px center;
    padding-left: 175px;
    p {
      margin-bottom: 6px;
    }
  }
  button {
    text-transform: initial;
  }
  .add-space-btn {
    background-color: #1e5ef1;
    color: #fff;
    margin-right: 10px;
    padding: 6px 13px;
    &:hover {
      background-color: #1ea6f1;
    }
  }
  .edit-draft-btn {
    background-color: #a34bf3;
    color: #fff;
    margin-right: 10px;
    &:hover {
      background-color: #7b50ff;
    }
  }
}
.verify-email-block > div > div:first-child {
  max-width: 300px;
  padding: 210px 20px 20px 20px;
  position: relative;
  h4 {
    font-size: 19px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
img.verify-email-hero {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
img.verify-email-hero-complete {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.verify-email-block div.verify-complete.email-verify {
  text-align: center;
  h4 {
    margin-top: -40px;
  }
  p {
    margin-bottom: 10px;
  }
}


