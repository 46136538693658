@media print {
  .nav-search-container-form ,
  .mobile-menu-drop {
    display: none;
  }
  .primary-navigation-wrapper .app-bar-nav {
    box-shadow: none;
  }
  .col-left,
  .col-right {
    display: none!important;
  }
  .host-links {
    display: none;
  }
  .no-direct-deposits-but-can-issue-checks {
    display: none;
  }
  .no-seller-account-found {
    display: none;
  }
  .footer-wrap {
    display: none;
  }
  .print-hide-toolbar {
    display: none!important;
  }
  button {
    display: none;
    span {
      display: none!important;
    }
  }
  .table-wrapper-account-hosting {
    display: none;
  }
  .arialabel-noprint {
    display: none;
  }
  .print-table-wrap {
    display: block;
  }
}