.dashboard-notifications.billing-info {
  float: left;
  max-width: 600px;
  width: 100%;
}
.dashboard-container.billing-dashboard {
  .col-right {
    position: relative;
  }
}
.protected-by-row {
  color: #b3b3b3;
  float: right;
  width: 133px;
  clear: both;
  margin-top: 10px;
  span {
    font-weight: bolder;
    padding-bottom: 5px;
    float: left;
  }
  svg.stripe-secure {
    width: 55px;
    float: left;
    height: 27px;
    margin-top: -4px;
    margin-left: -2px;
    path {
      fill: #8c8c8c;
    }
    .st0 {
      fill : #8c8c8c;
    }
  }
}
.save-payment-information {
  float: left;
  width: 100%;
  max-width: 600px;
  button.about-info-save-btn {
    margin: 10px 0 0 0;
    float: right;
    clear: both;
  }
}
svg.lock-icon-billing {
  width: 15px;
  height: 15px;
  float: left;
}
.loading-stripe-card-from {
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  max-width: 600px;
  margin-top: 10px;
  span {
    color: #b7b7b7;
    display: inline-block;
    position: relative;
    top: -15px;
    left: 15px;
  }
  .stripe-form-loader svg {
    color: #4bbfff;
  }
}
.stripe-add-card-form {
  float: left;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.user-cards-data {
  float: left;
  width: 100%;
  max-width: 600px;
}
.default-card-source {
  float: left;
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
  background-color: #e6fff8;
  border-radius: 10px;
  padding: 15px;
  position: relative;
}
h3.payment-info-title {
  font-size: 13px;
  font-weight: bolder;
  float: left;
  width: 100%;
  border-bottom: 2px solid #92e2dc;
  padding-bottom: 5px;
  color: #7ecec8;
}
.saved-card-info {
  float: left;
  width: 100%;
  margin-top: 10px;
  position: relative;
  .card-four {
    font-family: 'Inconsolata', monospace;
    font-size: 24px;
    margin: 9px 15px 0 15px;
    color: #1b6d68;
    float: left;
    user-select: none;
    span {
      font-size: 12px;
      position: absolute;
      top: -2px;
    }
  }
}
.status-info-billing {
  background-color: #cccccc;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 15px;
  font-weight: bold;
  color: #fff;
  &.Succeeded {
    background-color: #7dcc69;
  }
}
td.billing-history-logo {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../images/credit-card.svg');
  padding-left: 45px;
  background-position: left;
  font-family: 'Inconsolata', monospace;
  &.Visa {
    background-image: url('../images/visa.svg');
    background-size: 36px;
  }
  &.MasterCard {
    background-image: url('../images/mastercard.svg');
    background-size: 25px;
  }
  &.American.Express {
    background-image: url('../images/amex.svg');
    background-size: 15px;
  }
  &.Discover {
    background-image: url('../images/discover.svg');
    background-size: 44px;
  }
  &.Diners.Club {
    background-image: url('../images/dinersclub.svg');
    background-size: 44px;
  }
  &.JCB {
    background-image: url('../images/jcb.svg');
    background-size: 16px;
  }
  &.UnionPay {
    background-image: url('../images/unionpay.svg');
    background-size: 16px;
  }
}
.card-brand-logo {
  height: 35px;
  width: 54px;
  background-color: #fff;
  border-radius: 5px;
  float: left;
  box-shadow: aquamarine 1px 2px 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../images/credit-card.svg');
  background-size: 58px;
  &.Visa {
    background-image: url('../images/visa.svg');
    background-size: 44px;
  }
  &.MasterCard {
    background-image: url('../images/mastercard.svg');
    background-size: 30px;
  }
  &.American.Express {
    background-image: url('../images/amex.svg');
    background-size: 25px;
  }
  &.Discover {
    background-image: url('../images/discover.svg');
    background-size: 54px;
  }
  &.Diners.Club {
    background-image: url('../images/dinersclub.svg');
    background-size: 54px;
  }
  &.JCB {
    background-image: url('../images/jcb.svg');
    background-size: 26px;
  }
  &.UnionPay {
    background-image: url('../images/unionpay.svg');
    background-size: 26px;
  }
}
.saved-card-actions {
  float: right;
}
.payment-form-wrap {
  float: left;
  width: 100%;
  max-width: 600px;
  margin-top: 10px;
  .new-payment-title {
    font-weight: bold;
    font-size: 14px;
    padding-left: 13px;
    margin-bottom: 3px;
    color: #556379;
  }
}
button.update-card-btn {
  color: #2d63ff;
  &.with-cancel {
    color: #525252;
  }
}
button.delete-card-btn {
  color: #ff00a3;
}
.deleting-card-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  border-radius: 10px;
  user-select: none;
  .loading-stripe-card-from {
    background-color: transparent;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0;
    .loader-container-delete {
      width: 200px;
      height: 43px;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      span {
        color: #fff;
      }
    }
  }
}
.billing-history-container {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.billing-table-wrap {
  float: left;
  width: 100%;
  margin-top: -10px;
}
.loader-container-history {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.78);
  z-index: 9;
  .history-loader {
    width: 205px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    span {
      position: relative;
      top: -12px;
      left: 15px;
    }
  }
}
.no-payment-history {
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #f7f7f7;
  min-width: 100%;
  display: block;
  position: absolute;
  text-align: center;
}
.manage-host-payment-container {
  position: absolute;
  right: 20px;
  top: 20px;
  height: 200px;
  width: calc(100% - 660px);
  border: 1px solid #ddd;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: 900px;
  background-position: center 15px;
  background-image: url('../images/banner-bottom.svg');
  @media(max-width: 1100px) {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 22px;
    right: 0;
    top: 0;
    max-width: 600px;
  }
  @media(max-width: 991px) {
    max-width: 100%;
  }
  h5 {
    font-size: 15px;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
  }
  .garage-payments-icon {
    background-image: url('../images/motorroof-garage.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 100px;
    width: 120px;
    margin: auto;
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    margin: auto;
  }
  button {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 0;
    width: calc(100% - 20px);
    background-color: #1e5ff1;
    color: #fff;
    text-transform: none;
    font-weight: bold;
    max-width: 200px;
    margin: auto;
    &:hover {
      background-color: #1ea6f1;
      color: #fff;
    }
  }
}
.sort-label-header-billing {
  svg {
    width: 15px;
  }
}