.search-page-wrap {
  position: fixed;
  width: 100%;
  height: calc(100% - 65px);
  top: 65px;
  background-color: #fff;
}
.search-garage-map {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30%;
  height: 100%;
  background-color: #ddd;
  z-index: 9;
  @media(max-width: 1250px) {
    width: 330px;
  }
  @media(max-width: 991px) {
    width: 100%;
    height: 100%;
    display: none;
  }
  > div:first-child {
    width: 100%!important;
    height: 100%!important;
  }
}
.search-content-col-overflow-wrap {
  position: fixed;
  z-index: 99999;
  overflow: hidden;
  height: calc(100% - 67px);
  width: 70%;
  top: 67px;
  bottom: 0;
  left: 0;
  @media(max-width: 1250px) {
    width: calc(100% - 330px);
    .garage-thumb-image {
      height: 150px;
    }
    .garage-grid-item {
      height: 290px;
    }
  }
  @media(max-width: 1100px) {
    .garage-thumb-image {
      height: 200px;
    }
    .grage-grid-item {
      height: 340px;
    }
  }
  @media(max-width: 991px) {
    width: 100%;
    .garage-thumb-image {
      height: auto;
      padding-bottom: 50%;
    }
  }
}
.search-content-col {
  float: left;
  position: absolute;
  z-index: 99999;
  overflow: scroll;
  height: 100%;
  width: calc(100% + 30px);
  top: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 50px;
  padding-bottom: 20px;
  padding-right: 30px;
}
.filters-wrap {
  display: none;
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  position: relative;
}
.search-results-wrap {
  float: left;
  width: 100%;
}
.search-list-container {
  float: left;
  width: 100%;
  padding: 3px;
  .grid-list-block {
    width: 33.3333333333%;
    float: left;
    padding: 5px;
    @media(max-width: 1100px) {
      width: 50%;
    }
    @media(max-width: 991px) {
      width: 33.3333333333%;
    }
    @media(max-width: 850px) {
      width: 50%;
    }
    @media(max-width: 667px) {
      width: 100%;
    }
    .garage-grid-item {
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
      transition: all 0.3s ease-in-out;
      @media(max-width: 1100px) {
        height: 345px;
      }
      @media(max-width: 991px) {
        height: auto;
      }
      &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
      }
    }
  }
}
.searching-for-location-title {
  float: left;
  width: 100%;
  padding: 10px;
  .searching-properties-spinner {
    float: left;
    margin-right: 10px;
    margin-top: 1px;
    svg {
      color: #1ea6f1;
    }
  }
  h2 {
    font-size: 13px;
    font-weight: normal;
    color: #5a5a5a;
    padding-top: 5px;
  }
}
.garage-map-pin {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #ddd;
  transition: all 0.2s ease-in-out;
  float: left;
  position: relative;
  &.opened {
    border-color: #1ea6f1;
  }
  p {
    font-weight: normal;
    float: left;
  }
  &:hover {
    border-color: #1ea6f1;
  }
}
.storage-and-workshop-pin {
  float: left;
  position: relative;
  margin-right: -5px;
  .storage-pin {
    left: -8px;
    top: 4px;
    border: 1px solid #fff;
    height: 12px;
    width: 12px;
  }
}
.workshop-pin {
  height: 10px;
  width: 10px;
  border-radius: 20px;
  background-color: #20d0c9;
  float: left;
  position: relative;
  top: 5px;
  left: -3px;
}
.storage-pin {
  height: 10px;
  width: 10px;
  border-radius: 20px;
  background-color: #7b49ef;
  float: left;
  position: relative;
  top: 5px;
  left: -3px;
}
.garage-map-pin-wrap {
  position: relative;
  float: left;
}

// Single type of garage, like just workshop or just storage
.tag-width-1 .pin-info-container {
  margin-left: -250%;
}
.tag-width-2 .pin-info-container {
  margin-left: -203%;
}
.tag-width-3 .pin-info-container {
  margin-left: -166%;
}
.tag-width-4 .pin-info-container {
  margin-left: -133%;
}
.tag-width-5 .pin-info-container {
  margin-left: -112%;
}
.tag-width-6 .pin-info-container {
  margin-left: -96%;
}
.tag-width-7 .pin-info-container {
  margin-left: -80%;
}
// tag has two types, like workshop AND storage
.tag-width-7.type-width-2 .pin-info-container {
  margin-left: -72%;
}
.tag-width-6.type-width-2 .pin-info-container {
  margin-left: -87%;
}
.tag-width-5.type-width-2 .pin-info-container {
  margin-left: -100%;
}
.tag-width-4.type-width-2 .pin-info-container {
  margin-left: -116%;
}
.tag-width-3.type-width-2 .pin-info-container {
  margin-left: -144%;
}
.tag-width-2.type-width-2 .pin-info-container {
  margin-left: -174%;
}
.tag-width-1.type-width-2 .pin-info-container {
  margin-left: -210%;
}
.pin-info-container {
  width: 250px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  position: absolute;
  bottom: 3px;
  left: 0;
  right: 0;
  margin: auto;
  height: 339px;
  transform: scale(0.8);
  display: none;
  z-index: 999;
  border-radius: 20px;
  &.opened {
    display: block;
  }
  .garage-grid-item {
    overflow: hidden;
    border-radius: 20px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: #ffffff transparent transparent transparent;
    z-index: 9999;
  }
  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
  }
}
.toggle-info-pin {
  float: left;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: -2px;
}
.suggest-places-box {
  padding: 10px;
  background-color: #e7f6f9;
  border-radius: 10px;
}
.get-notified-box {
  margin-top: 5px;
  padding: 10px;
  background-color: #4c5eb3;
  color: white;
  border-radius: 10px;
  position: relative;
  float: left;
  width: 100%;
  a {
    float: left;
    background-color: #a5b4ff;
    padding: 10px 5px 10px 40px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 122px;
    color: #4d0ace;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #1c7ffd;
      color: #fff;
    }
  }
  span {
    float: left;
    width: 100%;
    padding-left: 122px;
  }
  svg {
    position: absolute;
    left: 10px;
    top: 10px;
    margin: auto;
  }
}
.search-on-map-scroll-container {
  background-color: #fff;
  width: 230px;
  margin: 10px auto auto auto;
  border-radius: 5px;
  border: 2px solid #ddd;
  &.map-is-searching {
    padding: 10px;
  }
  input {
    color: blue;
  }
  label {
    margin-right: 0;
    width: 100%;
    margin: auto;
  }
  span {
    font-size: 14px;
  }
}
.loader-wrap-map {
  max-width: 50px;
  display: block;
  margin: auto;
  svg {
    width: 50px;
    height: 20px;
    margin: auto;
    display: block;
  }
}
.marker-wrap {
  position: relative;
  z-index: 0;
  &:hover {
    z-index: 3;
  }
  &.is-open-true {
    z-index: 5;
  }
}
.noplacesfound-hero-wrap {
  float: left;
  width: calc(100% - 40px);
  border-radius: 10px;
  background-color: #ecfbff;
  margin: 20px;
  padding: 100px 20px 20px 20px;
  background-position: center 6px;
  background-repeat: no-repeat;
  background-size: 685px;
  background-image: url('../images/banner-bottom.svg');
  p {
    text-align: center;
    color: #4bb2ca;
  }
}