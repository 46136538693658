.signup-page-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  background-image: url('../images/motorroof-banner-bg.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  @media(max-width: 991px) {
    background-size: 1300px;
    background-position: center top;
  }
  @media(max-width: 767px) {
    position: relative;
  }
}

div.signup-form-container {
  float: left;
  width: 100%;
  @media(max-width: 767px) {
    background-color: transparent;
    box-shadow: none;
  }
  .left-col {
    float: left;
    width: 350px;
    position: relative;
    @media(max-width: 767px) {
      width: 100%;
    }
  }
  .right-col {
    float: right;
    width: calc(100% - 350px);
    padding: 40px;
    @media(max-width: 767px) {
      width: 100%;
      max-width: 400px;
      float: none;
      display: block;
      margin: auto;
      padding-top: 225px;
    }
    h2 {
      margin: 0;
    }
    .signup-form {
      .form-control-row {
        margin: 0;
        > div:nth-child(1) {
          margin-top: 0;
        }
         > div:nth-child(2) {
          margin-top: 0;
        }
        &.name-row {
          margin-top: 10px;
        }
      }
    }
  }
  button {
    background-color: #1ea6f1;
    color: #fff;
    text-transform: initial;
    margin-top: 30px;
    &:hover {
      background-color: #1e5ff1;
    }
  }
}
.signup-container-wrap {
  display: block;
  max-width: 750px;
  height: 570px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  @media(max-width: 767px) {
    position: initial;
  }
  .signup-cta-links {
    padding: 10px;
    span {
      display: inline-block;
      float: left;
      font-family: 'Roboto';
      font-size: 15px;
      color: #5a5a5a;
      width: initial;
    }
    a {
      width: initial;
      margin: 0 0 0 5px;
      font-weight: bolder;
      color: #1ea6f1;
      font-size: 15px;
      transition: 0.2s ease-in-out;
      &:hover {
        color: #1e5ff1; 
      }
    }
  }
}
.motorroof-register {
  background-image: url('../images/motorroof-garage.svg');
  height: 200px;
  width: 300px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 85px;
  left: 40px;
  margin: auto;
  @media(max-width: 767px) {
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 99;
    left: 0;
    top: 84px;
    background-size: 241px;
    height: 152px;
    margin-top: -15px;
  }
}
.signup-motorroof {
  background-image: url('../images/review-first.png');
  height: 300px;
  width: 200px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 70px;
  left: 100px;
  margin: auto;
  background-size: 100%;
  @media(max-width: 767px) {
    display: none;
  }
}
.motorroof-register-background {
  width: 300px;
  height: 100%;
  position: absolute;
  @media(max-width: 767px) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
.motorroof-signup-logo {
  width: 320px;
  height: 200px;
  position: absolute;
  top: 20px;
  left: 30px;
  @media(max-width: 767px) {
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: auto;
    max-width: 279px;
  }
}
.signup-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ddd;
  padding: 5px 20px;
  background-color: #fff;
  @media(max-width: 767px) {
    position: initial;
    margin-top: 150px;
    text-align: center;
    border-top: 0;
  }
  p {
    float: left;
    font-size: 12px;
    letter-spacing: 0.02em;
    margin-left: 70px;
    @media(max-width: 767px) {
      width: 100%;
      text-align: center;
      margin-left: auto;
    }
  }
  .footer-link-holder {
    float: right;
    margin: 10px 0;
    margin-right: 80px;
    @media(max-width: 767px) {
      width: 100%;
      text-align: center;
      margin: auto;
      margin-bottom: 100px;
    }
    a {
      font-size: 14px;
      color: #5a5a5a;
      &:hover {
        color: #1e5ff1; 
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.terms-policy-link {
  padding: 0 5px;
  font-size: 17px;
  color: #1ea6f1;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #1e5ff1;
  }
}
div.fix-padding-terms {
  padding-top: 0;
}
.checkbox-terms {
  font-family: 'Roboto';
  font-size: 14px;
  vertical-align: middle;
  a {
    font-size: 14px;
  }
}