.host-navigation {
  float: left;
  width: 100%;
  padding: 20px;
  a {
    width: 100%;
    float: left;
  }
}
.host-container {
  display: block;
  margin: auto;
  max-width: 1200px;
  width: 100%;
  margin-top: 20px;
  @media(max-width: 991px) {
    margin-top: 0;
  }
  .col-right {
    float: left;
    width: 250px;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    @media(max-width: 767px) {
      width: 100%;
      float: none;
      display: block;
      max-width: 400px;
      margin: auto;
      text-align: center;
    }
  }
  .col-left {
    float: left;
    width: calc(100% - 250px);
    padding: 20px;
    @media(max-width: 767px) {
      width: 100%;
    }
    span.subtitle {
      color: #717171;
      margin: 1px;
      margin-bottom: 15px;
      float: left;
    }
    .no-seller-account-found {
      width: calc(100% - 5px);
    }
  }
}
span.host-nav-title {
  float: left;
  width: 100%;
  margin-left: 8px;
  color: #717171;
  @media(max-width: 767px) {
    margin-left: 0;
  }
}
.host-links {
  button {
    color: #394148;
    transition: all 0.2s ease-in-out;
    width: 100%;
    text-align: left;
    margin-top: 2px;
    &.active {
      color: #33a0ff;
      border: 2px solid #33a0ff;
    }
    p {
      flex: auto;
    }
    span {
      text-transform: none;
      width: 100%;
      text-align: left;
      @media(max-width: 767px) {
        text-align: center;
      }
    }
    &:hover {
      color: #33a0ff;
      background-color: #f7f7f7;
    }
  }
}
button.dash-btn {
  color: #394148;
  transition: all 0.2s ease-in-out;
  width: 100%;
  span {
    text-transform: none;
    width: 100%;
    text-align: left;
  }
  &.delete {
    color: #ff005a;
    &:hover {
      background-color: #ffdae7;
      color: #ff005a;
    }
  }
  &:hover {
    color: #33a0ff;
    background-color: #f7f7f7;
  }
}
.garage-active-list-container {
  float: left;
  width: 100%;
  margin-left: -5px;
  .grid-list-block {
    width: 33.3333333333%;
    float: left;
    padding: 5px;
    @media(max-width: 1100px) {
      width: 50%;
    }
    @media(max-width: 550px) {
      width: 100%;
    }
    .garage-grid-item {
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
      transition: all 0.2s ease-in-out;
      &:hover {
        cursor: pointer;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
      }
    }
  }
}
.garage-active-title {
  float: left;
  width: calc(100% - 5px);
  margin-left: 5px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin-bottom: 15px;
  margin-top: 20px;
  h5 {
    float: left;
    margin-top: 13px;
  }
  a {
    float: right;
  }
}
.properties-list-hosthub {
  float: left;
  width: calc(100% + 5px);
  .garage-actions {
    float: left;
    width: 100%;
    background-color: #ddd;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    overflow: hidden;
    button {
      float: right;
      border-radius: 0;
      text-transform: none;
    }
    &.active {
      background-color: #8ecfff;
      button {
        background-color: #1d67f1;
        &:hover {
          background-color: #5d91f3;
        }
        span {
          color: #fff;
        }
      }
      p {
        color: #fff;
      }
    }
    &.draft {
      background-color: #d8baff;
      p {
        color: #652eb7;
      }
    }
  }
  .garage-host-active {
    float: left;
    text-transform: uppercase;
    padding: 8px 0 8px 19px;
  }
  .garage-grid-item {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
.work-in-progress-garage {
  position: relative;
  float: left;
  width: 100%;
  .grid-list-block  {
    width: 100%;
  }
  .garage-grid-item {
    opacity: 1;
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  .work-in-progress-garage-item {
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    transition: all 1s ease-in-out;
    box-shadow: 0 6px 6px 0 rgba(24, 52, 117, 0.09);
  }
}
.draft-tag-item {
  color: #652eb7;
  background-color: #d8baff;
  padding: 5px 10px;
  border-radius: 19px;
  margin: 0 0 10px 0;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 0;
  margin: auto;
  width: 52px;
  z-index: 91;
}
.draft-tag {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  z-index: 91;
  margin: auto;
  border-radius: 6px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  z-index: 91;
  padding: 20px;
  -webkit-box-shadow: -1px -9px 39px -23px rgba(171, 171, 171, 0.75);
  -moz-box-shadow:-1px -9px 39px -23px rgba(171, 171, 171, 0.75);
  box-shadow: -1px -9px 39px -23px rgba(171, 171, 171, 0.75);
  .button-wrap {
    float: left;
    width: 100%;
  }
  button.dash-btn {
    float: left;
    width: 50%;
  }
}
.host-garage-loader {
  float: left;
  svg {
    color: #33a0ff;
  }
}
button.new-garage-btn {
  background-color: #1ea6f1;
  color: #fff;
  text-transform: initial;
  position: relative;
  float: right;
  padding: 10px 20px 10px 40px;
  &.is-loading {
    background-color: #ddd;
    &:hover {
      background-color: #ddd;
    }
  }
  &:hover {
    background-color: #1e5ff1;
  }
  .edit-icon svg {
    position: absolute;
    left: 10px;
  }
  .add-icon svg {
    position: absolute;
    left: 10px;
  }
}
.no-garages-yet {
  float: left;
  width: 100%;
  background-color: #eef7ff;
  padding: 20px;
  border-radius: 10px;
}
.hosting-hub-access-hours {
  float: left;
  .scheduler-screen-container {
    padding-left: 0;
    padding-top: 20px;
  }
  .check-box-left {
    float: left;
    margin-bottom: 30px;
  }
}
.hover-edit-garage-container {
  position: relative;
  float: left;
  width: 100%;
  &:hover {
    .edit-garage-hover-btn {
      opacity: 1;
    }
  }
}
.edit-garage-hover-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(109, 118, 132, 0.5);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    p.edit-listing-btn-text {
      border-color: #1ea6f1;
      color: #1ea6f1;
      box-shadow: 0 6px 28px 0 rgba(24, 52, 117, 0.2);
    }
  }
}
p.edit-listing-btn-text {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  display: block;
  text-align: center;
  width: 150px;
  height: 50px;
  padding: 10px;
  border-radius: 30px;
  font-weight: bolder;
  user-select: none;
  border: 3px solid #fff;
  transition: all 0.2s ease-in-out;
}
.loading-host-nofitications {
  float: left;
  width: 100%;
  background-color: #ddd;
  border-radius: 10px;
  padding: 10px;
  svg {
    fill: #ddd;
    color: #ddd;
  }
}
.host-payout-block {
  float: left;
  width: 100%;
  background-color: #f6ffc7;
  padding: 10px;
}
h5.account-table-header {
  margin-bottom: -15px;
  margin-top: 20px;
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}
.table-wrapper-account-hosting {
  float: left;
  width: 100%;
  padding-right: 5px;
  margin-top: 20px;
  tr {
    td, th {
      padding: 4px;
      font-size: 13px;
    }
  }
}

tr.MenuItem-payout.selected-payout {
  background-color: #ebfcff;
  &:hover {
    background-color: #ebfcff;
  }
}
.payment-status-label {
  background-color: #f3f3f3;
  color: #4e4e4e;
  border-radius: 20px;
  text-align: center;
  padding: 5px;
  font-size: 11px;
  &.stripe_managed {
    background-color: #6f65f3;
    color: #fff;
  }
  &.requested {
    background-color: #24b8ff;
    color: #ffffff;
  }
}
button.request-payout-btn {
  text-transform: initial;
  margin-left: 10px;
  white-space: nowrap;
  padding: 10px 42px;
  background-color: #0089ff;
  color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  &.disabled-request-payout {
    background-color: #c7c7c7;
    color: #676767;
    box-shadow: none;
    &:hover {
      background-color: #c7c7c7;
      color: #676767;
      box-shadow: none;
    }
  }
  &:hover {
    background-color: #0066ff;
  }
}
.paymentstatus {
  &.awaiting_payment {
    border: 2px solid #ff93d4;
    border-radius: 20px;
    color: #ff93d4;
    padding: 4px;
    font-size: 11px;
    text-align: center;
  }
  &.paid {
    border: 2px solid #30ef7c;
    border-radius: 20px;
    color: #30ef7c;
    padding: 4px;
    font-size: 11px;
    text-align: center;
  }
}
.manage-your-stripe-account {
  float: left;
  width: 100%;
  padding: 15px 15px 15px 150px;
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 10px;
  background-image: url('../images/stripe-connected-slide-1.jpg');
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: 0px -10px;
  max-width: 640px;
  clear: both;
  &.sidebar-size {
    margin-top: 15px;
    padding: 116px 20px 20px 20px;
    background-position: center 0px;
    p.manage-info {
      font-size: 14px;
      text-align: center;
    }
    button {
      text-transform: none;
    }
  }
  p.manage-info {
    padding: 0 0 10px 0;
  }
  button {
    background-color: #6f65f3;
    color: #fff;
    padding: 10px 20px;
    text-transform: none;
    &:hover {
      background-color: #5145f9;
    }
  }
}
.no-direct-deposits-but-can-issue-checks {
  float: left;
  width: 100%;
  padding: 15px;
  background-color: #f3ffde;
  border-radius: 10px;
  &.sidebar-size {
    margin-top: 15px;
  }
  p.check-issue-title {
    font-weight: bold;
    color: #536732;
    padding: 10px 10px 0 10px;
  }
  p.check-issue-content {
    font-size: 13px;
    color: #344021;
    padding: 10px;
  }
  a {
    font-weight: bold;
    color: #11a066;
    &:hover {
      color: #16d085;
    }
  }
}
.request-check-form {
  z-index: 9999!important;
  .request-check-title {
    padding-bottom: 5px;
  }
  form {
    float: left;
    width: 100%;
    margin-top: -20px;
    .issuename-input {
      margin-bottom: 10px;
    }
  }
  .garage-location-editor {
    padding: 0;
    border: 0;
  }
  .next-update-btn {
    background-color: #1ea6f1;
    color: #fff;
    text-transform: none;
    &.requesting-check {
      background-color: #fff;
      color: #0bc794;
    }
    &.cancel {
      background-color: #fff;
      color: #20a6f1;
      box-shadow: none;
      &.requesting {
        color: #ddd;
      }
      &:hover {
        background-color: #f7f7f7;
      }
    }
    &:hover {
      background-color: #1e5ff1;
    }
  }
}
.check-total-and-fees {
  display: block;
  margin: auto;
}
.requested-check-at {
  float: left;
  width: 100%;
  text-align: center;
}
.center-action-btns {
  max-width: 322px;
  margin: auto;
  &.with-print {
    max-width: 330px;
    margin-bottom: 20px;
    @media print {
      display: none;
    }
  }
}
.print-table-wrap {
  display: none;
  tr, th {
    font-size: 10px;
    white-space: nowrap;
  }
  tbody tr > th {
    padding-left: 15px;
  }
  tr, td {
    font-size: 10px;
  }
  .payment-status-label {
    background-color: #fff;
    color: #000;
    text-align: left;
  }
  .table-row-th {
    padding-left: 10px;
  }
  .paymentstatus {
    font-size: 10px;
    padding: 2px;
    border: 0;
    text-align: left;
  }
}
span.confirm-check-info {
  text-align: center;
  color: #828282;
  float: left;
  width: 100%;
}
.inline-label-with-link {
  a, p {
    display: inline-block;
    font-size: 13px;
  }
  p.garage-host-guidelines {
    top: 0;
    margin-left: 3px;
    font-size: 13px;
  }
}
.confirm-check-send-actions {
  max-width: 356px;
  border-top: 1px solid #ddd;
  margin: 10px auto;
  label {
    margin-right: 0;
  }
  label span {
    font-size: 13px;
  }
}
button.download-print-btn {
  text-transform: none;
  background-color: #fff;
  color: #1c8aff;
  box-shadow: inset 0px 0px 0px 2px;
  &:hover {
    background-color: #1c8aff;
    color: #fff; 
    box-shadow: none;
  }
}
.support-id-info {
  float: left;
  width: 100%;
  background-color: #eafdff;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  margin: 20px 0;
  span {
    color: #63a7af;
  }
  h2 {
    font-weight: bolder;
    font-size: 33px;
    text-transform: uppercase;
  }
}