.reservations-list-container {
  float: left;
  width: 100%;
  clear: both;
  &.hosting-reservation-list {
    @media(max-width: 991px) {
      .reservation-content {
        width: 100%;
        margin: 0;
        .col-left-content {
          margin: 0;
        }
      }
      .reservation-photo {
        width: 100%;
        position: initial;
        float: left;
        height: 100px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
    @media(max-width: 860px) {
      .reservation-content {
        .col-left-content {
          width: 100%;
        }
        .col-right-content {
          width: 100%;
          margin-top: 10px;
          border-top: 1px solid #ddd;
          .host-content {
            padding: 0;
            border: 0;
            margin-top: 5px;
            .about-host-user-icon {
              float: left;
              width: 35px;
              height: 35px;
            }
            p {
              float: left;
              width: calc(100% - 35px);
              clear: initial;
              text-align: left;
              padding-left: 10px;
              padding-top: 6px;
            }
          }
        }
      }
    }
    @media(max-width: 667px) {
      .reservation-content .col-right-content {
        background-color: initial;
        position: initial;
        height: initial;
        top: initial;
        bottom: initial;
        margin: initial;
        margin-top: 10px;
        strong {
          display: block;
        }
        .host-content {
          position: initial;
          height: initial;
          top: initial;
          bottom: initial;
          margin: initial;
          p {
            color: initial;
            padding-top: 8px;
          }
        }
      }
    }
  }
  .no-garages-yet {
    h4 {
      font-size: 19px;
    }
    p {
      font-size: 15px;
    }
  }
  .reservation-row {
    float: left;
    width: 100%;
    background-color: #fff;
    padding: 3px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px;
    border: 1px solid #f3f3f3;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.14);
    }
  }
  .reservation-photo {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 150px;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../images/missing-image.jpg');
    @media(max-width: 565px) {
      width: 100px;
    }
    @media(max-width: 500px) {
      width: 100%;
      position: initial;
      float: left;
      height: 100px;
    }
  }
  .reservation-content {
    float: left;
    width: calc(100% - 150px);
    margin-left: 150px;
    padding: 10px;
    color: #3a4148;
    @media(max-width: 565px) {
      width: calc(100% - 100px);
      margin-left: 100px;
    }
    @media(max-width: 500px) {
      width: 100%;
      margin-left: 0;
    }
    .col-left-content {
      float: left;
      width: calc(100% - 150px);
      @media(max-width: 667px) {
        width: 100%;
      }
    }
    .col-right-content {
      float: left;
      width: 150px;
      @media(max-width: 667px) {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
        strong {
          display: none;
        }
        .about-host-user-icon {
          border: 2px solid #fff;
        }
        .host-content {
          border: none;
          position: absolute;
          height: 100px;
          top: 0;
          bottom: 0;
          margin: auto;
          padding: 0 10px;
          p {
            color: #fff;
            font-size: 12px;
          }
        }
      }
      @media(max-width: 565px) {
        width: 100px;
      }
      @media(max-width: 500px) {
        width: 100%;
        height: 100px;
        .about-host-user-icon {
          width: 50px;
          height: 50px;
        }
        .host-content {
          height: 75px;
        }
      }
      strong {
        font-size: 13px;
      }
    }
    svg {
      color: #9eb7d2;
    }
  }
  .reservation-status {
    float: left;
    background-color: #ff097b;
    color: #fff;
    padding: 2px 8px;
    border-radius: 15px;
    border: 2px solid;
    p {
      font-size: 12px;
    }
    &.pending {
      border-color: #ff097b;
    }
    &.approved {
      background-color: #12d67b;
      border-color: #12d67b;
    }
    &.denied {
      background-color: #000;
      border-color: #000;
    }
    &.canceled {
      background-color: #797979;
      border-color: #797979;
    }
    &.completed {
      background-color: #fff;
      color: #98f3e8;
      border: 2px solid #98f3e8;
      text-align: center;
    }
  }
  .location {
    float: left;
    width: 100%;
    position: relative;
    padding-left: 25px;
    padding-right: 10px;
    margin: 10px 0;
    min-height: 24px;
    @media(max-width: 667px) {
      p {
        font-size: 13px;
        padding-top: 2px;
      }
    }
    svg {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .checkin-checkout-row {
    float: left;
    width: 100%;
    .start-date {
      float: left;
      width: 50%;
      max-width: 200px;
      span p {
        font-size: 12px;
      }
      @media(max-width: 500px) {
        p {
          font-size: 14px;
        }
      }
    }
    .end-date {
      float: left;
      width: 50%;
      max-width: 200px;
      span p {
        font-size: 12px;
      }
      @media(max-width: 500px) {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
.dashboard-container .col-right .subtitle.with-reservations {
  margin: 1px;
  float: left;
  margin-bottom: 25px;
}
.reservations-tab-panel {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  float: left;
  width: 100%;
  padding: 20px 20px 15px 20px;
  .type-label {
    border-radius: 15px;
    float: left;
    @media(max-width: 667px) {
      margin-bottom: 2px;
    }
    p {
      font-family: 'Roboto';
      font-size: 12px;
    }
    padding: 2px 8px;
    margin-right: 5px;
  }
  .Storage {
    border: 2px solid #7b49ef;
    color: #7b49ef;
  }
  .Workshop {
    border: 2px solid #20d0c9;
    color: #20d0c9;
  }
  > div {
    padding: 0;
  }
  .no-garages-yet {
    h4 {
      font-size: 18px;
    }
    p {
      font-size: 13px;
      margin-top: 6px;
    }
  }
  .your-host-info {
    float: left;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100%;
  }
  .host-content {
    float: left;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    p {
      float: left;
      width: 100%;
      clear: both;
      text-align: center;
      padding: 10px 0 0 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .about-host-user-icon {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    display: block;
    margin: auto;
    background-image: url('../images/missing-avatar.jpg');
  }
}
.host-container .reservations-tab-panel {
  .type-label {
     @media(max-width: 991px) {
      margin-bottom: 2px;
    }
  }
}
header.flat-bar {
  box-shadow: none;
  border: 1px solid #e8e8e8;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 0;
}
.reservation-detail-row {
  float: left;
  width: 100%;
  margin-top: 10px;
  p {
    font-size: 13px;
    color: #5d5d5d;
  }
}
.dashboard-container .col-right .subtitle.with-reservations.and-has-hostings {
  margin-bottom: 0;
  float: right;
}
.reservation-guest-list-info {
  float: left;
  width: calc(100% - 210px);
  margin-bottom: 20px;
  &.host-reservation-list {
    width: 100%;
  }
  @media(max-width: 600px) {
    width: 100%;
  }
  p:nth-child(2) {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  a {
    color: #00a3ff;
    &:hover {
     color: #1e5ff1; 
    }
  }
}
a.has-host-reservations-tab  {
  float: right;
  clear: both;
  @media(max-width: 600px) {
    float: none;
    margin: auto;
    display: block;
    width: 189px;
  }
  button{
    background-color: #1ea6f1;
    border-radius: 5px;
    padding: 5px 20px;
    margin: 5px;
    color: #fff;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    @media(max-width: 600px) {
      float: none;
      margin: auto;
    }
    &:focus {
      color: #fff;
    }
    &:hover {
      background-color: #1e5ff1;
      color: #fff;
    } 
  }
}
.host-reservations-link-bar {
  float: right;
  width: 210px;
  @media(max-width: 600px) {
    width: 100%;
    float: left;
    margin-bottom: 30px;
    span {
      text-align: center;
      float: left;
      width: 100%;
    }
  }
}