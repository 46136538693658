.garage-grid-item {
  background-color: #fff;
  z-index: 999;
  box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #eee;
  float: left;
  width: 100%;
  user-select: none;
  height: 340px;
  .garage-thumb-container {
    position: relative;
    float: left;
    width: 100%;
  }
  .user-name-with-rating {
    font-size: 12px;
    font-family: 'Roboto';
    position: absolute;
    bottom: -18px;
    left: 24px;
    background-color: #fff;
    padding: 5px 0px 5px 25px;
    border-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: left;
    z-index: 0;
    .name {
      float: left;
      width: 100%;
    }
    .user-rating {
      float: left;
      width: 100%;
    }
    .user-star {
      color: #ffb224;
      width: 17px;
      height: 17px;
      float: left;
    }
    .rating {
      float: left;
      margin: 0;
      font-weight: bold;
    }
  }
  .garage-thumb-price {
    position: absolute;
    right: -10px;
    top: -10px;
    background-color: #fff;
    padding: 11px;
    font-family: 'Roboto';
    border-radius: 12px;
  }
  .user-nav-avatar  {
    position: absolute;
    bottom: -20px;
    width: 45px;
    height: 45px;
    left: 0;
    border: 3px solid #fff;
    z-index: 5;
    background-color: #fff;
    &.no-avatar {
      background-color: #ddd;
      img {
        width: 20px;
        height: 24px;
      }
    }
  }
  .type-container {
    float: left;
    width: 100%;
    margin: 10px 0 0;
  }
  .type-label {
    border-radius: 15px;
    float: left;
    color: #fff;
    font-family: 'Roboto';
    font-size: 12px;
    padding: 2px 8px;
    margin-right: 5px;
  }
  .Storage {
    border: 2px solid #7b49ef;
    color: #7b49ef;
  }
  .Workshop {
    border: 2px solid #20d0c9;
    color: #20d0c9;
  }
}
.garage-thumb-image {
  float: left;
  width: calc(100% + 40px);
  background-color: #eee;
  height: 200px;
  margin-top: -20px;
  margin-left: -20px;
  background-size: cover;
  background-position: center;
  &.missing-image {
    background-image: url('../images/missing-image.jpg');
  }
}
.garage-thumb-title {
  float: left;
  width: 100%;
  padding: 30px 0px 0px 0px;
  position: relative;
  height: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  h2 {
    font-size: 15px;
    font-weight: normal;
    line-height: 17px;
  }
}
.location-of-garage {
  float: left;
  clear: both;
  margin-top: 10px;
  h1 {
    font-size: 12px;
    font-weight: 500;
    float: left;
    margin: 0;
    color: #656565;
  }
  svg {
    float: left;
    height: 13px;
    width: 13px;
    margin-top: 2px;
    color: #656565;
  }
}
