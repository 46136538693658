header.edit-garage-app-bar {
  background-color: #386bff;
  h6 {
    span {
      color: #04ffec;
    }
  }
}
.wants-to-delete-info {
  z-index: 99999!important;
}
.edit-garage-preview-col {
  position: absolute;
  left: 0;
  top: 64px;
  bottom: 0;
  height: calc(100% - 64px);
  width: 450px;
  background-image: url('../images/motorroof-pattern.jpg');
  background-size: cover;
  @media(max-width: 991px) {
    display: none;
  }
  .garage-grid-item {
    width: 300px;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
h6.editing-garage-title {
  float: left;
  width: calc(100% - 205px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media(max-width: 667px) {
    font-size: 14px;
  }
}
.edit-garage-form-col {
  position: fixed;
  top: 64px;
  bottom: 0;
  right: 0;
  height: calc(100% - 64px);
  z-index: 9;
  width: calc(100% - 450px);
  background-color: white;
  overflow: scroll;
  padding-bottom: 100px;
  @media(max-width: 991px) {
    width: 100%;
    h5.tab-title {
      padding-left: 20px;
    }
  }
  @media(max-width: 599px) {
    margin-top: -8px;
  }
}
button.save-edit-btn {
  float: right;
  position: absolute;
  right: 20px;
  background-color: #00ffbb;
  padding: 10px 20px;
  color: #179033;
  @media(max-width: 991px) {
    font-size: 12px;
    padding: 10px 10px;
  }
  &.disabled {
    background-color: #6c91ff;
  }
  &:hover {
    background-color: rgb(0, 159, 255);
    color: #ddd;
  }
}
button.close-edit-btn {
  background-color: rgba(255, 255, 255, 0.44);
  color: #386aff;
  transition: all 0.2s ease-in-out;
  margin-right: 10px;
  &:hover {
    background-color: #fff;
  }
}
header.edit-tabs-app-bar {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.02), 0px 2px 2px 0px rgba(0,0,0,0.04), 0px 1px 20px 0px rgba(0,0,0,0.10);
}
.edit-garage-basic-info-wrapper {
  float: left;
  width: 100%;
  max-width: 800px;
  @media(max-width: 991px) {
    max-width: 100%;
    padding: 20px;
  }
}
.edit-garage-price-and-description {
  float: left;
  width: 100%;
  max-width: 800px;
  clear: both;
  .listing-price {
    float: left;
    width: 120px;
  }
  .listing-title {
    float: left;
    width: 100%;
    &.edit-form-listing-title {
      line-height: 20px;
    }
    @media(max-width: 667px) {
      width: 100%;
      margin-left: 0;
    }
  }
  .listing-description {
    float: left;
    width: 100%;
  }
}
.build-garage-amenities {
  float: left;
  width: 100%;
}
span.edit-garage-form-subtitle{
  float: left;
  width: 100%;
  margin-top: 20px;
}
.saving-loader {
  position: absolute;
  left: 14px;
  top: 11px;
}
button.save-edit-btn.saving {
  padding-left: 45px;
  background-color: transparent;
  color: #00ffbb;
  -webkit-box-shadow: inset 0px 0px 38px 0px rgba(0,255,187,1);
  -moz-box-shadow: inset 0px 0px 38px 0px rgba(0,255,187,1);
  box-shadow: inset 0px 0px 38px 0px rgba(0,255,187,1);
  svg {
    color: #00ffbb;
  }
}
.hosting-hub-availability-calendar {
  float: left;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 12px;
  .scheduler-screen-container {
    padding: 20px;
  }
}
.edit-panel-info-header {
  float: left;
  clear: both;
  max-width: 600px;
  padding-right: 20px;
}
.garage-location-editor {
  float: left;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ddd;
  border-top: 0;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.map-edit-wrap {
  float: left;
  width: 100%;
}
.map-edit-wrap > div:first-child {
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  max-width: 600px;
  margin-top: 20px;
  float: left;
}
.delete-property-container {
  float: left;
  width: 100%;
  max-width: 800px;
  p {
    margin: 0 0 10px 0;
    width: 100%;
    float: left;
    max-width: initial;
  }
  button {
    border: 2px solid #ff008b;
    color: #ff008b;
    padding: 10px 20px;
    &:hover {
      background-color: #ff008b;
      color: #fff;
    }
  }
}
button.cancel-delete {
  color: #1890ff;
  padding: 10px 20px;
  &:hover {
    background-color: #d4ebff;
  }
}
button.delete-forever-btn {
  background-color: #ff008b;
  color: #fff;
  padding: 10px 20px;
  &.loading-delete-request {
    background-color: #fff;
    color: #ff008b;
  }
  &:hover {
    background-color: #ff0039;
  }
}


