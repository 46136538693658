.login-page-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url('../images/whymotorroof-bg.svg');
  background-size: 120%;
  background-position: 50%;
  z-index: 9999;
  @media(max-width: 991px) {
    background-size: 1200px;
    float: left;
  }
  @media(max-width: 767px) {
    position: relative;
    background-image: none;
    background-color: #fff;
  }
  form {
    margin-top: -10px;
  }
}
div.login-signup-form {
  padding: 20px;
  max-width: 350px;
  position: absolute;
  width: 400px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 540px;
  padding-top: 100px;
  display: block;
  @media(max-width: 767px) {
    position: initial;
    padding-top: 20px;
    height: 100%;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }
  @media(max-width: 667px) {
    max-width: 100%;
  }
}

.login-btn-wrap {
  width: 100%;
  margin-top: 20px;
}

button.login-btn {
  span {
    margin-top: -2px;
    margin-bottom: 1px;
  }
}

button.btn-primary {
  background-color: #1ea6f1;
  color: #fff;
  text-transform: initial;
  &:hover {
    background-color: #1e5ff1;
  }
}

button.facebook-login-btn {
  background-color: #4267b2;
  margin-top: 10px;
  display: block;
  width: 100%;
  padding: 10px;
  text-transform: initial;
  &:hover {
    background-color: #497ce2;
  }
  img {
    margin-right: 10px;
    max-width: 10px;
    bottom: -3px;
    position: relative;
  }
}

button.google-login-btn {
  background-color: #fff;
  margin-top: 10px;
  display: block;
  width: 100%;
  padding: 10px;
  color: #737373; 
  text-transform: initial;
  &:hover {
    background-color: #e6e6e6;
  }
  img {
    margin-right: 10px;
    max-width: 17px;
    bottom: -3px;
    position: relative;
  }
}

svg.icon-lock {
  width: 23px;
  position: relative;
  top: 5px;
  left: -4px;
}

.login-form-logo-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  margin-top: 20px;
  @media(max-width: 767px) {
    position: initial;
    margin: auto;
  }
  svg {
    max-width: 100px;
    margin: auto;
    display: block;
    .st0 {
      fill: #10FFE2;
    }
    .st1 {
      fill: #231F20;
    }
    .st2 {
      fill: #2D2D2D;
    }
  }
}
.signup-cta-links {
  float: left;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #ddd;
  a {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    color: #5a5a5a;
    &:hover {
      color: #33a0ff;
    }
  }
}